import React from "react";
import pkgbnr from "../src/images/pkgbnr.png";


const Packagecontent = () => {

    return (
        <>
            <section className="sect_padding route_info">
                <div className="container">
                    <div className="row pkgcontent">
                        <div className="pimg">
                            <img src={pkgbnr}/>
                        </div>
                        <div className="pkgtext">
                            <p>Lorem ipsum dolor sit amet. Eum provident repudiandae aut omnis nobis qui tempora placeat quo quaerat impedit et omnis amet. Ut rerum consequatur aut cumque repudiandae ut
                            eligendi harum hic itaque consequatur qui deleniti officiis non harum molestiae et autem adipisci. Et dignissimos quae vel facere rerum sed quae libero sit aliquam sequi.
                            Ut impedit rerum aut assumenda vero et commodi dolorum ut facilis tenetur et Quis incidunt eos amet sunt non aliquid illum. Vel voluptatem consequatur a magnam ullam non 
                            facere quasi. Sit temporibus unde hic quidem quia nam fugit pariatur eos atque optio non sunt rerum qui doloribus autem qui aliquid numquam. Sit voluptate accusamus aut iusto
                            omnis est consequatur commodi qui atque dolor ut magni nemo non odio molestias et aspernatur nihil. Id voluptates nobis et nemo ipsam eos magni sint aut molestiae asperiores.
                            Et dolores ipsum ut inventore esse et earum delectus ad consequatur dolore. Est odio consequatur sed temporibus unde et illum distinctio ut voluptas incidunt rem enim consequatur
                            et galisum dolor. Et amet consectetur in accusantium harum sed commodi eligendi et expedita voluptates est ipsam excepturi et nemo pariatur aut omnis quia. Sed ipsa velit At
                            consequatur illum aut natus fugiat est neque perferendis et quasi voluptatem quo optio libero. Et perspiciatis voluptatibus et totam aspernatur nam quisquam accusamus non
                            quam fugit qui velit quia At facere fugiat. Qui vero optio qui officia molestiae a deserunt aperiam.  </p>
                        </div>
                        <div className="rdmore">
                            
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Packagecontent;