import React from "react";
import pic1 from "../src/images/pack1.png";
import DateRange from '@material-ui/icons/DateRange';


const Blog_list_box = () => {

    return (
        <>
            <div className="col-sm-4">
                <div className="blgbox packgboxlist">
                    <div className="pkgimg">
                        <img src={pic1} />
                    </div>
                    <div className="pkgcont">
                        <h4>Must-Visit places for Dharamshala local sightseeing</h4>
                        <span className="datebg"><DateRange />15 March, 2022</span>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard </p>
                        <div className="c_btn"><a href="" className="btn_know"> Read More</a></div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Blog_list_box