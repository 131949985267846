import axios from 'axios';
import React, { useState, useEffect } from "react";
import OwlCarousel from 'react-owl-carousel';
import { useNavigate } from 'react-router-dom';

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Tooltip from 'react-bootstrap/Tooltip';
import Modal from 'react-bootstrap/Modal';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
 
import fleet1 from "../src/images/fleet1.png";
import fleet2 from "../src/images/fleet2.png";

import cbi1 from "../src/images/cbi1.png";
import cbi2 from "../src/images/cbi2.png";
import cbi3 from "../src/images/cbi3.png";
import cbi4 from "../src/images/cbi4.png";
import cbi5 from "../src/images/cbi5.png";

import CabPrivacyDetailsModelBox from './cabsearchengine/CabPrivacyDetailsModelBox';

import config                   from './config/config';
import apiHeaderToken           from './config/api_header'; 


const options = {
    margin: 20,
    responsiveClass: true,
    nav: true,
    items: 1,
    dots: false,
    autoplay: false,
    smartSpeed: 1000
};

const CabListBox = ( props ) => {
    const [cabItem, setCabItem] = useState( props.listData );
    const navigate = new useNavigate();

    /*check cab image */
    if( cabItem.cab_image_list.length === 0 ){
        cabItem.cab_image_list[0] = fleet1;
        cabItem.cab_image_list[1] = fleet2;
    }
 
    const renderTooltip = props => (
        <Tooltip {...props}>To View Vehicle Terms Click This</Tooltip>
    );

    /************ Model Box *************/
    const [show, setShow] = useState(false); 
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    /******** Set Popup Model Data **********/
    const [modalData, setModalData] = useState({}); 

    /************ Load Terms Data ***********/
    function loadTermsAndReviewData( type, data, cab_id ){ 
 
            let mData = {}
            mData.type = type;
            mData.contentData = data;
            mData.cab_id = cab_id;
            if( type === 'reviews' ){
                //get review List  
                 
                  axios.post(`${config.API_URL}customer/booking/cabratinglist`,{ cab_id: cab_id,'page_no':'1','per_page_records':'50','is_test':'yes' }, apiHeaderToken(config.API_TOKEN) )
                  .then((respData) => respData.data)
                  .then((responseData) => {
                      if (responseData.status) { 
                           mData.contentData = responseData.data;  
                           setModalData( mData );
                      }else {
                           setModalData( mData );
                      }
                  })
                  .catch((error) => { 
                        setModalData( mData );
                  }); 
             
            }else{  
                setModalData( mData );  
            }  
            handleShow(); 
    }

    const handleCabItem = ()=>{
         //remove old session 
        localStorage.removeItem('coupon_data');
        localStorage.removeItem('selectedCab'); 
        localStorage.setItem('selectedCab', JSON.stringify( cabItem ) ); 
        navigate('/cab_details');
    } 
    
    return (
        <>
            <div className="cabbox">
                <div className="cabsall">
                    <div className="d-flex abtcab">
                        <div className="cb_img">
                            <OwlCarousel className="cabs_det_slider owl-carousel owl-theme" {...options}>
                            {cabItem.cab_image_list.length > 0 && cabItem.cab_image_list.map((item) => (
                                    item ? (
                                        <div className="item" key={item}>
                                        <img src={item} alt={item} />
                                        </div>
                                    ) : null
                            ))}
                            </OwlCarousel>
                        </div>
                        <div className="cb_txt">
                            <h5>{cabItem.model_name}</h5>
                            <ul className="cb_feat d-flex">
                                <li>{cabItem.category_name}</li>
                                <li>4 Seater</li>
                                <li>AC</li>
                            </ul>
                            <p>Vehicle No. : {cabItem.vehicleno}</p>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between cb_feat_listed">
                        <div className="bookby">
                            <p>Booked : {cabItem.total_bookings !== '' ? cabItem.total_bookings : 0 }</p>
                        </div>
                        { <div className="cb_icons">
                            <img src={cbi1}  alt={cbi1} />
                            <img src={cbi2}  alt={cbi2} />
                            <img src={cbi4}  alt={cbi4} />
                            <img src={cbi5}  alt={cbi5} />
                            <img src={cbi3}  alt={cbi3} />
                        </div> }
                    </div>
                    <div className="d-flex justify-content-between cb_inform">
                        <OverlayTrigger placement="top" overlay={renderTooltip}>
                            <p onClick={ ()=>loadTermsAndReviewData('terms', cabItem.terms_list, cabItem.cab_id )} >Terms</p>
                        </OverlayTrigger>
                        <p onClick={ ()=>loadTermsAndReviewData('features', cabItem.features_list, cabItem.cab_id )} >Inclusions</p>
                        <p onClick={ ()=>loadTermsAndReviewData('safety', cabItem.safety_policy_list, cabItem.cab_id )} >Safety</p>
                        <p onClick={ ()=>loadTermsAndReviewData('reviews',[], cabItem.cab_id )} >Reviews</p>
                    </div>
                    <div className="flt_text">
                        <p>₹{cabItem.fare_details.total_trip_amount_with_gst}</p>
                        <a href="cab_details" onClick={handleCabItem} className="bookbtn"> Book Now </a>
                    </div>
                </div>
            </div>

            <CabPrivacyDetailsModelBox show={show} onHide={handleClose} pushData={modalData} /> 
        </>
    );
};

export default CabListBox;