import React, { useEffect, useState } from 'react';
import { NavLink, useLocation  } from "react-router-dom";
import logo from "../../src/images/logo.png";
import usr from "../../src/images/User--avatar1.png";
import AccountBalanceWalletOutlinedIcon from '@material-ui/icons/AccountBalanceWalletOutlined';
import PersonOutlineOutlined from '@material-ui/icons/PersonOutlineOutlined';
import MyLoginModal from '../../src/Login';

const Navbar = () => {
  const [modalShow, setModalShow] = useState(false);
  const [getCurrentPath, setGetCurrentPath] = useState('');

  //check Login user Data
  const userLoginData = JSON.parse( localStorage.getItem('login_data') ); 
  const [loginUserData, setLoginUserData] = useState( userLoginData ); 


  const location = useLocation();
  const currentPath = location.pathname;

  const getURLPath = ( currentPath )=>{ 
    setGetCurrentPath( currentPath );
  }  

  //Logout Session
  const logoutSession = ()=>{
    localStorage.removeItem('login_data');
    window.location.href = '/';
  }

  useEffect( ()=>{ 
    setTimeout( ()=>{
      getURLPath( currentPath ); 
    },200);

  },[currentPath]);


  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <nav className="header_site navbar navbar-expand-lg navbar-light">
              <div className="container-fluid">
                <NavLink className="navbar-brand" to="/">
                  <img src={logo} className="logoimg" alt="Logo" />
                </NavLink>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                  <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                    <li className="nav-item">
                      <NavLink className="nav-link" aria-current="page" to="/">Home</NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink className="nav-link" to="/about">About</NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink className="nav-link" to="/taxi_package">Taxi Packages</NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink className="nav-link" to="/contact">Contact</NavLink>
                    </li>
                  </ul>
                </div>
                <div className="header_btn">
                    
                      { loginUserData ? ( <>
                        <ul>
                        <li className="nav-item">
                          <NavLink className="nav-link btn_know" to="/my_profile"> 
                          <img src={usr} className="inact" />
                            Profile
                          </NavLink>
                        </li>
                        <li className="nav-item" onClick={() => logoutSession() }>
                          <NavLink className="nav-link btn_know bbtn" to={getCurrentPath}> 
                            Sign Out
                          </NavLink>  
                        </li></ul></>
                        ) : (
                          <>
                          <ul>
                          <li className="nav-item" onClick={() => setModalShow(true)}>
                          <NavLink className="nav-link btn_know bbtn" to={getCurrentPath}>
                            <PersonOutlineOutlined />
                            Sign In
                          </NavLink>
                          <MyLoginModal show={modalShow} onHide={ () => setModalShow(false) } />
                          </li></ul></>
                        )} 
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;