import React, {useState, useEffect, useCallback } from "react";
import Modal from 'react-bootstrap/Modal';
import MyLoginModal from '../src/Login';
import BookingSuccessModals from "./BookingSuccessModals";
import logo from "../src/images/logo.png";

import axios  from "axios"; 

import StarIcon from '@material-ui/icons/Star';
import offers from "../src/images/offers.png";
import { ucWords, allDateFormat, handleNullValue, getWordsBeforeComma  }  from './helper/my_function_helper';
 

import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
 
import config                   from './config/config';
import apiHeaderToken           from './config/api_header'; 

import AlertBox from "../src/modalbox/AlertBox";


const Trip_summary = ( props ) => {
   
    const [cabItem, setCabItem] = useState( props.data ); 
    const [estimatedAmount, setEstimatedAmount] = useState( cabItem.fare_details.total_trip_amount_with_gst ); 
    const [paymentMode, setPaymentMode] = useState( 'cash' ); 
    const [modalShow, setModalShow] = useState(false);
    const [bookingModalShow, setBookingModalShow] = useState( false );
    const [bookingId, setBookingId] = useState( '' ); 
    const [couponList, setCouponList] = useState([]);  
    const [appliedCoupon, setAppliedCoupon] = useState(null);
    const [couponStatus, setCouponStatus] = useState( false );
    const [discount, setDiscount] = useState(0);
    const [showModalBox, setShowModalBox] = useState(false); 
    const [payNowButton, setPayNowButton] = useState( 'Pay Now' ); 
    const [payNowDisabled, setPayNowDisabled] = useState( false ); 

    //alert box message 
    const [showAlertMessage, setShowAlertMessage] = useState(''); 
    const [showAlertStatus, setShowAlertStatus] = useState( false ); 
    const delayTime = 3000; 

   
    


    useEffect(() => {
        setTimeout( ()=>{
            renderCouponData( cabItem ); 
        }, 200 ) ;
    }, []);

    
    //check Login user Data
    const userLoginData = JSON.parse( localStorage.getItem('login_data') ); 
    const [loginUserData, setLoginUserData] = useState( userLoginData );  


    /********set searched Items ************/
    let getSearchData = JSON.parse( localStorage.getItem('search_tab') );
    const [cabSearchData, setCabSearchData] = useState( getSearchData ); 
 
    if( getSearchData === null ){
        window.location.href='/'; return;
    } 

    let tripType = handleNullValue( cabSearchData.trip_type ); 
    let fromCityId = handleNullValue( cabSearchData.from_city_id ) ;
    let fromCityName = handleNullValue( cabSearchData.from_city ) ;
    let packageName = handleNullValue( cabSearchData.package ) ; 
    let toCityName = handleNullValue( cabSearchData.to_city ) ;
 
    let travel_routes = cabSearchData.travel_routes ; 
   
 
    let toCityLevel = ''; let toCityValue = '';   
    if( ['airport','oneway'].includes( tripType ) ){
        toCityLevel = 'To'; 
        toCityValue = toCityName;          
    }
    else if( ['local'].includes( tripType ) ){
        toCityLevel = 'Package'; 
        toCityValue = packageName;          
    }
    else if( ['outstation'].includes( tripType ) ){
        toCityLevel = 'Route';  
        let str = travel_routes.map( (elm)=>{
            return  elm.city +'→';
        }).toString(); 
        toCityValue = str.slice(0, -1); 
    } 


    const handleLoginChange = ( nameValueObj ) => { 
        for (let property in nameValueObj ) { 
            loginUserData[property] = nameValueObj[property];
        } 
        setLoginUserData({
          ...loginUserData
        });

        localStorage.removeItem('login_data');
        localStorage.setItem('login_data', JSON.stringify( loginUserData ) );
    };

     
/************* razorpay gateway Start Script ************/  

function loadScript(src) {
    return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = src;
        script.onload = () => {
            resolve(true);
        };
        script.onerror = () => {
            resolve(false);
        };
        document.body.appendChild(script);
    });
}


const handlePayment = ( restAmount, booking_id, booking_doc_id  ) => {  

    //load checkout js library
    const res = loadScript( "https://checkout.razorpay.com/v1/checkout.js" );

    if (!res) { 
        setShowAlertMessage( "Razorpay SDK failed to load. Are you online?" );
        setShowAlertStatus(true);
        setTimeout( ()=>{ setShowAlertStatus(false);}, 3000 );
        return false; 
    }

    const payLoad = {}
    payLoad.user_id = loginUserData.user_id;
    payLoad.amount = restAmount;
    payLoad.online_charge = '';

    axios
      .post(`${config.API_URL}customer/generate_order_id`, payLoad,  apiHeaderToken(config.API_TOKEN))
      .then((respData) => respData.data)
      .then((responseData) => { 
            if( responseData.status ){
                    //var orderId = responseData.data.razor_token;
                    const { orderid, razor_token, amount } = responseData.data;
                     
                            const options = {
                                key: config.RAZORPAY_KEY_ID ,
                                amount: parseInt( amount ),
                                currency: "INR",
                                name: config.COMPANY_NAME,
                                description: "Test Transaction",
                                image: logo,
                                order_id: razor_token,
                                handler: ( response ) => {
                                        setPayNowButton('Please wait...'); 
                                        axios
                                        .post(`${config.API_URL}customer/capture_amount`, {"user_id": loginUserData.user_id,"orderid":orderid,"razor_payid":response.razorpay_payment_id},  apiHeaderToken(config.API_TOKEN))
                                        .then((respData) => respData.data)
                                        .then((responseData) => { 
                                                if( responseData.status ){ 
                                                    loadCaptureBooking( booking_doc_id ); 
                                                }else{
                                                    setShowAlertMessage( responseData.message );
                                                    setShowAlertStatus(true);
                                                    setTimeout( ()=>{ setShowAlertStatus(false);}, delayTime );
                                                    return false;
                                                }
                                        }) 
                                },
                                prefill: {
                                    name: loginUserData.user_name,
                                    email: loginUserData.user_email,
                                    contact: loginUserData.user_mobile,
                                },
                                notes: {
                                address: "903, 9th Floor Pearls Business Park, Neta Ji Subhas Place, Pitam Pura, Delhi, 110034",
                                },
                                theme: {
                                color: "#3399cc",
                                },
                            };

                    const paymentObject = new window.Razorpay(options);
                    paymentObject.open();
            }else{
                setShowAlertMessage( responseData.message );
                setShowAlertStatus(true);
                setTimeout( ()=>{ setShowAlertStatus(false);}, delayTime );
                return false;
            }
        }); 
}

/************* razorpay gateway End Script ************/ 

    
  /************************** Start Of Coupon Listing **********************************/ 
  const gatCouponRecords = ( tripType, city_id ) => {
    const payLoad = {}
    payLoad.trip_type = tripType ;
    payLoad.city_id = city_id ; 
    payLoad.cart = city_id ; 
    payLoad.cart_amount = cabItem.fare_details.total_trip_amount;
    axios
      .post(`${config.API_URL}customer/coupon/list`, payLoad,  apiHeaderToken(config.API_TOKEN))
      .then((respData) => respData.data)
      .then((responseData) => { 
        if (responseData.status) { 
            setCouponList(responseData.data); 
        } else {
            setCouponList([]);
        }
      })
      .catch((error) => { 
        setCouponList([]);
      });
  };
/************************** End Of Coupon Listing **********************************/
 


/************  Apply Coupon Code ************/ 
const applyCoupon = (couponId) => {
    const selectedCoupon = couponList.find((coupon) => coupon._id === couponId);  
    if ( selectedCoupon ) {
         
        const payLoad = {}
        payLoad.trip_type = cabItem.trip_type;
        payLoad.coupon_code = selectedCoupon.coupon_code;
        payLoad.total_amount = cabItem.fare_details.total_trip_amount; 
        axios
            .post(`${config.API_URL}customer/coupon/apply`, payLoad,  apiHeaderToken(config.API_TOKEN))
            .then((respData) => respData.data)
            .then((responseData) => { 
                if (responseData.status) { 
                    setDiscount( responseData.data.discount_amount );
                    setAppliedCoupon( selectedCoupon.coupon_code );
                    setCouponStatus( true );
                    setEstimatedAmount( (cabItem.fare_details.total_trip_amount_with_gst - responseData.data.discount_amount) );
                    closeModal();

                    //remove old session 
                    localStorage.removeItem('coupon_data');
                    //assign coupon data in storage
                    localStorage.setItem('coupon_data', JSON.stringify( {'coupon_code': selectedCoupon.coupon_code,'discount': responseData.data.discount_amount } ) );  
                } else {
                    setDiscount(0);
                    setAppliedCoupon(null);
                }
            })
            .catch((error) => { 
                setDiscount(0);
                setAppliedCoupon(null);
            });
    } else {
      setAppliedCoupon(null);  
    }
  };

 const removeCoupon = ()=>{
    localStorage.removeItem('coupon_data');
    setDiscount(0);
    setAppliedCoupon( null );
    setCouponStatus( false );
    setEstimatedAmount( cabItem.fare_details.total_trip_amount_with_gst  ); 
 } 

 
const options={margin:10,responsiveClass:!0,nav:!1,dots:!0,autoplay:!1,smartSpeed:1e3,responsive:{0:{items:1},400:{items:1},600:{items:1},700:{items:1},1e3:{items:3}}};

/************ manage coupon Popup Start Script ************/  
 const closeModal = () => setShowModalBox(false);
 const openModal = () => { setShowModalBox(true); gatCouponRecords( tripType, fromCityId ); }
/************ manage coupon Popup End Script ************/
 

/*********  get coupon applied data Start ***********/
var renderCouponData = ( cabItem )=>{
    var appliedCouponData = JSON.parse( localStorage.getItem('coupon_data') ); 
    if( appliedCouponData ){
        setDiscount( appliedCouponData.discount );
        setAppliedCoupon( appliedCouponData.coupon_code );
        setCouponStatus( true );
        setEstimatedAmount( (cabItem.fare_details.total_trip_amount_with_gst - appliedCouponData.discount ) );
    }
}
 

const appliedCouponCss = {'text-align':'center','font-weight':'600','padding-left': '30px !important'}
 

/*********  get coupon applied data End ***********/

const loadCaptureBooking = ( booking_doc_id )=>{
    const capturePayload = {}
    capturePayload.user_id =  loginUserData.user_id;
    capturePayload.booking_collection_id =  booking_doc_id;  
    axios
    .post(`${config.API_URL}customer/booking/capture`, capturePayload,  apiHeaderToken(config.API_TOKEN))
    .then((respData) => respData.data)
    .then((responseData) => { 
            if (responseData.status) {  
                setBookingModalShow( true );
                setBookingId( responseData.data ); 
                //update wallet balance
                handleLoginChange({'wallet_balance':responseData.wallet_balance}); 
                setPayNowButton('Completed');
            }else{
                setShowAlertMessage( responseData.message );
                setShowAlertStatus(true);
                setTimeout( ()=>{ setShowAlertStatus(false);}, 3000 );
                return false; 
            }
    })
}

const handlePaymentModeChange = (mode) => {
    setPaymentMode(mode); 
}; 



/************* Place Order Start Script ************/ 
const placeOrder = (e)=>{

    e.preventDefault();
    if( !loginUserData ){
        setModalShow( true ); return;
    }

    if(payNowDisabled){
        return;
    }

    const payLoad = {}
    payLoad.base_fixed_fare = cabItem.fare_details.base_fixed_fare;
    payLoad.cab_id = cabItem.cab_id;
    payLoad.cab_image = '';
    payLoad.category_name = cabItem.category_name;
    payLoad.coupon_code = String( appliedCoupon );
    payLoad.days = cabItem.days;
    payLoad.discount_amount = String( discount );
    payLoad.driver_charge = cabItem.fare_details.driver_charge;
    payLoad.drop_date = cabSearchData.drop_date;
    payLoad.estimated_kms = cabItem.fare_details.estimated_kms;
    payLoad.estimated_time = '';
    payLoad.ex_drop_charge = cabItem.fare_details.is_ex_drop === 'yes' ?  cabItem.fare_details.ex_drop_charge : '';
    payLoad.ex_pickup_charge = cabItem.fare_details.is_ex_pickup === 'yes' ?  cabItem.fare_details.ex_pickup_charge : '';
    payLoad.fixed_hours = cabItem.fare_details.fixed_hours;
    payLoad.fixed_kms = cabItem.fare_details.fixed_kms;
    payLoad.from_city =  cabSearchData.from_city;
    payLoad.from_city_id =  cabSearchData.from_city_id;
    payLoad.from_state_id = cabSearchData.from_state_id;
    payLoad.from_state_name = cabSearchData.from_state_name;
    payLoad.google_kms = cabItem.google_kms;
    payLoad.gst_amount_on_total_trip_amount = cabItem.fare_details.gst_amount_on_total_trip_amount;
    payLoad.gst_percentage = cabItem.fare_details.gst_percentage;
    payLoad.model_name = cabItem.model_name;
    payLoad.night_charge = cabItem.fare_details.night_charge;
    payLoad.package_name = cabItem.fare_details.package;
    payLoad.payment_mode = paymentMode;
    payLoad.per_km_charge = cabItem.fare_details.per_km_charge;
    payLoad.per_min_charge = cabItem.fare_details.per_min_charge;
    payLoad.pickup_date = cabSearchData.pickup_date;
    payLoad.pickup_time = cabSearchData.pickup_time;
    payLoad.total_trip_amount = cabItem.fare_details.total_trip_amount;
    payLoad.total_trip_amount_with_gst = cabItem.fare_details.total_trip_amount_with_gst;
    payLoad.to_city_id = cabItem.to_city_id;
    payLoad.to_city_name = cabItem.to_city;
    payLoad.to_state_id = cabSearchData.to_state_id;
    payLoad.to_state_name = cabSearchData.to_state_name;
    payLoad.travel_route = cabItem.travel_route;
    payLoad.trip_type = cabItem.trip_type;
    payLoad.user_email = loginUserData.user_email; 
    payLoad.user_id = loginUserData.user_id; 
    payLoad.user_mobile = loginUserData.user_mobile; 
    payLoad.user_name = loginUserData.user_name;
    payLoad.vehicle_no = cabItem.vehicleno;
    payLoad.vendor_id = cabItem.vendor_id;
    payLoad.vendor_name = cabItem.vendor_name;
    //console.log( payLoad );

            axios
            .post(`${config.API_URL}customer/booking/create`, payLoad,  apiHeaderToken(config.API_TOKEN))
            .then((respData) => respData.data)
            .then((responseData) => { 
                  if (responseData.status) {  
                            setPayNowButton('Processing...'); 
                            setPayNowDisabled( true );
                            var payment_mode = responseData.data.payment_mode;
                            var booking_doc_id = responseData.data.booking_doc_id;
                            var booking_status = responseData.data.booking_status;
                            var payable_amount = responseData.data.payable_amount;
                            var booking_id = responseData.data.booking_id;
                            /*capture booking start script*/
                            if( payment_mode === 'cash' && booking_status === 'Hold' ){
                                loadCaptureBooking( booking_doc_id ); 
                            }
                            else if( payment_mode === 'wallet' && booking_status === 'Hold' ){
                                    var walletBalance = loginUserData.wallet_balance;
                                    if( payable_amount <= walletBalance ){
                                        loadCaptureBooking( booking_doc_id ); 
                                    }
                                    else if( payable_amount > walletBalance ){
                                        var restAmount =  parseInt( payable_amount ) - parseInt( walletBalance );
                                        handlePayment( restAmount, booking_id, booking_doc_id );
                                    }
                            }        
                            /*capture booking End script*/
                  }else{
                    setShowAlertMessage( responseData.message );
                    setShowAlertStatus(true);
                    setTimeout( ()=>{ setShowAlertStatus(false);}, delayTime );
                    return false;
                  }
                   
            })
            .catch((error) => { 
                setShowAlertMessage( 'Some Error Occurred!!' );
                setShowAlertStatus(true);
                setTimeout( ()=>{ setShowAlertStatus(false);}, delayTime );
                return false;
            }); 

}
/************** Place Order End Script ************/

    return (
        <>
        {showAlertStatus  && (<AlertBox type="error" message={showAlertMessage} />)}
           <MyLoginModal show={modalShow} onHide={ () => setModalShow(false) } />
            <h3 className="site_sub_hdng">Trip Summary </h3>
            <div className="cab_dt_box">
                <div className="row trip_upr_wrap">
                    <div className="col-sm-6 trip_left">
                        <div className="abtcab">
                            <h4 className="sub_hdng">{cabItem.model_name}</h4>
                            <ul className="cb_feat d-flex">
                                <li>{cabItem.category_name}</li>
                                <li>4 Seater</li>
                                <li>AC</li>
                            </ul>
                            <p>Vehicle No. : {cabItem.vehicleno}</p>
                        </div>
                        <div className="d-flex location_tofrom">
                            <LocationOnOutlinedIcon />
                            <div className="">
                                <span>From</span>
                                <p>{getSearchData.from_city}</p>
                            </div>
                        </div>
                        <div className="d-flex location_tofrom">
                            <LocationOnOutlinedIcon />
                            <div className="">
                            <span>{toCityLevel}</span>
                            <p>{toCityValue}</p>
                            </div>
                        </div>
                        <div className="trip_km">
                            <p> Trip of about {cabItem.fare_details.estimated_kms} KM</p>
                        </div>
                    </div>
                    <div className="col-sm-6 trip_right">
                        <div className="d-flex justify-content-between">
                            <h5>Fare Details</h5>
                            <p className="rate_avg"><StarIcon />{cabItem.avg_rating}</p>
                        </div>
                        <div className="d-flex justify-content-between">
                            <p className="pricetxt">Taxi Cost (Includes Driver B.A/T.A) <br/>
                            {['outstation'].includes( tripType ) ? (<><span>{cabItem.fare_details.estimated_kms} Km x ₹{cabItem.fare_details.per_km_charge}</span></>) : null }
                            
                            </p>
                            <p className="pricetxt">₹{cabItem.fare_details.total_trip_amount}</p>
                        </div>
                        <div className="d-flex justify-content-between">
                            <p className="pricetxt">GST Charge@{cabItem.fare_details.gst_percentage}%</p>
                            <p className="pricetxt">₹{cabItem.fare_details.gst_amount_on_total_trip_amount}</p>
                        </div>
                        <div className="d-flex justify-content-between">
                            <p className="pricetxt">Total Trip Fare</p>
                            <p className="pricetxt">₹{cabItem.fare_details.total_trip_amount_with_gst}</p>
                        </div>
                        <div className="d-flex justify-content-between">
                            <p className="pricetxt">Online Tax Charge@0%</p>
                            <p className="pricetxt">₹{cabItem.fare_details.total_trip_amount_with_gst}</p>
                        </div>
                        <div className="d-flex justify-content-between">
                            <p className="pricetxt">Coupon discount </p>
                            <p className="pricetxt red">(-) ₹ {discount}</p>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-9 d-flex align-items-center offr_row">
                        <img src={offers}  alt={offers} /> 
                        <h5 onClick={openModal} className="pointer">View Coupon and offers </h5>
                    </div>
                    <div className="col-sm-3 d-flex offr_row align-items-center" > 
                    { appliedCoupon ? (
                        <div className="cab_dt_box frtxt" style={ appliedCouponCss }>{appliedCoupon}</div>
                    ) : null }
                    </div>
                </div>
                <div className="d-flex justify-content-between">
                    <div className="estmt_txt">
                        <h4>Estimated Fare</h4>
                        <p>Toll, Parking Extra if Applicable*</p>
                    </div>
                    <div className="finalbox">
                        { couponStatus ? (
                            <>
                            <p className="cpn_apply">Coupon Applied, You Saved ₹{discount} </p>
                            <span style={{'color':'red','cursor':'pointer'}} onClick={removeCoupon}> ❌ Remove Coupon</span>
                            </>
                        ) : ( '' ) } 
                        
                        <h3 className="finalprc">₹{estimatedAmount}</h3>
                    </div>
                </div>
            </div> 


    <Modal
        {...props} 
        show={showModalBox}
        size="xl"
        fullscreen="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
 

          <Modal.Body>  
          <h4 className="mdl_hdng">Coupon Codes</h4>
              <div className="row">

               {couponList.length > 0 && couponList.map(( item ) =>( 
                 <div className="col-sm-4">
                    <div className="cpnbox cab_dt_box">
                      <h5>{item.coupon_title} </h5>
                      <ul>
                        <li>Maximum discount up to ₹{item.max_discount_amount}</li>
                        <li>Minimum booking value ₹{item.min_amount}</li>
                        <li>Offer valid till {item.valid_till}</li>
                      </ul>
                      <div className="d-flex justify-content-between">
                        <span className="nav-link btn_know">{item.coupon_code}</span>
                        <a className="nav-link btn_know active pointer" style={ appliedCoupon ===  item.coupon_code ? {'border':'2px solid #06446b'} : {} } onClick={ ()=>applyCoupon( item._id )}>{ appliedCoupon ===  item.coupon_code ? 'Applied' : 'Apply' }</a>
                      </div>
                    </div>
                 </div> 
                ) ) }   
                
              </div>
          </Modal.Body>

          <Modal.Footer>
                <div class="frbtn"><a href="javascript:void(0)" class="bookbtn" onClick={closeModal}> Close </a></div> 
          </Modal.Footer>
    </Modal> 
      

        <div className="col-sm-12">
            <div className="farebox d-flex justify-content-between">
                <div className="d-flex">
                <div className="cab_dt_box frtxt">
                    <span>Estimated Fare</span>
                    <h3>₹{estimatedAmount}</h3>
                </div>
                <div className="cab_dt_box frtxt flwdth">
                        <span>Mode of Payment</span> 
                        <ul className="paymode">
                            <li className="filter-df">
                                <div className="cstm_radio pointer">
                                    <input className="form-check-input" type="radio" checked={paymentMode === 'wallet'} name="categoryIds" id="payMode1" value="wallet"  onChange={() => handlePaymentModeChange('wallet')}/>
                                    <span className="checkmark"></span>
                                    <label htmlFor="payMode1">Pay Online</label>
                                </div>
                            </li>
                            
                            <li className="filter-df">
                                <div className="cstm_radio pointer">
                                    <input className="form-check-input" type="radio" checked={paymentMode === 'cash'} name="categoryIds" id="payMode2" value="cash" onChange={() => handlePaymentModeChange('cash')} />
                                    <span className="checkmark"></span>
                                    <label htmlFor="payMode2">Pay to Driver</label>
                                </div>
                            </li>
                        </ul>
                </div>
                </div>
                <div className="frbtn">
                    <a href="javascript:void(0)" className="bookbtn" onClick={placeOrder} >{payNowButton}</a>
                </div>
            </div>
        </div> 

        {bookingId && (<BookingSuccessModals show={bookingModalShow}  booking_id={bookingId} />) } 
     </>
    )
}

export default Trip_summary;