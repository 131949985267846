import React, { useEffect, useState } from "react";
import bookSuccessImage from "../src/images/book-succ.png"; 
import Modal from 'react-bootstrap/Modal';

import { ucWords, allDateFormat, handleNullValue, getWordsBeforeComma  }  from './helper/my_function_helper';


const BookingSuccessModals = ( props ) => {

  console.log( props.booking_id.booking_id );

  const [bookingId, setBookingID] = useState( props.booking_id.booking_id ); 
  const [ travelTextRoute, setTravelTextRoute ] = useState('');

  const [showModalBox, setShowModalBox] = useState(false); 

  const bookAgain = () => {
      localStorage.removeItem('search_tab');
      window.location.href = "/"; 
  }; 

  /********set searched Items ************/
  let getSearchData = JSON.parse( localStorage.getItem('search_tab') );
  const [cabSearchData, setCabSearchData] = useState( getSearchData ); 


  let tripType = handleNullValue( cabSearchData.trip_type );
  let fromCityName = handleNullValue( cabSearchData.from_city ) ;
  let packageName = handleNullValue( cabSearchData.package ) ;
  let pickupDate = handleNullValue( cabSearchData.pickup_date ) ;
  let pickupTime = handleNullValue( cabSearchData.pickup_time ) ;
  let toCityName = handleNullValue( cabSearchData.to_city ) ;
  let travel_routes = cabSearchData.travel_routes ; 


  var searchTextRoot = '';
  if( ['airport','oneway'].includes( tripType ) ){ 
      var searchTextRoot = getWordsBeforeComma( fromCityName )+'→'+getWordsBeforeComma( toCityName );  
  }
  else if( ['local'].includes( tripType ) ){ 
      var searchTextRoot = getWordsBeforeComma( fromCityName )+'→'+packageName;  
  }
  else if( ['outstation'].includes( tripType ) ){ 
      var searchTextRoot = getWordsBeforeComma( fromCityName )+'→'+ travel_routes.map( (elm)=>{
          return getWordsBeforeComma( elm.city )+'→';
      })+getWordsBeforeComma( fromCityName );   
  }


  useEffect(()=>{
    setTimeout(()=>{  
        setTravelTextRoute( searchTextRoot ); 
        if( bookingId ) {
          setShowModalBox(true); 
          setTimeout(()=>{ localStorage.removeItem('search_tab'); }, 500 );
        }
    }, 200 );
  }, [ searchTextRoot, bookingId ] );

  return (
    <>   
      <Modal
              {...props} 
              show={showModalBox}
              size="lg"
              fullscreen="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              backdrop="static"
              keyboard={false}
        >
        
            <Modal.Body>  
                <div className="modal-body">
                    <h4 className="mdl_hdng">Booking Successful</h4>
                    <div className="bookicon">
                      <img src={bookSuccessImage} />
                    </div>
                    <div className="row text-center route_info">
                      <h2 className="sitepage_hdng">{travelTextRoute}</h2>
                      <p className="rt_dtl">{ ucWords(tripType) }, {allDateFormat(pickupDate,'DD-MMM')}, {pickupTime}</p>
                    </div>
                    <div className="bookidbox">
                      <p className="box_sh_inner">Booking ID : {bookingId}</p>
                    </div>
                    <div className="mdl_text">
                      <p>Full details of booking has also been sent to your registered mobile number and email address</p>
                    </div>
                    <div className="mdlbtn">
                      <a href="javascript:void(0)" class="bookbtn" onClick={bookAgain}>Book Again</a>
                    </div>
                </div>
            </Modal.Body>
      </Modal> 
    </> 
  );
};

export default BookingSuccessModals;