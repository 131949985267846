import React from "react";
import v1 from "../src/images/v1.webp";
import v2 from "../src/images/v2.webp";
import v3 from "../src/images/v3.webp";


const ValueSection = () => {
    

    return (
        <>
            <div className="container">
                <div className="row">
                   <div className="col-sm-4">
                       <div className="valuebox">
                           <div className="vl_img">
                               <img src={v1} alt={v1}/>
                           </div>
                           <div className="vl_txt">
                               <h4>Money Back Guarantee</h4>
                               <p>Lorem ipsum dolor sit amet, potenti metus congue et orci purus, magna sagittis in at. </p>
                           </div>
                       </div>
                   </div>
                   <div className="col-sm-4">
                       <div className="valuebox">
                           <div className="vl_img">
                               <img src={v2}  alt={v2}/>
                           </div>
                           <div className="vl_txt">
                               <h4>Verified Expert Driver</h4>
                               <p>Lorem ipsum dolor sit amet, potenti metus congue et orci purus, magna sagittis in at. </p>
                           </div>
                       </div>
                   </div>
                   <div className="col-sm-4">
                       <div className="valuebox">
                           <div className="vl_img">
                               <img src={v3}  alt={v3}/>
                           </div>
                           <div className="vl_txt">
                               <h4>100% Secure Payment</h4>
                               <p>Lorem ipsum dolor sit amet, potenti metus congue et orci purus, magna sagittis in at. </p>
                           </div>
                       </div>
                   </div>
                    
                </div>
            </div>
        </>
    );
};

export default ValueSection;