import React,{ useState, useEffect} from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
 
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import StarIcon from '@material-ui/icons/Star';
import FormatQuoteIcon from '@material-ui/icons/FormatQuote';

import { shortStringByLength } from '../helper/my_function_helper';

function CabPrivacyDetailsModelBox(props) {

  const [contentData, setContentData] = useState({});
  const [modalHeading, setModalHeading] = useState('');
  const [modalSize, setModalSize] = useState('');
  

  useEffect(() => {  
    manageData( props ); 
  }, [props] );

  function manageData( props ){
   
      if( props.pushData.type === 'terms' ){
        setModalHeading('Terms & Conditions'); 
        setModalSize('lg');
        setContentData( props.pushData );
      } 
      else if( props.pushData.type === 'features' ){
        setModalHeading('Inclusions'); 
        setModalSize('md');
        setContentData( props.pushData );
      }
      else if( props.pushData.type === 'safety' ){
        setModalHeading('Safety Policy'); 
        setModalSize('lg');
        setContentData( props.pushData );
      }
      else if( props.pushData.type === 'reviews' ){
        setModalHeading('Reviews'); 
        setModalSize('xl'); 
        setContentData( props.pushData ); 
      } 
     
  }

 


  const textAlignLeft = {
    textAlign: 'left'
  }
  const spanStyle = { 
    fontWeight: '600'
  }

  const options = {
    margin: 10,
    responsiveClass: true,
    nav: false,
    dots: true,
    autoplay: false,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 1,
      },
      700: {
        items: 1,
      },
      1000: {
        items: 3,
      }
    },
  };


  function createReview(stars) {
    const starArray = [];
  
    for (var i = 0; i < stars; i++) {
      starArray.push(<StarIcon key={i} />);
    }
  
    return starArray;
  }

 


  return (
      <Modal
        {...props} 
        size={modalSize}
        fullscreen="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
        {modalHeading}
        </Modal.Title>
      </Modal.Header>

          <Modal.Body>  
            
              <ul class="grey_list list_disc" >
              {contentData.type === 'terms' && contentData.contentData.map( (item)=>( 
                <li><p style={textAlignLeft}>{item.text}</p></li> 
              ))} 
              </ul> 

              {contentData.type === 'features' && contentData.contentData.map( (item)=>( 
                <div class="row">
                <div class="col-lg-6">
                <p style={textAlignLeft}>{item.text}</p>
                </div>
                <div class="col-lg-6" style={spanStyle}>{item.value}</div>
                </div>
              ))} 

              <ul class="grey_list list_disc" >
              {contentData.type === 'safety' && contentData.contentData.map( (item)=>(  
                <li><p style={textAlignLeft}>{item.s_name}</p></li> 
              ))}
              </ul> 

              { (contentData.type === 'reviews') && (
                 <div className="row text_testimon_slider">
                 <OwlCarousel className="owl-carousel owl-theme" {...options}>
                   {contentData.contentData.map((item) => (
                     <div className="item" key={Math.random()} >
                       <div className="textslide_upr d-flex justify-content-between">
                         <span className="quotes"><FormatQuoteIcon /></span>
                         <div className="img_name">
                           <div>
                             <p className="stars">
                               {createReview( item.ratings )} 
                             </p> 
                             <p className="byname">{ shortStringByLength(item.remark_by,20,true)} on {shortStringByLength(item.pickup_date,11)}</p>
                             <p>{item.remark}</p>
                           </div>
                         </div>
                       </div>
                     </div>
                   ))}
                 </OwlCarousel>
               </div>
              ) } 
            
          </Modal.Body>

      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}


export default CabPrivacyDetailsModelBox;