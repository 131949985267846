import React,{useState} from "react";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { shortStringByLength } from './helper/my_function_helper';

import StarIcon from '@material-ui/icons/Star';
import FormatQuoteIcon from '@material-ui/icons/FormatQuote';


const options = {
    margin: 10,
    responsiveClass: true,
    nav: false,
    dots: true,
    autoplay: false,
    smartSpeed: 1000,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        600: {
            items: 1,
        },
        700: {
            items: 1,
        },
        1000: {
            items: 3,

        }
    },
};
const ReviewTextSlider = (props) => {

    const [ reviewData, setReviewData ] = useState(  props.reviewList ); 

    function createReview(stars) {
        const starArray = [];
      
        for (var i = 0; i < stars; i++) {
          starArray.push(<StarIcon key={i} />);
        }
      
        return starArray;
      }

    
    return (
        <>
                <div className="row text_testimon_slider">
                    <OwlCarousel className="owl-carousel owl-theme" {...options}>
                    {reviewData.map((item) => (
                        <div className="item" key={Math.random()} >
                        <div className="textslide_upr d-flex justify-content-between">
                            <span className="quotes"><FormatQuoteIcon /></span>
                            <div className="img_name">
                            <div>
                                <p className="stars">
                                {createReview( item.ratings )} 
                                </p> 
                                <p className="byname">{ shortStringByLength(item.remark_by,20,true)} on {shortStringByLength(item.pickup_date,11)}</p>
                                <p>{item.remark}</p>
                            </div>
                            </div>
                        </div>
                        </div>
                    ))}
                    </OwlCarousel>
                </div>
            
        </>
    );
};
export default ReviewTextSlider;