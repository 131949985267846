import React from "react";
import pic1 from "../src/images/pack1.png";
//import pic2 from "../src/images/pack2.png";
import ArrowRightAltOutlined from '@material-ui/icons/ArrowRightAltOutlined';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined'; 

const PackageListBox = () => {
    return (
        <>
            <div className="col-sm-12"> 
                <div className="packgboxes cab_dt_box d-flex">
                    <div className="pkgimg">
                        <img src={pic1} alt={pic1}/>
                    </div>
                    <div class="pkgtxt_btns">
							<div class="d-flex pkgtxt_price align-items-center">
								<div class="pkgtxt">
									<span>3 Nights 4 Days Package tour</span>
									<h4>Chandigarh Shimla Manali Tour Package</h4>
									<p>You may be looking for “weekend getaways near me” but for the ideal weekend vacation where you have to worry about nothing but relaxing and
                                     enjoying yourself, you can opt for...</p>
							 	</div>
							 	<div class="tourprc">
							 		<p>Starts from</p>
							 		<h3>₹ 15000</h3>
							 		<span>*per person</span>
							 		<p class="cutprice">₹ 18000</p>
							 	</div>
							</div>
							<div class="pkgbtns d-flex align-items-center justify-content-between">
								<div class="loct_jorny">
									<LocationOnOutlinedIcon/><span>Delhi(1N) </span>
                                    <ArrowRightAltOutlined/>
									<span>Manali (2N)</span>
								</div>
								<div class="btnswrp">
								  <a href="package_details" class="btn_know  tbtn">View Package</a>
								  <a href="package_details" class="btn_know  bgbtn">Book Now</a>
								</div>
							</div>
					</div>
                </div>
            </div>
        </>
    )
}

export default PackageListBox