import React ,{ useState, useEffect }  from "react"; 
import axios                    from "axios"; 
import { ucWords }  from '../helper/my_function_helper';
import config                   from '../config/config';
import apiHeaderToken           from '../config/api_header';  

import AlertBox from "../modalbox/AlertBox";

import cam from "../images/cam.png";
import aleft from "../images/Arrow--left.png";


import "react-modern-calendar-datepicker/lib/DatePicker.css";
import DatePicker from 'react-modern-calendar-datepicker';
import DateRange from '@material-ui/icons/DateRange';


const WalletHistory = () => {
 
    let currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(currentDate.getDate()).padStart(2, '0');
    const today = {'day':day,'month': month, 'year': year };  

    //date 
    const [selectedFromDate, setSelectedFromDate] = useState( today ); 
    const [selectedToDate, setSelectedToDate] = useState( today );  

 
   //alert box message 
   const [showAlertMessage, setShowAlertMessage] = useState(''); 
   const [showAlertStatus, setShowAlertStatus] = useState( false ); 
   const delayTime = 3000; 


   //check Login user Data
   const userLoginData = JSON.parse( localStorage.getItem('login_data') ); 
   const [loginUserData, setLoginUserData] = useState( userLoginData );  


   const [walletDBList, setWalletDBList] = useState([]);
   const [walletPage, setWalletPage] = useState(1); 


   const fetchWalletRecordsList = ()=>{

    const payLoad = {}
    payLoad.user_id = loginUserData.user_id;
    payLoad.pageno = walletPage; 
    payLoad.from = '';//selectedFromDate ? `${selectedFromDate.year}-${selectedFromDate.month}-${selectedFromDate.day}` : '';
    payLoad.to = '';//selectedToDate ? `${selectedToDate.year}-${selectedToDate.month}-${selectedToDate.day}` : '';

    axios
    .post(`${config.API_URL}customer/wallet_history`, payLoad, apiHeaderToken(config.API_TOKEN) )
    .then((respData) => respData.data)
    .then((responseData) => {  

            if( responseData.status ){ 
                setWalletDBList( [...walletDBList, ...responseData.data]);  
                return false; 
            }else{
                setWalletPage( 0 );  
                return false;
            }
    }) 
    .catch( (error) =>{ 
        setShowAlertMessage( error );
        setShowAlertStatus(true);
        setTimeout( ()=>{ setShowAlertStatus(false);}, delayTime );
        return false; 
    });  
}
 

const loadMoreWalletRecords = () => {
    setWalletPage( walletPage + 1); 
    setTimeout(()=>{
        fetchWalletRecordsList();
    },500 );
};

useEffect( ()=>{
    loadMoreWalletRecords();
}, [] );



/************************** Start Of Date Function **********************************/  

function handleFromDateValue (e){ 
    setSelectedFromDate( e ); 
    //reset drop date
    setSelectedToDate( e ); 
}  
// render regular HTML input element
const renderCustomFromDateInput = ({ ref }) => (
  <input
    readOnly
    ref={ref} // necessary
    placeHolder={'From Date'}
    name={'from_date'}
    value={selectedFromDate ? `${selectedFromDate.day}/${selectedFromDate.month}/${selectedFromDate.year}` : ''}
    style={{
      textAlign: 'center',
      padding: '1rem 1.5rem',
      fontSize: '1rem',
      borderRadius: '100px',
      color: '#9c88ff',
      outline: 'none',
      cursor:'pointer'
    }}
    className="form-control"
    required
  />
)

function handleToDateValue (e){ 
    setSelectedToDate( e );
    setTimeout( ()=>{
        setWalletPage(1); 
        setTimeout(()=>{
            fetchWalletRecordsList();
        },500 );
    }, 500 ); 
}

const renderCustomToDateInput = ({ ref }) => (
  <input
    readOnly
    ref={ref} // necessary
    placeHolder={'To Date'}
    name={'to_date'}
    value={selectedToDate ? `${selectedToDate.day}/${selectedToDate.month}/${selectedToDate.year}` : ''}
    style={{
      textAlign: 'center',
      padding: '1rem 1.5rem',
      fontSize: '1rem',
      borderRadius: '100px',
      color: '#9c88ff',
      outline: 'none',
      cursor:'pointer'
    }}
    className="form-control"
    required
  />
)
/************************** End Of Date Function **********************************/




return (
    <>
    {showAlertStatus  && (<AlertBox type="error" message={showAlertMessage} />)} 
                        
                            <div className="daternge">
                                <p>Select Date Range</p>
                                <div className="sltdate">
                                    <label>From:</label>
                                        <DatePicker
                                        value={selectedFromDate}
                                        onChange={handleFromDateValue}
                                        renderInput={renderCustomFromDateInput} 
                                        name="from"
                                        shouldHighlightWeekends
                                        />
                                        <DateRange />
                                </div>
                                <div className="sltdate">
                                    <label>To:</label>
                                    <DatePicker
                                            value={selectedToDate}
                                            onChange={handleToDateValue}
                                            renderInput={renderCustomToDateInput} 
                                            shouldHighlightWeekends
                                    />
                                    <DateRange />
                                </div>
                                <button className="search_cb prflbtn">Search</button>
                            </div>
                            <div className="wallttable">
                                <table>
                                    <tr>
                                        <th>Transaction ID</th>
                                        <th>Amount</th>
                                        <th>Date & Time</th>
                                        <th>Credit / Debit</th>
                                    </tr>

                                    { walletDBList.length > 0 ? walletDBList.map( (item, index) => (

                                    <tr>
                                        <td>{item.order_id}</td>
                                        <td>₹ {item.amount}</td>
                                        <td> {item.added_on} </td>
                                        <td className= { (item.credit_debit === 'credit' ? 'txtgrn' : 'txtred' ) }>{ ucWords( item.credit_debit ) }</td>
                                    </tr>

                                    )) : (
                                        <>
                                        <div className="booknglistwrp">
                                         { (walletDBList.length === 0 ? 'Processing...' : ' No Records Found ') }
                                        </div>
                                        </> 
                                    ) 
                                    }

                                   
                                </table>

                                { walletPage !== 0 && (
                                            <div className="bkngdtls">
                                                <div style={ {'padding':'10px 20px 12px 20px'} } >
                                               <center> <button onClick={loadMoreWalletRecords} class="paybtn">Load More Records</button></center>
                                                </div>
                                                <br/><br/><br/><br/><br/><br/>
                                            </div>
                                        )}

                            </div>
                        

    </>
)

}

export default WalletHistory;