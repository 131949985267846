import React from "react";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import deal1 from "../src/images/deal1.png";
import deal2 from "../src/images/deal2.png";
import deal3 from "../src/images/deal3.png";

const options = {
    margin: 20,
    responsiveClass: true,
    nav: false,
    dots: true,
    autoplay: false,
    smartSpeed: 1000,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        600: {
            items: 2,
        },
        700: {
            items: 3,
        },
        1000: {
            items: 3,

        }
    },
};

const DealSlider = () => {
    return (
        <>
            <div className="container dealsec">
                <div className="row align-items-center">

                    <div className="col-sm-2 dealshdng">
                        <h2 className="site_hdng">Deal <br /> <span>of the </span><br /> day!</h2>
                        <a href="">View All</a>
                    </div>
                    <div className="col-sm-10 dealsimgs">
                        <OwlCarousel className="deals-items owl-carousel owl-theme" {...options}>
                                <div className="item"><img src={deal1} alt={deal1} /></div>
                                <div className="item"><img src={deal2} alt={deal2} /></div>
                                <div className="item"><img src={deal3} alt={deal3} /></div>
                                <div className="item"><img src={deal2} alt={deal2} /></div>
                        </OwlCarousel>
                    </div>
                </div>
            </div>
        </>
    );
};
export default DealSlider;