import React , { useEffect, useState } from "react";
import axios from 'axios';
import aboutimage from "../../src/images/about.png";
import config from "../config/config";

import { Helmet } from 'react-helmet'; 

const AboutSection = ( props ) => {

        const [ data, setData ] = useState( [] );

        const fetchAboutusData = ()=>{
            axios.post(`${config.API_URL}customer/common/cms/list`, {"page_type":"aboutus"} )
            .then( (response)=>{
                    return response.data;
            })
            .then( ( responseData )=>{
                 if( responseData.status ){  
                    setData( responseData.data );
                 }else{
                    setData( [] );
                 } 
            })
        } 

        useEffect(() => {
            fetchAboutusData(); 
          }, [])

    return (
        <>
            <div className="container">
                <div className="row j-align-center">
                    <div className="col-sm-6 left_img">
                        <img src={aboutimage} alt=""/>
                    </div>
                    <div className="col-sm-6 right_text"> 
                   
                        {data.length > 0 && ( 
                                data.map(user => (  
                                    <>
                                        <Helmet>
                                        <title>{user.meta_title}</title>
                                        <meta name="description" content={user.meta_description} />
                                        <meta name="keywords" content={user.meta_keyword} />
                                        </Helmet>
                                    
                                    <h2 className="site_hdng">{user.h_one_tag} </h2>
                                    <p align="justify"> {user.content_data} </p>                                  
                                    </>
                                ))
                        )}                        
                    </div>
                </div>
            </div>
        </>
    )
}

export default AboutSection