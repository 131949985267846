import React from "react";
import Faq from "./Faq";
import ReviewTextSlider from "./ReviewTextSlider";
import Blog_list from "./BlogList";
import AppDownload from "./AppDownload";
import Topfilter from "./TopFilter";
import ArrowRightAltOutlined from '@material-ui/icons/ArrowRightAltOutlined';
import fleet1 from "../src/images/fleet1.png";
import fleet2 from "../src/images/fleet2.png";
import RoundCabPrice from "./RoundCabPrice";
import Footer from "./partials/Footer";
import pkgdtl from "../src/images/pkgdtl.png";
import adv_img from "../src/images/adv_img.png";
import googleimg from "../src/images/google.png";
import Cablistbox from "./CabListBox";
import StarIcon from '@material-ui/icons/Star';


const SeoPage = () => {
    return (
        <>
            <Topfilter />
            <section className="sect_padding container">
                <div className="row sub_seomenu ">
                    <div className="col-sm-6 sbmenu box_sh_inner m-auto">
                        <ul>
                            <li><a href="">Local</a></li>
                            <li><a href="">One Way</a></li>
                            <li><a href="" className="active">Round Trip</a></li>
                            <li><a href="">Airport</a></li>
                        </ul>
                    </div>
                </div>
            </section>
            <section className="sect_padding route_info">
                <div className="container">
                    <div className="row text-center route_info">
                        <h2 className="sitepage_hdng">Lucknow <span> <ArrowRightAltOutlined /> </span> Delhi</h2>
                        <p className="rt_dtl">With 1932 Customer Reviews  |
                            <span className="stars">
                                <StarIcon /><StarIcon /><StarIcon /><StarIcon /><StarIcon />
                            </span>
                        </p>
                        <div className="cont">
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
                                Lorem Ipsum </p>
                        </div>
                    </div>
                </div>
            </section>
            <div className="container">
                <div className="row">
                    <div className="col-sm-8 pkg_descrption">
                        <div className="hdngrow">
                            <div className="lefthdng">
                                <h3 className="site_sub_hdng"> Select Cab Type </h3>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-4">
                                <div className="cabtype box_sh_inner ">
                                    <img src={fleet1} />
                                    <h5>Sedan</h5>
                                    <input type="radio" name="cabtype"/>
                                    <span class="checkmark"></span>
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className="cabtype box_sh_inner ">
                                    <img src={fleet1} />
                                    <h5>Hatchbook</h5>
                                    <input type="radio" name="cabtype" />
                                    <span class="checkmark"></span>
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className="cabtype box_sh_inner ">
                                    <img src={fleet1} />
                                    <h5>MUV</h5>
                                    <input type="radio" name="cabtype" />
                                    <span class="checkmark"></span>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <Cablistbox />
                            </div>
                            <div className="col-sm-6">
                                <Cablistbox />
                            </div>
                            <div className="col-sm-6">
                                <Cablistbox />
                            </div>
                            <div className="col-sm-6">
                                <Cablistbox />
                            </div>
                            <div className="col-sm-6">
                                <Cablistbox />
                            </div>
                            <div className="col-sm-6">
                                <Cablistbox />
                            </div>
                        </div>
                        <div className="hdngrow">
                            <div className="lefthdng">
                                <h3 className="site_sub_hdng"> Things To Know About Delhi </h3>
                            </div>
                        </div>
                        <div className="packgboxlist pkgdscsldr whiteshdow ">
                            <div className="pkgimg moreimg">
                                <img src={pkgdtl} />
                            </div>
                            <div className="pkgcont bgwhite">
                                <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer
                                 took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ips
um passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing 
Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                </p>
                            </div>
                        </div>
                     
                    </div>
                    <div className="col-sm-4 pkgdscright">
                        <section className="pkglist_right">
                            <div className="rght_hdng">
                                <h3 className="site_sub_hdng"> Cabs Nearby Delhi </h3>
                            </div>
                            <div className="pkglist">
                                <div className="lightwhiteshdow pkgname width50">
                                    <a href="">Delhi to Faridabad</a>
                                </div>
                                <div className="lightwhiteshdow pkgname width50">
                                    <a href="">Delhi to Faridabad</a>
                                </div>
                                <div className="lightwhiteshdow pkgname width50">
                                    <a href="">Delhi to Faridabad</a>
                                </div>
                                <div className="lightwhiteshdow pkgname width50">
                                    <a href="">Delhi to Faridabad</a>
                                </div>
                                <div className="lightwhiteshdow pkgname width50">
                                    <a href="">Delhi to Faridabad</a>
                                </div>
                                <div className="lightwhiteshdow pkgname width50">
                                    <a href="">Delhi to Faridabad</a>
                                </div>
                                <div className="lightwhiteshdow pkgname width50">
                                    <a href="">Delhi to Faridabad</a>
                                </div>
                                <div className="lightwhiteshdow pkgname width50">
                                    <a href="">Delhi to Faridabad</a>
                                </div>
                               
                                <div className="lightwhiteshdow pkgname width50">
                                    <a href="">Delhi to Faridabad</a>
                                </div>
                                <div className="lightwhiteshdow pkgname width50">
                                    <a href="">Delhi to Faridabad</a>
                                </div>

                            </div>
                        </section>
                        <section className="featr_tour">
                            <div className="rght_hdng">
                                <h3 className="site_sub_hdng"> Testimonial </h3>
                            </div>
                            <div className="vdoslider-items item">
                                <iframe src="https://www.youtube.com/embed/Ql8pai_D-Zs" title="YouTube video player"
                                    frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen></iframe>

                            </div>
                        </section>
                        <div className="sideimgs">
                            <img src={googleimg} />
                            <img src={adv_img} />

                        </div>
                        <section className="pkglist_right">
                            <div className="rght_hdng">
                                <h3 className="site_sub_hdng"> Cabs Nearby Delhi </h3>
                            </div>
                            <div className="pkglist">
                                <div className="lightwhiteshdow pkgname width50">
                                    <a href="">Delhi to Faridabad</a>
                                </div>
                                <div className="lightwhiteshdow pkgname width50">
                                    <a href="">Delhi to Faridabad</a>
                                </div>
                                <div className="lightwhiteshdow pkgname width50">
                                    <a href="">Delhi to Faridabad</a>
                                </div>
                               
                                <div className="lightwhiteshdow pkgname width50">
                                    <a href="">Delhi to Faridabad</a>
                                </div>
                                <div className="lightwhiteshdow pkgname width50">
                                    <a href="">Delhi to Faridabad</a>
                                </div>
                                <div className="lightwhiteshdow pkgname width50">
                                    <a href="">Delhi to Faridabad</a>
                                </div>

                                <div className="lightwhiteshdow pkgname width50">
                                    <a href="">Delhi to Faridabad</a>
                                </div>
                                <div className="lightwhiteshdow pkgname width50">
                                    <a href="">Delhi to Faridabad</a>
                                </div>
                                <div className="lightwhiteshdow pkgname width50">
                                    <a href="">Delhi to Faridabad</a>
                                </div>
                                <div className="lightwhiteshdow pkgname width50">
                                    <a href="">Delhi to Faridabad</a>
                                </div>

                            </div>
                        </section>
                    </div>
                </div>
            </div>
            <section>
                <div className="container">
                    <div className="hdngrow">
                        <div className="lefthdng">
                            <h3 className="site_sub_hdng"> Our Blog </h3>
                        </div>
                        <div className="rght_hdng">
                            <a href="#" className="sidelink">View All</a>
                        </div>
                    </div>
                </div>
                <Blog_list />
            </section>
            <section>
                <div className="container">
                    <div className="hdngrow">
                        <div className="lefthdng">
                            <h3 className="site_sub_hdng"> FAQ </h3>
                        </div>
                        <div className="rght_hdng">
                            <a href="#" className="sidelink">View All</a>
                        </div>
                    </div>
                </div>
                <Faq />
            </section>
            <section>
                <div className="container">
                    <div className="row hdngrow m-auto text-center">
                        <div className="col-sm-12 lefthdng">
                            <h3 className="site_sub_hdng"> Round Trip Cabs Prices </h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.
                                ut labore et dolore magna aliqua. </p>
                        </div>
                    </div>
                    <RoundCabPrice />
                </div>
            </section>
            <section>
                <div className="container">
                    <div className="row hdngrow m-auto text-center">
                        <div className="col-sm-12 lefthdng">
                            <h3 className="site_sub_hdng"> Happy Travelers <br /> from Delhi for Cab Hire Services </h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.
                                ut labore et dolore magna aliqua. </p>
                        </div>
                    </div>
                    <ReviewTextSlider cab_id ={''} />
                </div>
            </section>
            <section className="add_download sect_padding">
                <AppDownload />
            </section>
            <section className="footer">
                <Footer />
            </section>
        </>
    );
};

export default SeoPage;