import React ,{ useState, useEffect }  from "react"; 
import axios                    from "axios"; 
import { ucWords, allDateFormat, handleNullValue, getWordsBeforeComma, getProfileLoginDataFields  }  from '../helper/my_function_helper';
import config                   from '../config/config';
import apiHeaderToken           from '../config/api_header';  

import AlertBox from "../modalbox/AlertBox";

import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import usr from "../images/User--avatar.png";
import bkng from "../images/Certificate--check.png";
import wallt from "../images/Wallet.png";
import usr1 from "../images/User--avatar1.png";
import bkng1 from "../images/Certificate--check1.png";
import wallt1 from "../images/Wallet1.png";
import cam from "../images/cam.png";
import Form from 'react-bootstrap/Form';
import logo from "../images/logo.png";
import aleft from "../images/Arrow--left.png";

import WalletHistory from './WalletHistory';
 


const MyProfile = () => {
    const [bookingShow, setBookingShow] = useState(false)

    //set booking pagination pages
    const [bookingListShow, setBookingListShow] = useState(false)
    const [bookingsDBList, setBookingsDBList] = useState([]);
    const [bookingPage, setBookingPage] = useState(1); 
    const [bookingsDetails, setBookingsDetails] = useState({});

    //set wallet pagination pages
    const [walletShow, setWalletShow] = useState(false);
    const [walletTransactionShow, setWalletTransactionShow] = useState(false);
    const [rechargePlanList, setRechargePlanList] = useState([]);
    const [selectedRechargeAmount, setSelectedRechargeAmount] = useState(0); 
    
    const [payNowButton, setPayNowButton] = useState( 'Pay Now' ); 

    //alert box message 
    const [showAlertMessage, setShowAlertMessage] = useState(''); 
    const [showAlertStatus, setShowAlertStatus] = useState( false ); 
    const delayTime = 3000; 


    //check Login user Data
    const userLoginData = JSON.parse( localStorage.getItem('login_data') ); 
    const [loginUserData, setLoginUserData] = useState( userLoginData );  
    const [profileImage, setProfileImage] = useState( (loginUserData ? loginUserData.profile_image : cam) );

    //city state db list 
    const [cityOneDBList, setCityOneDBList] = useState([]); 
    const [cityTwoDBList, setCityTwoDBList] = useState([]); 

     

    //update profile data
    const putProfileData = {
        user_id: '',
        user_name: '',
        user_mobile:'', 
        user_email:'', 
        gender:'',
        kyc_status:'',
        pin_code:'',
        address:'',
        city_id:'',
        city_name:'',
        state_name:'',
        comp_address:'',
        comp_city_name:'',
        comp_gstin:'',
        comp_name:'',
        comp_pan:'',
        comp_pin_code:'',
        comp_state_name:'', 
      }

      
      const [profileData, setProfileData] = useState( putProfileData );
      const handleFormInputChange = ( nameValueObj ) => { 
        for (let property in nameValueObj ) { 
            profileData[property] = nameValueObj[property];
        } 
        setProfileData({
          ...profileData
        });

        console.log( profileData );
      }; 

    

    //use effect
    useEffect(()=>{
        setTimeout(()=>{
            setProfileData( loginUserData );
            console.log( loginUserData );
        }, 200 );
    },[loginUserData,setProfileData ]);


    const handleLoginChange = ( nameValueObj ) => { 
        for (let property in nameValueObj ) { 
            loginUserData[property] = nameValueObj[property];
        } 
        setLoginUserData({
          ...loginUserData
        });

        localStorage.removeItem('login_data');
        localStorage.setItem('login_data', JSON.stringify( loginUserData ) );
    };



/*******************   Profile Image Upload  Start Script ***********************/
    const handleFileChange = (event) => { 
        const selectedFile = event.target.files[0];  

        const allowedFormats = ['image/jpeg', 'image/png', 'image/gif'];  
    
        if (selectedFile && allowedFormats.indexOf(selectedFile.type) === -1) { 
            setShowAlertMessage( 'Invalid file format. Please select a JPEG, PNG, or GIF file.' );
            setShowAlertStatus(true);
            setTimeout( ()=>{ setShowAlertStatus(false);}, 3000 ); 
            return false;
        }else if( selectedFile ){ 
            setTimeout( ()=>{ handleProfileImageSubmit( selectedFile ); }, 200 ); 
        } 
    }; 

    const handleProfileImageSubmit = ( selectedFile ) => {  
    
        //Create a FormData object
        const formData = new FormData();
        formData.append('_id', loginUserData.user_id );
        formData.append('filename', selectedFile );

        const headersData = apiHeaderToken(config.API_TOKEN).headers;
        const nameValueObj = { 'Content-Type': 'multipart/form-data' };
        for (let property in nameValueObj ) { 
            headersData[property] = nameValueObj[property];
        }   


        axios
        .post(`${config.API_URL}customer/upload_profile_image`, formData, {'headers':headersData} )
        .then((respData) => respData.data)
        .then((responseData) => {  

                if( responseData.status ){  
                    setProfileImage( responseData.data );
                    handleLoginChange({'profile_image':responseData.data});
                    setShowAlertMessage( responseData.message );
                    setShowAlertStatus(true);
                    setTimeout( ()=>{ setShowAlertStatus(false);}, delayTime );
                    return false; 
                }else{
                    setShowAlertMessage( responseData.message );
                    setShowAlertStatus(true);
                    setTimeout( ()=>{ setShowAlertStatus(false);}, delayTime );
                    return false;
                }
        }) 
        .catch( (error) =>{ 
            setShowAlertMessage( error );
            setShowAlertStatus(true);
            setTimeout( ()=>{ setShowAlertStatus(false);}, delayTime );
            return false; 
        });   
        
      }
/*******************  Profile Image Upload End Script ***********************/
      
const getInputChange = (e) => {
    const value = e.target.value; 
    const name = e.target.name;  

    if( ['city_name','comp_city_name'].includes( name ) ){
        handleCityInputChange( value, name );
    }
     
    setTimeout(() => { 
        handleFormInputChange( { [name] : value } ); 
    }, 30);

  };



  /************************* Start Of Search City input Fields  *****************************/  
  let timer;

  // Fetch live Records From Db
  const gatherMatchRecords = ( value, name ) => {
    axios.post(`${config.API_URL}customer/citylist`, { keyword: value, page_no: 1, records_per_page: 10, trip_type: 'all' }, apiHeaderToken(config.API_TOKEN) )
      .then((respData) => respData.data)
      .then((responseData) => {
        if (responseData.status) {
            if( name === 'city_name' ){
                setCityOneDBList( responseData.data );  
            }else{
                setCityTwoDBList( responseData.data ); 
            }
            
        } else {
            setCityOneDBList([]); 
            setCityTwoDBList([]);  
        }
      })
      .catch((error) => { 
            setCityOneDBList([]); 
            setCityTwoDBList([]);   
    });
  };


  const handleCityInputChange = (value, name ) => { 
    clearTimeout(timer);
    timer = setTimeout(() => {
      gatherMatchRecords( value , name ); 
    }, 300);
  };

  const handleSuggestionClick = (suggestion, typeName ) => {
    let city_name = suggestion.city_name; 
     
    if( typeName === 'city_name' ){
        setCityOneDBList([]); 
        handleFormInputChange( { 'city_name' : city_name,'city_id': suggestion._id, 'state_name': suggestion.state_name } ); 
    }
    else{
        handleFormInputChange( { 'comp_city_name' : city_name,'comp_state_name': suggestion.state_name } ); 
        setCityTwoDBList([]);
    }
}

  
  const ulListStyle = {
    position: 'absolute',
    zIndex: 900,
    background: '#fbfdfd',
    top: '57px',
    left: '29px',
  };

  const listStyle = {
    padding: '5px 0px 5px 5px',
    borderBottom: '1px dotted #111111',
    width: '463px',
    cursor: 'pointer',
  };
  /************************* End Of Search City input Fields  *****************************/


  const handleProfileDataSubmit = () => {   

    //Create a FormData object
    const formData = new FormData();
    formData.append('_id', profileData.user_id ); 
    formData.append('full_name', profileData.user_name ); 
    formData.append('mobile_no', profileData.user_mobile ); 
    formData.append('email_id', profileData.user_email ); 
    formData.append('city_id', profileData.city_id ); 
    formData.append('city_name', profileData.city_name ); 
    formData.append('state_id', profileData.state_id ); 
    formData.append('state_name', profileData.state_name ); 
    formData.append('gender', profileData.gender ); 
    formData.append('pin_code', profileData.pin_code ); 
    formData.append('address', profileData.address ); 
    formData.append('comp_name', profileData.comp_name ); 
    formData.append('comp_pan', profileData.comp_pan ); 
    formData.append('comp_gstin', profileData.comp_gstin ); 
    formData.append('comp_city_name', profileData.comp_city_name ); 
    formData.append('comp_state_name', profileData.comp_state_name ); 
    formData.append('comp_address', profileData.comp_address ); 
    formData.append('comp_pin_code', profileData.comp_pin_code ); 

    const headersData = apiHeaderToken(config.API_TOKEN).headers;
    const nameValueObj = { 'Content-Type': 'application/json'};
    for (let property in nameValueObj ) { 
        headersData[property] = nameValueObj[property];
    }   


    axios
    .post(`${config.API_URL}customer/update_user_profile`, formData, {'headers':headersData}  )
    .then((respData) => respData.data)
    .then((responseData) => {  

            if( responseData.status ){ 
                 var loginData = responseData.data;
                 var storeLoginData = getProfileLoginDataFields( loginData, handleNullValue );
                //assign Login data in storage
                localStorage.setItem('login_data', JSON.stringify( storeLoginData ) ); 
                
                setShowAlertMessage( responseData.message );
                setShowAlertStatus(true);
                setTimeout( ()=>{ setShowAlertStatus(false);}, delayTime );
                return false; 
            }else{
                setShowAlertMessage( responseData.message );
                setShowAlertStatus(true);
                setTimeout( ()=>{ setShowAlertStatus(false);}, delayTime );
                return false;
            }
    }) 
    .catch( (error) =>{ 
        setShowAlertMessage( error );
        setShowAlertStatus(true);
        setTimeout( ()=>{ setShowAlertStatus(false);}, delayTime );
        return false; 
    });   
    
  }

/******************** Booking List And Wallet Control Script Start *************************/
const handleBookingDetailsClick = event => { 
    setBookingShow(true);
    setBookingListShow(false);
};
const goBack = event => {
    setBookingShow(false);
    setBookingListShow(true); 
};
const transactionClick = event => {
    setWalletShow(false);
    setWalletTransactionShow(true);
};
const walletBack = event => {
    setWalletShow(true);
    setWalletTransactionShow(false);
};
/******************** Booking List And Wallet Control Script End *************************/

const fetchBookingRecordsList = ()=>{

    const payLoad = {}
    payLoad.user_id = profileData.user_id;
    payLoad.page_no = bookingPage;
    payLoad.per_page_records = 10; 

    axios
    .post(`${config.API_URL}customer/booking/list`, payLoad, apiHeaderToken(config.API_TOKEN) )
    .then((respData) => respData.data)
    .then((responseData) => {  

            if( responseData.status ){ 
                setBookingsDBList( [...bookingsDBList, ...responseData.data]);  
                return false; 
            }else{
                setBookingPage( 0 );  
                return false;
            }
    }) 
    .catch( (error) =>{ 
        setShowAlertMessage( error );
        setShowAlertStatus(true);
        setTimeout( ()=>{ setShowAlertStatus(false);}, delayTime );
        return false; 
    });  
}
 

const loadMoreBookingRecords = () => {
    setBookingPage( bookingPage + 1); 
    setTimeout(()=>{
        fetchBookingRecordsList();
    },500 );
};


const fetchBookingDetailsData = ( id )=>{

    const payLoad = {}
    payLoad._id = id; 

    axios
    .post(`${config.API_URL}customer/booking/details`, payLoad, apiHeaderToken(config.API_TOKEN) )
    .then((respData) => respData.data)
    .then((responseData) => {  

            if( responseData.status ){  
                setBookingsDetails( responseData.data ); 
                return false; 
            }else{
                setShowAlertMessage( responseData.message );
                setShowAlertStatus(true);
                setTimeout( ()=>{ setShowAlertStatus(false);}, delayTime );
                return false;
            }
    }) 
    .catch( (error) =>{ 
        setShowAlertMessage( error );
        setShowAlertStatus(true);
        setTimeout( ()=>{ setShowAlertStatus(false);}, delayTime );
        return false; 
    });  
}


/************************** Add Wallet Balance And Wallet List Script Start *************************/

const fetchRechargePlansList = ()=>{

    const payLoad = {}
    payLoad.user_id = profileData.user_id; 

    console.log( `${config.API_URL}customer/rechargeplan/list` );
    console.log( JSON.stringify( payLoad ) );

    axios
    .post(`${config.API_URL}customer/rechargeplan/list`, payLoad, apiHeaderToken(config.API_TOKEN) )
    .then((respData) => respData.data)
    .then((responseData) => {  

            console.log( responseData );

            if( responseData.status ){ 
                setRechargePlanList( responseData.data );  
                return false; 
            }else{ 
                setShowAlertMessage( responseData.message );
                setShowAlertStatus(true);
                setTimeout( ()=>{ setShowAlertStatus(false);}, delayTime );
                return false;
            }
    }) 
    .catch( (error) =>{ 
        setShowAlertMessage( error );
        setShowAlertStatus(true);
        setTimeout( ()=>{ setShowAlertStatus(false);}, delayTime );
        return false; 
    });  
}

/******************** Add wallet Balance and Wallet List Script End *************************/


/************* razorpay gateway Start Script ************/  

function loadScript(src) {
    return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = src;
        script.onload = () => {
            resolve(true);
        };
        script.onerror = () => {
            resolve(false);
        };
        document.body.appendChild(script);
    });
}


const handlePayment = () => {  

    //load checkout js library
    const res = loadScript( "https://checkout.razorpay.com/v1/checkout.js" );

    if (!res) { 
        setShowAlertMessage( "Razorpay SDK failed to load. Are you online?" );
        setShowAlertStatus(true);
        setTimeout( ()=>{ setShowAlertStatus(false);}, 3000 );
        return false; 
    }

    const payLoad = {}
    payLoad.user_id = loginUserData.user_id;
    payLoad.amount = selectedRechargeAmount;
    payLoad.online_charge = '';

    axios
      .post(`${config.API_URL}customer/generate_order_id`, payLoad,  apiHeaderToken(config.API_TOKEN))
      .then((respData) => respData.data)
      .then((responseData) => { 
            if( responseData.status ){
                setPayNowButton('Processing...');
                    //var orderId = responseData.data.razor_token;
                    const { orderid, razor_token, amount } = responseData.data;
                     
                            const options = {
                                key: config.RAZORPAY_KEY_ID ,
                                amount: parseInt( amount ),
                                currency: "INR",
                                name: config.COMPANY_NAME,
                                description: "Test Transaction",
                                image: logo,
                                order_id: razor_token,
                                handler: ( response ) => {
                                        setPayNowButton('Please wait...'); 
                                        axios
                                        .post(`${config.API_URL}customer/capture_amount`, {"user_id": loginUserData.user_id,"orderid":orderid,"razor_payid":response.razorpay_payment_id},  apiHeaderToken(config.API_TOKEN))
                                        .then((respData) => respData.data)
                                        .then((responseData) => { 
                                                if( responseData.status ){ 
                                                    //console.log( responseData.data.wallet_balance );
                                                    handleLoginChange({'wallet_balance': responseData.data.wallet_balance }); 
                                                    setShowAlertMessage( responseData.message );
                                                    setShowAlertStatus(true);
                                                    setTimeout( ()=>{ setShowAlertStatus(false);}, delayTime );
                                                    setPayNowButton('Pay Now');
                                                    setSelectedRechargeAmount( 0 );
                                                    return false;
                                                }else{
                                                    setShowAlertMessage( responseData.message );
                                                    setShowAlertStatus(true);
                                                    setTimeout( ()=>{ setShowAlertStatus(false);}, delayTime );
                                                    return false;
                                                }
                                        }) 
                                },
                                prefill: {
                                    name: loginUserData.user_name,
                                    email: loginUserData.user_email,
                                    contact: loginUserData.user_mobile,
                                },
                                notes: {
                                address: "903, 9th Floor Pearls Business Park, Neta Ji Subhas Place, Pitam Pura, Delhi, 110034",
                                },
                                theme: {
                                color: "#3399cc",
                                },
                            };

                    const paymentObject = new window.Razorpay(options);
                    paymentObject.open();
            }else{
                setShowAlertMessage( responseData.message );
                setShowAlertStatus(true);
                setTimeout( ()=>{ setShowAlertStatus(false);}, delayTime );
                return false;
            }
        }); 
}

/************* razorpay gateway End Script ************/ 


    return (
        <>
        {showAlertStatus  && (<AlertBox type="error" message={showAlertMessage} />)}
            <div className="container">
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <Row>
                        <Col sm={3}>
                            <Nav variant="pills" className="sidecolmfxd flex-column">
                                <Nav.Item>
                                    <Nav.Link eventKey="first"><img src={usr} className="inact" /><img src={usr1} className="act" /> My Profile</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="second" onClick={()=>{ fetchBookingRecordsList(); setBookingListShow(true); }}  ><img src={bkng} className="inact" /><img src={bkng1} className="act" /> My Bookings</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="third" onClick={()=>{ fetchRechargePlansList(); setWalletShow(true);}}  ><img src={wallt} className="inact" /> <img src={wallt1} className="act" /> My Wallet</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Col>
                        <Col sm={9}>
                            <Tab.Content className="dashwrpr">
                                <Tab.Pane eventKey="first">
                                    <div className="tbcntwrpr">
                                        <h3>My Profile</h3>
                                        <form action="#">
                                            <div className="proflform_row">
                                                <div className="proflimg">
                                                    <label className="uploadFile">
                                                        <img src={profileImage} alt="Profile Image" />
                                                        <input type="file" className="inputfile form-control" name="file" onChange={handleFileChange} />
                                                    </label>
                                                    <h5>Upload Profile Pic</h5>
                                                </div>
                                                <div className="proflformwrp">
                                                    <div className="row">
                                                        <div className="col-md-6 col-12 col-lg-6 form-element">
                                                            <label> Name</label>
                                                            <input type="text" name="user_name" placeholder="Enter Name" onChange={getInputChange} className="form-control box_sh_inner" value={profileData.user_name} onInput={(event) => { event.target.value = event.target.value.replace(/[^0-9a-zA-Z.\s]+$/g,'').slice(0, 100); }}></input>
                                                        </div>
                                                        <div className="col-md-6 col-12 col-lg-6 form-element">
                                                            <label> Mobile Number</label>
                                                            <input type="text" name="user_mobile" placeholder="Enter Mobile Number" onInput={(event) => { event.target.value = event.target.value.replace(/[^0-9]/g, '').slice(0, 10); }}  className="form-control box_sh_inner" value={profileData.user_mobile}></input>
                                                        </div>
                                                        <div className="col-md-6 col-12 col-lg-6 form-element">
                                                            <label> Email Id</label>
                                                            <input type="text" name="user_email" placeholder="Enter Email Id" onChange={getInputChange} className="form-control box_sh_inner" value={profileData.user_email} onInput={(event) => { event.target.value = event.target.value.replace(/[^@_a-zA-Z0-9\.]/g,'').slice(0, 120); }}></input>
                                                        </div>
                                                        <div className="col-md-6 col-12 col-lg-6 form-element">
                                                            <label> City</label>
                                                            <input type="text" placeholder="Enter City" name="city_name"  className="form-control box_sh_inner" onChange={getInputChange} value={profileData.city_name} onInput={(event) => { event.target.value = event.target.value.replace(/[^0-9a-zA-Z.\s]+$/g,'').slice(0, 60); }}></input>
                                                            <input type="hidden" id="city_id" name="city_id" value={profileData.city_id} />
                                                            <ul style={ulListStyle}>
                                                            { cityOneDBList && cityOneDBList.map((item, index) => (
                                                            <li key={index} onClick={() => handleSuggestionClick(item,'city_name')} style={listStyle}> {item.city_name+', '+ item.state_name}</li>
                                                            ))}
                                                            </ul>
                                                        </div>
                                                        <div className="col-md-6 col-12 col-lg-6 form-element">
                                                            <label>State</label>
                                                            <input type="text" placeholder="Enter State Name" name="state_name" className="form-control box_sh_inner" onChange={getInputChange} value={profileData.state_name} onInput={(event) => { event.target.value = event.target.value.replace(/[^0-9a-zA-Z.\s]+$/g,'').slice(0, 60); }} ></input>
                                                        </div>
                                                        <div className="col-md-6 col-12 col-lg-6 form-element">
                                                            <label> Pincode</label>
                                                            <input type="text"  placeholder="Enter Pincode" name="pin_code" className="form-control box_sh_inner" onChange={getInputChange} value={profileData.pin_code} onInput={(event) => { event.target.value = event.target.value.replace(/[^0-9]/g, '').slice(0, 6); }}></input>
                                                        </div>
                                                        <div className="col-md-6 col-12 col-lg-6 form-element">
                                                            <label> Address</label>
                                                            <Form.Control as="textarea" name="address" rows={3} onChange={getInputChange} value={profileData.address}  onInput={(event) => { event.target.value = event.target.value.replace(/[^0-9a-zA-Z.,:-\s]+$/g,'').slice(0, 130); }}  />
                                                        </div>
                                                    </div>

                                                    <div style={{'width':'100%','border':'1px solid rgb(128 128 128)','marginBottom':'20px','marginTop':'10px'}}></div>
                                                    
                                                    <div className="row">
                                                        <div className="col-md-6 col-12 col-lg-6 form-element">
                                                            <label> Company name</label>
                                                            <input type="text" placeholder="Enter Company Name" name="comp_name" className="form-control box_sh_inner" onChange={getInputChange}  value={profileData.comp_name} onInput={(event) => { event.target.value = event.target.value.replace(/[^0-9a-zA-Z.\s]+$/g,'').slice(0, 120) } }></input>
                                                        </div>
                                                        <div className="col-md-6 col-12 col-lg-6 form-element">
                                                            <label> Company Pan number</label>
                                                            <input type="text" placeholder="Enter Pan Number" name="comp_pan" onChange={getInputChange} className="form-control box_sh_inner" value={profileData.comp_pan} onInput={(event) => { event.target.value = event.target.value.replace(/[^0-9a-zA-Z]/g, '').slice(0, 10).toUpperCase(); }} ></input>
                                                        </div>
                                                        <div className="col-md-6 col-12 col-lg-6 form-element">
                                                            <label> GSTIN</label>
                                                            <input type="text" placeholder="Enter GSTIN" name="comp_gstin" onChange={getInputChange} className="form-control box_sh_inner" value={profileData.comp_gstin} onInput={(event) => { event.target.value = event.target.value.replace(/[^0-9a-zA-Z]/g, '').slice(0, 60).toUpperCase(); }} ></input>
                                                        </div>
                                                        <div className="col-md-6 col-12 col-lg-6 form-element">
                                                            <label> City</label>
                                                            <input type="text" placeholder="Enter City" name="comp_city_name" onChange={getInputChange} className="form-control box_sh_inner" value={profileData.comp_city_name} onInput={(event) => { event.target.value = event.target.value.replace(/[^0-9a-zA-Z.\s]+$/g,'').slice(0, 60); }} ></input>
                                                            <ul style={ulListStyle}>
                                                            { cityTwoDBList && cityTwoDBList.map((item, index) => (
                                                            <li key={index} onClick={() => handleSuggestionClick(item,'comp_city_name')} style={listStyle}> {item.city_name+', '+ item.state_name}</li>
                                                            ))}
                                                            </ul>
                                                        </div>
                                                        <div className="col-md-6 col-12 col-lg-6 form-element">
                                                            <label>State</label>
                                                            <input type="text" placeholder="Enter State Name" name="comp_state_name" onChange={getInputChange} className="form-control box_sh_inner" value={profileData.comp_state_name} onInput={(event) => { event.target.value = event.target.value.replace(/[^0-9a-zA-Z.\s]+$/g,'').slice(0, 60); }}  ></input>
                                                        </div>
                                                        <div className="col-md-6 col-12 col-lg-6 form-element">
                                                            <label> Pincode</label>
                                                            <input type="text" placeholder="Enter Pincode" name="comp_pin_code" onChange={getInputChange} className="form-control box_sh_inner" value={profileData.comp_pin_code} onInput={(event) => { event.target.value = event.target.value.replace(/[^0-9]/g, '').slice(0, 6); }} ></input>
                                                        </div>
                                                        <div className="col-md-6 col-12 col-lg-6 form-element">
                                                            <label> Address</label>
                                                            <Form.Control as="textarea" rows={3} value={profileData.comp_address} name="comp_address" onChange={getInputChange} onInput={(event) => { event.target.value = event.target.value.replace(/[^0-9a-zA-Z.,\s]+$/g,'').slice(0, 130); }}   />
                                                        </div>
                                                        <div className="col-md-6 col-12 col-lg-6 form-element">
                                                            <input type="button" onClick={handleProfileDataSubmit} className="search_cb prflbtn" value="Save"></input>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </Tab.Pane>


                                <Tab.Pane eventKey="second">
                                    <div className="tbcntwrpr">
                                        <h3>My Booking</h3>
                                        { bookingListShow && ( bookingsDBList.length > 0 ? bookingsDBList.map( (item, index) => (
                                            <div className="booknglistwrp">
                                                <div className="bookboxdash">
                                                    <div className="d-flxbtwn bkiddate">
                                                        <p>Booking ID: {item.booking_id}</p>
                                                        <p>Booking Date: {item.add_date}</p>
                                                    </div>
                                                    <div className="d-flxbtwn addrstag">
                                                        <p>{item.travel_route}</p>
                                                        <span className="bggrn">{item.booking_status}</span>
                                                    </div>
                                                    <div className="bkdtlbtns d-flxbtwn">
                                                        <div className="bkndtls">
                                                            <div className="abtbkng">
                                                                <span>Trip Type</span>
                                                                <p>{ucWords(item.trip_type)}</p>
                                                            </div>
                                                            <div className="abtbkng">
                                                                <span>Pickup Date & Time</span>
                                                                <p>{item.pickup_date}</p>
                                                            </div>
                                                            <div className="abtbkng">
                                                                <span>Vehicle</span>
                                                                <p>{item.vehicle_model}</p>
                                                            </div>
                                                        </div>
                                                        <div className="bknbtnswrp">
                                                            <a href="javascript:void(0)" className="bkbtns actv" onClick={()=>{ fetchBookingDetailsData( item._id ); handleBookingDetailsClick();  }}>View</a>

                                                            { item.booking_status === 'Completed' && ( 
                                                            <a href="javascript:void(0)" className="bkbtns">Invoice</a>
                                                            )} 

                                                            { item.rating_status === 'No' && ( 
                                                            <a href="javascript:void(0)" className="bkbtns">Rate Us</a>
                                                            )} 
                                                             
                                                            { item.booking_status === 'Pending' && ( 
                                                            <a href="javascript:void(0)" className="bkbtns" >Cancel</a>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div> 
                                                
                                            </div>
                                        )) : (
                                            <>
                                            <div className="booknglistwrp">
                                            No Records Found
                                            </div>
                                            </> 
                                           ) 
                                        )

                                        }

                                        { bookingPage !== 0 && (
                                            <div className="bkngdtls">
                                                <div style={ {'padding':'10px 20px 12px 20px'} } >
                                               <center> <button onClick={loadMoreBookingRecords} class="paybtn">Load More Bookings</button></center>
                                                </div>
                                                <br/><br/><br/><br/><br/><br/>
                                            </div>
                                        )}


                                        {bookingShow && bookingsDetails && (
                                            <div className="bkngdtls">
                                                <div className="backbtnid">
                                                    <button onClick={goBack}> <img src={aleft} /> </button>
                                                    <span>Booking ID: {bookingsDetails.booking_id}</span>
                                                </div>
                                                <div className="bookboxdash">
                                                    <div className="d-flxbtwn bkiddate">
                                                        <img src={logo} />
                                                        <p>Booking Date: {bookingsDetails.add_date}</p>
                                                    </div>
                                                    <p className="bkid">Booking ID: {bookingsDetails.booking_id}</p>
                                                    <div class="dashroute">
                                                        <p><span>Route</span>: {bookingsDetails.travel_route}</p>
                                                    </div>
                                                    <div className="dtlsfields">
                                                        <div className="dfld">
                                                            <p><span>Pick up from:</span>{bookingsDetails.from_city}</p>
                                                        </div> 
                                                        
                                                        { typeof bookingsDetails.to_city !=='undefined' && bookingsDetails.to_city && (
                                                        <div className="dfld">
                                                            <p><span>Drop To:</span>{bookingsDetails.to_city}</p>
                                                        </div>
                                                        )}
                                                        <div className="dfld">
                                                            <p><span>Trip Type:</span>{ bookingsDetails.trip_type }</p>
                                                        </div>
                                                        <div className="dfld">
                                                            <p><span>Pick up Date:</span>{bookingsDetails.pickup_date}</p>
                                                        </div>
                                                        { typeof bookingsDetails.drop_date !=='undefined' && bookingsDetails.drop_date && (
                                                        <div className="dfld">
                                                            <p><span>Drop Date:</span>{bookingsDetails.drop_date}</p>
                                                        </div>
                                                        )}
                                                    </div>
                                                    <div className="dtlsfields">
                                                        { typeof bookingsDetails.user_name !=='undefined' && bookingsDetails.user_name && (
                                                        <div className="dfld">
                                                            <p><span>Customer Name:</span>{bookingsDetails.user_name}</p>
                                                        </div>
                                                        )}

                                                        { typeof bookingsDetails.user_email !=='undefined' && bookingsDetails.user_email && (
                                                        <div className="dfld">
                                                            <p><span>Email ID:</span>{bookingsDetails.user_email}</p>
                                                        </div>
                                                        )}

                                                        { typeof bookingsDetails.user_mobile !=='undefined' && bookingsDetails.user_mobile && (
                                                        <div className="dfld">
                                                            <p><span>Mobile number:</span>{bookingsDetails.user_mobile}</p>
                                                        </div>
                                                        )}

                                                        { typeof profileData.comp_name !=='undefined' && profileData.comp_name && (
                                                        <div className="dfld">
                                                        <p><span>Company:</span>{profileData.comp_name}</p>
                                                        </div>
                                                        )} 

                                                        { typeof profileData.comp_pan !=='undefined' && profileData.comp_pan && (
                                                            <div className="dfld">
                                                                <p><span>Pan number:</span>{profileData.comp_pan}</p>
                                                            </div>
                                                        )}

                                                        { typeof profileData.comp_gstin !=='undefined' && profileData.comp_gstin && (
                                                        <div className="dfld">
                                                        <p><span>GSTIN:</span>{profileData.comp_gstin}</p>
                                                        </div>
                                                        )} 
                                                        
                                                    </div>

                                                    <div className="dtlsfields">
                                                    { typeof bookingsDetails.per_km_charge !=='undefined' && bookingsDetails.per_km_charge && (
                                                        <div className="dfld">
                                                            <p><span>Fare / KM:</span>{bookingsDetails.per_km_charge} INR</p>
                                                        </div>
                                                    )}

                                                    { typeof bookingsDetails.estimated_kms !=='undefined' && bookingsDetails.estimated_kms && (
                                                    <div className="dfld">
                                                    <p><span>Estimated KM:</span>{bookingsDetails.estimated_kms} KM</p>
                                                    </div>
                                                    )}

                                                    { typeof bookingsDetails.fixed_hours !=='undefined' && bookingsDetails.fixed_hours && (
                                                    <div className="dfld">
                                                    <p><span>Fixed Hours:</span>{bookingsDetails.fixed_hours} Hours</p>
                                                    </div>
                                                    )}

                                                    { typeof bookingsDetails.total_trip_amount !=='undefined' && bookingsDetails.total_trip_amount && (
                                                    <div className="dfld">
                                                    <p><span>Est. Amount:</span>{bookingsDetails.total_trip_amount} INR</p>
                                                    </div>
                                                    )}

                                                    { typeof bookingsDetails.gst_amount !=='undefined' && bookingsDetails.gst_amount && (
                                                    <div className="dfld">
                                                    <p><span>GST Amount:</span>{bookingsDetails.gst_amount} INR</p>
                                                    </div>
                                                    )}

                                                    { typeof bookingsDetails.discount_amount !=='undefined' && bookingsDetails.discount_amount > 0 && (
                                                    <div className="dfld">
                                                    <p><span>Discount Amount:</span>{bookingsDetails.discount_amount} INR</p>
                                                    </div>
                                                    )}

                                                    { typeof bookingsDetails.final_trip_amount !=='undefined' && bookingsDetails.final_trip_amount && (
                                                    <div className="dfld">
                                                    <p><span>Payable Amount:</span>{bookingsDetails.final_trip_amount} INR</p>
                                                    </div>
                                                    )} 

                                                    </div>
                                                <div className="dtlsfields">
                                                        { typeof bookingsDetails.vehicle_model !=='undefined' && bookingsDetails.vehicle_model && (
                                                        <div className="dfld">
                                                        <p><span>Vehicle:</span>{bookingsDetails.vehicle_model}</p>
                                                        </div>
                                                        )}

                                                        { typeof bookingsDetails.vehicle_no !=='undefined' && bookingsDetails.vehicle_no && (
                                                        <div className="dfld">
                                                        <p><span>Vehicle no.:</span>{bookingsDetails.vehicle_no}</p>
                                                        </div>
                                                        )}
                                                         

                                                        { typeof bookingsDetails.driver_name !=='undefined' && bookingsDetails.driver_name && (
                                                        <div className="dfld">
                                                        <p><span>Driver name:</span>{bookingsDetails.driver_name}</p>
                                                        </div>
                                                        )}

                                                        { typeof bookingsDetails.driver_mobile !=='undefined' && bookingsDetails.driver_mobile && (
                                                        <div className="dfld">
                                                        <p><span>Driver Mobile:</span>{bookingsDetails.driver_mobile}</p>
                                                        </div>
                                                        )}

                                                    </div>
                                                    <div className="text-center">
                                                    <button className="dwnldbtn">Download</button>
                                                    </div>
                                                    
                                                </div> 
                                            </div>
                                        )
                                        }
                                    </div>
                                </Tab.Pane>


                                <Tab.Pane eventKey="third">
                                    <div className="tbcntwrpr">
                                        {walletShow && (
                                            <div className="walltwrpr">
                                                <h3>My Wallet</h3>
                                                <div className="wltbox">
                                                    <h2>Balance: ₹ { (typeof profileData.wallet_balance !=='undefined' ? profileData.wallet_balance : 0) } </h2>
                                                    <a href="javascript:void(0)" onClick={transactionClick}>View Transaction history</a>
                                                </div>
                                                <div className="addamnt">
                                                    <h5>Add Amount</h5>
                                                    <input type="text" className="boxshinnr" placeholder="Enter Amount" onChange={ (event) => { setSelectedRechargeAmount( event.target.value) }} value={selectedRechargeAmount} onInput={(event) => { event.target.value = event.target.value.replace(/[^0-9]/g, '').slice(0, 5); }}  ></input>
                                                </div>
                                                <div className="selectcamnt">
                                                    <h6>Or Select Amount</h6>
                                                    <div className="amntbtns">
                                                        { rechargePlanList && rechargePlanList.length > 0 &&  rechargePlanList.map((item)=>(
                                                            <button className="amntbtn" onClick={()=> setSelectedRechargeAmount( item.plan_amount )}>₹ {item.plan_amount.toFixed(2) }</button>
                                                        ))} 
                                                    </div>
                                                </div>
                                                <div className="paynw">
                                                    <button className="paybtn" onClick={handlePayment}> {payNowButton} </button>
                                                </div>
                                            </div>
                                        )
                                        }

                                        {walletTransactionShow && (
                                            <>
                                            <div className="transactwrpr">
                                                <div className="backbtnid">
                                                    <button onClick={walletBack}> <img src={aleft} /> </button>
                                                    <span>Transaction History</span>
                                                </div>
                                                
                                                <WalletHistory />
                                            </div>
                                            </>
                                        )
                                        }
                                    </div>
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </div>
        </>
    )
}

export default MyProfile;