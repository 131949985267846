import React, { useState } from "react";
import StarIcon from '@material-ui/icons/Star';

const RatingDetails = (props) => { 
   
    const [ ratingViewData, setRatingViewData ] = useState( props.ratingData );     

    return (
        <>
            <h3 className="site_sub_hdng">Rating </h3>
            <div className="cab_dt_box">
                <div className="ratebox">
                    <h4>{ratingViewData.avgrating}/{ratingViewData.outofrating} <StarIcon/> </h4>
                    <p>{ratingViewData.totalratings} Rating & {ratingViewData.totalreviews} Review</p>
                </div>
            </div>
        </>
    );
};

export default RatingDetails;