import React from "react";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import onewayimg from "../src/images/oneway.png";


const options = {
    margin: 20,
    responsiveClass: true,
    nav: false,
    dots: true,
    autoplay: false,
    smartSpeed: 1000,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        600: {
            items: 2,
        },
        700: {
            items: 3,
        },
        1000: {
            items: 3,

        }
    },
};
const FleetSlider = () => {
    return (
        <>
            <div className="row">
                <OwlCarousel className="slider-fleet-items owl-carousel owl-theme" {...options}>
                    <div className="item">
                        <div className="onewaybox">
                            <h3>Cabs from Delhi</h3>
                            <div className="row">
                                <div className="col-sm-6">
                                    <img src={onewayimg} alt=""/>
                                </div>
                                <div className="col-sm-6">
                                    <ul>
                                        <li>To Chandigarh</li>
                                        <li>To Jaipur</li>
                                        <li>To Agra</li>
                                        <li>To Dehradun</li>
                                        <li>To Haridwar</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="onewaybox">
                            <h3>Cabs from Delhi</h3>
                            <div className="row">
                                <div className="col-sm-6">
                                    <img src={onewayimg}  alt=""/>
                                </div>
                                <div className="col-sm-6">
                                    <ul>
                                        <li>To Chandigarh</li>
                                        <li>To Jaipur</li>
                                        <li>To Agra</li>
                                        <li>To Dehradun</li>
                                        <li>To Haridwar</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="onewaybox">
                            <h3>Cabs from Delhi</h3>
                            <div className="row">
                                <div className="col-sm-6">
                                    <img src={onewayimg} alt=""/>
                                </div>
                                <div className="col-sm-6">
                                    <ul>
                                        <li>To Chandigarh</li>
                                        <li>To Jaipur</li>
                                        <li>To Agra</li>
                                        <li>To Dehradun</li>
                                        <li>To Haridwar</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="onewaybox">
                            <h3>Cabs from Delhi</h3>
                            <div className="row">
                                <div className="col-sm-6">
                                    <img src={onewayimg} alt="" />
                                </div>
                                <div className="col-sm-6">
                                    <ul>
                                        <li>To Chandigarh</li>
                                        <li>To Jaipur</li>
                                        <li>To Agra</li>
                                        <li>To Dehradun</li>
                                        <li>To Haridwar</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </OwlCarousel>
            </div>
        </>
    );
};
export default FleetSlider;