import React, { useEffect, useState } from "react";
import axios from "axios";
import { Helmet } from 'react-helmet'; 
import config from "../config/config";
import apiHeaderToken from "../config/api_header";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import LocalPhoneIcon from '@material-ui/icons/LocalPhone';
import EmailIcon from '@material-ui/icons/Email';
import Button from 'react-bootstrap/Button';
//import aboutimage from "../../src/images/about.png";
import Footer from "../partials/Footer";

const Contact = () => {
        const [ contData, setContData ]  = useState( [] );

        const fetchCompanyDetails = ()=>{
            axios.get( `${config.API_URL}customer/support` , apiHeaderToken( config.API_TOKEN ) )
            .then( ( resp )=>{
                return  resp.data;
            })
            .then( ( responseData )=>{
                if( responseData.status ){ 
                    setContData( [responseData.data] )
                }
            }) 
        }

        useEffect(() => {
            fetchCompanyDetails(); 
          }, [])

    return(
        <>
          <section className="contactbg">
                <div className="container">
                    <div className="row j-align-center">
                        <div className="col-sm-12">
                            <h2 className="site_hdng">Contact Details</h2>

                                <div className="conttext">
                              
                                {contData.length > 0 && ( 
                                    contData.map( (elm) => (  
                                        <>
                                        <Helmet>
                                        <title>Look Book Cabs</title>
                                        <meta name="description" content="Look Book Cabs" />
                                        <meta name="keywords" content="Look Book Cabs" />
                                        </Helmet>

                                            <div className="contact_dtl">
                                            <LocationOnOutlinedIcon />
                                            <p>
                                            <strong>Address:</strong> 
                                            <span>{elm.address}</span>
                                            </p>
                                            </div>
                                            <div className="contact_dtl">
                                            <LocalPhoneIcon />
                                            <p>
                                            <strong>Phone:</strong> 
                                            <a href="tel: +91{elm.care_number}"> +91 {elm.care_number}</a>
                                            </p>
                                            </div>
                                            <div className="contact_dtl">
                                            <EmailIcon />
                                            <p>
                                            <strong>Email:</strong> 
                                            <a href="mailto:{elm.care_email}"> {elm.care_email}</a>
                                            </p>
                                            </div>
                                            </>   
                                            ))
                                        )}
                                </div>
                            </div>
                    </div>
                    <div className="row j-align-center">
                            <div className="col-sm-6 left_img">
                                {contData.length > 0 && ( 
                                    contData.map( (elments) => (  
                                        <>
                                        <iframe src={elments.map_script} width="600" height="550" style={{'border':'0','border-radius':'10px'}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                                        </>   
                                    ))
                                )}
                            </div>
                            <div className="col-sm-6 m-auto">
                               <h2 className="site_hdng">Get in Touch</h2>
                                <div className="c_formwrppr">
                                    <FloatingLabel controlId="floatingName" label="Your Name" className="mb-3">
                                        <Form.Control type="text" placeholder="Your Name" />
                                    </FloatingLabel>
                                    <FloatingLabel controlId="floatingInput" label="Email address" className="mb-3" >
                                        <Form.Control type="email" placeholder="name@example.com" />
                                    </FloatingLabel>
                                    <FloatingLabel controlId="floatingInput" label="Phone" className="mb-3" >
                                        <Form.Control type="text" placeholder="+91 999999999" />
                                    </FloatingLabel>
                                    <FloatingLabel controlId="floatingInput" label="Subject" className="mb-3" >
                                        <Form.Control type="text" placeholder="Subject" />
                                    </FloatingLabel>
                                    <FloatingLabel controlId="floatingTextarea2" label="Message" className="mb-3">
                                        <Form.Control  as="textarea"  placeholder="Message" style={{ height: '100px' }}/>
                                    </FloatingLabel>
                                    <Button variant="primary" type="submit">
                                        Send Message
                                    </Button>
                                </div>
                            </div>
                    </div>
                </div>
          </section>
          <section className="footer">
                <Footer />
          </section>
    </>
  );
}

export default Contact;


