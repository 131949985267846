import React from "react";
import ArrowRightAltOutlined from '@material-ui/icons/ArrowRightAltOutlined';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';


const TopPackageBar = () => {

    return (
        <>
            <section className="sect_padding">
                <div className="container">
                    <div className="row top_filterrow pkg_topfiltr">
                        <form>
                            <div className="col-sm-1">
                                <label>Destination</label>
                            </div>
                            <div className="col-sm-4 form-element">
                                <input type="text" name="destination" className="form-control box_sh_inner"
                                    placeholder="Pickup From" />
                                <LocationOnOutlinedIcon />
                            </div>
                            <div className="col-sm-1"></div>
                            <div className="col-sm-2 text-center">
                                <label>Choose Vehicle Type</label>
                            </div>
                            <div className="col-sm-3 form-element">
                                <select name="package" id="" required="" className="form-select">
                                    <option value="">Prime</option>
                                    <option value="">Select Vehicle</option>
                                </select>
                            </div>
                            <div className="col-sm-1 form-element">
                                <input type="submit" className="search_cb" value="Search" />
                            </div>
                        </form>
                    </div>
                </div>
            </section>
            <section className="sect_padding route_info">
                <div className="container">
                    <div className="row text-center route_info">
                        <h2 className="sitepage_hdng">Lucknow <span> <ArrowRightAltOutlined /> </span> Delhi</h2>
                        <p className="rt_dtl">Oneway, 12-Aug, 12:00 PM</p>
                    </div>
                </div>
            </section>

        </>
    );
};

export default TopPackageBar;