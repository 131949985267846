import React from "react";
import AboutSection from "./AboutSection";
import Footer from "../partials/Footer";

const About = () => {
    return (
        <>
            <AboutSection />
            <section className="footer">
                <Footer />
          </section>
        </>
    );
};

export default About;