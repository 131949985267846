let config = {}
// config['BASE_URL'] = 'http://localhost:3000';
// config['API_URL'] = 'http://localhost:3001/api/';
// config['API_TOKEN'] = 'Bearer 744b365cde7bd714a928d5a04167a117'; 

config['BASE_URL'] = 'http://lookbook.cab/';
config['API_URL'] = 'http://lookbookcabs.com/api/';
config['API_TOKEN'] = 'Bearer 744b365cde7bd714a928d5a04167a117'; 

config['COMPANY_NAME'] = 'Lookbook Cabs'; 
config['RAZORPAY_KEY_ID'] = 'rzp_test_nvsKQrmWNp3a5q'; 

export default config;