import React, {useEffect, useState } from "react";
import AppDownload from "../AppDownload";
import Footer from "../partials/Footer";
import { Helmet } from "react-helmet";
import config from "../config/config";
import axios from "axios";
import apiHeaderToken from "../config/api_header";

const TermsCondition = () => {

  const [ trmData, setTermData ] = useState([]);

  const getTermsDataFromApi = ()=>{
      axios.post(`${config.API_URL}customer/common/cms/list`,{'page_type':'terms'}, apiHeaderToken( config.API_TOKEN )  )
      .then( ( respData )=>{
          return respData.data;
      })
      .then( (responseData)=>{
          if( responseData.status ){
            setTermData( responseData.data );
          }
      })
  }

  useEffect( ()=>{
    getTermsDataFromApi();
}, [] );  

  return (
    <>
      <section className="container">
        <div className="row"> 
          <div class="col-md-12">
                {trmData.length > 0 && ( 
                    trmData.map( (elmVal) => (  
                        <>
                        <h2 className="site_hdng">{elmVal.h_one_tag}</h2>
                        <Helmet>
                        <title>{elmVal.meta_title}</title>
                        <meta name="description" content={elmVal.meta_description} ></meta>
                        <meta name="keywords" content={elmVal.meta_keyword} ></meta>
                        </Helmet>  
                        <div dangerouslySetInnerHTML={{ __html: elmVal.content_data}} />
                        </>
                    ))
                )}
          </div>
        </div>
      </section>

      <section className="add_download sect_padding">
        <AppDownload />
      </section>
      <section className="footer">
        <Footer />
      </section>
    </>
  );
};

export default TermsCondition;
