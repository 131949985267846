import React from "react";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import pic1 from "../src/images/pack1.png";
import pic2 from "../src/images/pack2.png";

const options = {
    margin: 20,
    responsiveClass: true,
    nav: false,
    dots: true,
    autoplay: false,
    smartSpeed: 1000,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        600: {
            items: 2,
        },
        700: {
            items: 3,
        },
        1000: {
            items: 4,

        }
    },
};

const PackageListSlider = () => { 
    return (
        <>
            <div className="row">
                <OwlCarousel className="round-slider-items owl-carousel owl-theme" {...options}>
                    <div className="item">
                        <div className="packgboxlist">
                            <div className="pkgimg">
                                <img src={pic1}  alt={pic1} />
                            </div>
                            <div className="pkgcont">
                                <h4>Local Manali Sightseeing</h4>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard </p>
                                <p className="cbtype">Cab Type : Sedan Car</p>
                                <p className="cbprice">Starting @ ₹2500</p>
                                <a href="package_details" className="bookbtn">  Know More</a>
                            </div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="packgboxlist">
                            <div className="pkgimg">
                                <img src={pic2} alt={pic2} />
                            </div>
                            <div className="pkgcont">
                                <h4>Local Manali Sightseeing</h4>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard </p>
                                <p className="cbtype">Cab Type : Sedan Car</p>
                                <p className="cbprice">Starting @ ₹2500</p>
                                <a href="package_details" className="bookbtn">  Know More</a>
                            </div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="packgboxlist">
                            <div className="pkgimg">
                                <img src={pic1} alt={pic1} />
                            </div>
                            <div className="pkgcont">
                                <h4>Local Manali Sightseeing</h4>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard </p>
                                <p className="cbtype">Cab Type : Sedan Car</p>
                                <p className="cbprice">Starting @ ₹2500</p>
                                <a href="package_details" className="bookbtn">  Know More</a>
                            </div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="packgboxlist">
                            <div className="pkgimg">
                                <img src={pic2}  alt={pic2} />
                            </div>
                            <div className="pkgcont">
                                <h4>Local Manali Sightseeing</h4>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard </p>
                                <p className="cbtype">Cab Type : Sedan Car</p>
                                <p className="cbprice">Starting @ ₹2500</p>
                                <a href="package_details" className="bookbtn">  Know More</a>
                            </div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="packgboxlist">
                            <div className="pkgimg">
                                <img src={pic1} alt={pic1} />
                            </div>
                            <div className="pkgcont">
                                <h4>Local Manali Sightseeing</h4>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard </p>
                                <p className="cbtype">Cab Type : Sedan Car</p>
                                <p className="cbprice">Starting @ ₹2500</p>
                                <a href="package_details" className="bookbtn">  Know More</a>
                            </div>
                        </div>
                    </div>
                </OwlCarousel>
            </div>
        </>
    )
}

export default PackageListSlider