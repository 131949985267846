const moment    = require("moment-timezone");

  export function getCurrentTime(){
    const now = new Date();
    let hours = now.getHours();
    let minutes = now.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
  
    // Convert hours to 12-hour format
    hours = hours % 12 || 12;
  
    // Add leading zero to minutes if needed
    minutes = minutes < 10 ? `0${minutes}` : minutes;
  
    const formattedTime = `${hours}:${minutes} ${ampm}`;
    return formattedTime;
  }

  export const ucWords = (str) => {
    if( typeof str !== 'undefined' || str !== '' ){ 
          var str = str.trim();
          var splitStr = str.toLowerCase().split(' ');
            for (var i = 0; i < splitStr.length; i++) { 
                splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
            } 
          return splitStr.join(' '); 
    }
  }


  export const allDateFormat  =  ( str, formatStr ) => {  
    var mndate = moment( str ).tz('Asia/Kolkata').format( formatStr );  
    return mndate ;
  }
  

  export const handleNullValue = ( str )=>{ 
    let output = ''; 
    if( typeof str !== 'undefined' && str !== '' ){ 
      output = str;
    }
    return output; 
  }


  export const getWordsBeforeComma = ( str )=>{
      const wordsArray = str.split(',');
      const firstWord = str.length > 0 ? wordsArray[0].trim() : '';
      return firstWord.replaceAll(' ,', '');
  } 
 

  export const shortStringByLength = (inputString, maxLength, isDotted = false)=> { 
    const threeDots = inputString.length > maxLength && isDotted ? '..' : '';
    const desiredSubstring =  inputString.length ? inputString.substring(0, maxLength ).trim() + threeDots : ''; 
    return desiredSubstring;
  }


  export const getProfileLoginDataFields = ( loginData, handleNullValue )=> { 
        const storeLoginData = {}
        storeLoginData.user_id = handleNullValue( loginData._id );
        storeLoginData.user_name = handleNullValue( loginData.full_name );
        storeLoginData.user_email = handleNullValue( loginData.email_id );
        storeLoginData.gender = handleNullValue( loginData.gender );
        storeLoginData.kyc_status = handleNullValue( loginData.kyc_status );
        storeLoginData.user_mobile = handleNullValue( loginData.mobile_no );
        storeLoginData.unique_id = handleNullValue( loginData.unique_id );
        storeLoginData.wallet_balance = handleNullValue( loginData.wallet_balance );
        storeLoginData.referral_code = handleNullValue( loginData.referral_code );
        storeLoginData.referral_amount = handleNullValue( loginData.referral_amount );
        storeLoginData.referral_used = handleNullValue( loginData.referral_used );
        storeLoginData.city_name = handleNullValue( loginData.city_name );
        storeLoginData.state_name = handleNullValue( loginData.state_name );
        storeLoginData.city_id = handleNullValue( loginData.city_id ); 
        storeLoginData.state_id = handleNullValue( loginData.state_id ); 
        storeLoginData.address = handleNullValue( loginData.address ); 
        storeLoginData.pin_code = handleNullValue( loginData.pin_code ); 
        storeLoginData.profile_image = handleNullValue( loginData.profile_image );  

        storeLoginData.comp_address = handleNullValue( loginData.comp_address ); 
        storeLoginData.comp_city_name = handleNullValue( loginData.comp_city_name ); 
        storeLoginData.comp_gstin = handleNullValue( loginData.comp_gstin ); 
        storeLoginData.comp_name = handleNullValue( loginData.comp_name ); 
        storeLoginData.comp_pan = handleNullValue( loginData.comp_pan ); 
        storeLoginData.comp_pin_code = handleNullValue( loginData.comp_pin_code ); 
        storeLoginData.comp_state_name = handleNullValue( loginData.comp_state_name ); 

        return storeLoginData;
  }