import React from "react";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import CabSearchEngine from "./cabsearchengine/CabSearchEngine";
import About from "./aboutus/About";
import Attach from "./Attach";
import Contact from "./contact/Contact";  
import Navbar from "./partials/Navbar";
import CabList from "./CabList";
import CabDetails from "./CabDetails";
import PriceSummary from "./PriceSummary";
import TaxiPackage from "./TaxiPackage";
import Faq from "./Faq";
import BlogList from "./BlogList";
import PackageDetails from "./PackageDetails";
import Seo from "./SeoPage";
import PrivacyPolicy from "./privacy/PrivacyPolicy";
import Terms from "./terms/TermsCondition";
import MyProfile from "./profile/MyProfile";

// import ScriptTag from 'react-script-tag';



import { Route, Routes } from "react-router-dom";

const App = () => {
    return(
        <>        
        <Navbar />

        <Routes>
          <Route exact path='/' element={<CabSearchEngine/>} />
          <Route exact path='/about' element={<About/>} />
          <Route exact path='/attach' element={<Attach/>} />
          <Route exact path='/contact' element={<Contact/>} />
          <Route exact path='/cab_list' element={<CabList/>} />
          <Route exact path='/cab_details' element={<CabDetails/>} />
          <Route exact path='/price_summary' element={<PriceSummary/>} />
          <Route exact path='/taxi_package' element={<TaxiPackage/>} />
          <Route exact path='/faq' element={<Faq/>} />
          <Route exact path='/blog_list' element={<BlogList/>} />
          <Route exact path='/package_details' element={<PackageDetails/>} />
          <Route exact path='/seo_page' element={<Seo/>} />
          <Route exact path='/privacy_policy' element={<PrivacyPolicy/>} />
          <Route exact path='/terms_condition' element={<Terms/>} />
          <Route exact path='/my_profile' element={<MyProfile/>} />


        </Routes>
        </>
    );
};

export default App;