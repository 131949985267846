import React from "react";
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import DateRange from '@material-ui/icons/DateRange';
import AccessTime from '@material-ui/icons/AccessTime';
const Topfilter = () => {

    let getSearchData = JSON.parse( localStorage.getItem('search_tab') );
    console.log( getSearchData );

    
    return (
        <>
            <section className="sect_padding">
                <div className="container">
                    <div className="row top_filterrow">
                         <form>
                            <div className="col-sm-1 form-element">
                                <select name="package" id="" required="" className="form-select">
                                    <option value="">One Way</option>
                                    <option value="" selected>Round Trip</option>
                                </select>
                            </div>
                            <div className="col-sm-1 list_locbtn form-element">
                                <input type="text" name="destination" className="form-control box_sh_inner"
                                    placeholder="Lucknow" />
                                <LocationOnOutlinedIcon />
                            </div>
                            <div className="col-sm-1 list_locbtn form-element">
                                <input type="text" name="destination" className="form-control box_sh_inner"
                                    placeholder="Delhi" />
                                <LocationOnOutlinedIcon />
                            </div>
                            <div className="col-sm-2 form-element">
                                <select name="package" id="" required="" className="form-select">
                                    <option value="">Select Package</option>
                                    <option value="">Select Vehicle</option>
                                </select>
                            </div>
                            <div className="col-sm-2 form-element">
                                <input className="form-control" id="datepicker" type="text"
                                    placeholder="Choose Date" data-dtp="dtp_UqSih" />
                                <DateRange />
                            </div>
                            <div className="col-sm-2 form-element">
                                <input className="form-control timepicker br-none" id="" type="text"
                                    placeholder="Choose Time" data-dtp="dtp_9bkxX" />
                                <AccessTime />

                            </div>
                            <div className="col-sm-1 form-element">
                                <select name="package" id="" required="" className="form-select">
                                    <option value="">Prime</option>
                                    <option value="">Select Vehicle</option>
                                </select>
                            </div>
                            <div className="col-sm-1 form-element">
                                <input type="submit" className="search_cb" value="Search" />
                            </div>
                         </form>
                    </div>
                    {/* <div className="d-md-none ">
                        <button>Trip Filter</button>
                    </div> */}
                </div>
            </section>
        </>
    );
};

export default Topfilter;