import React, { useState, useEffect } from "react";
import axios                from "axios";
import config               from './config/config';
import apiHeaderToken       from './config/api_header';  
import { useNavigate } from "react-router-dom";

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
 
import Value_sec from "./ValueSection"; 

import CabSafetyPolicy from "./CabSafetyPolicy";

import RatingDetails from "./RatingDetails";
import Footer from "./partials/Footer";
 
import cbi1 from "../src/images/cbi1.png";
import cbi2 from "../src/images/cbi2.png";
import cbi3 from "../src/images/cbi3.png";
import cbi4 from "../src/images/cbi4.png"; 
import cbi5 from "../src/images/cbi5.png";

 
import cbd1 from "../src/images/cbd1.png";
import cbd2 from "../src/images/cbd2.png";
import cbd3 from "../src/images/cbd3.png";
import cbd4 from "../src/images/cbd4.png";
import StarIcon from '@material-ui/icons/Star';


import ReviewTextSlider from "./ReviewTextSlider";

import { ucWords, allDateFormat, handleNullValue, getWordsBeforeComma  }  from './helper/my_function_helper';


const options = {
    margin: 20,
    responsiveClass: true,
    nav: false,
    items: 1.4,
    loop: true,
    dots: false,
    autoplay: false,
    smartSpeed: 2000
};


const CabDetails = () => {  

    const navigate = new useNavigate();


    const [ travelTextRoute, setTravelTextRoute ] = useState(''); 
    const [ reviewData, setReviewData ] = useState([]);
    const [ ratingData, setRatingData ] = useState({});
    const [ cabFuelType, setCabFuelType ] = useState('Petrol');
    

    const getSelectedCabItem = JSON.parse( localStorage.getItem('selectedCab') ) || {};
    const [cabItem, setCabItem] = useState( getSelectedCabItem ); 


useEffect(() => {
        setTimeout( ()=>{  
            setTravelTextRoute( searchTextRoot );
            if( cabItem.cab_id ){ getReviewList( cabItem.cab_id ); } 
            if( cabItem.features_list ){  handleFuelType( cabItem.features_list ); } 
        }, 500 ) ;
}, []);




    /********set searched Items ************/
    let getSearchData = JSON.parse( localStorage.getItem('search_tab') );
 
    if( getSearchData === null ){
        window.location.href='/'; return;
    }


    let tripType = handleNullValue( getSearchData.trip_type );
    let fromCityId = handleNullValue( getSearchData.from_city_id ) ;
    let fromCityName = handleNullValue( getSearchData.from_city ) ;
    let packageName = handleNullValue( getSearchData.package ) ;
    let pickupDate = handleNullValue( getSearchData.pickup_date ) ;
    let pickupTime = handleNullValue( getSearchData.pickup_time ) ;
    let dropDate = handleNullValue( getSearchData.drop_date ) ;

    let toCityId = handleNullValue( getSearchData.to_city_id ) ;
    let toCityName = handleNullValue( getSearchData.to_city ) ;

    let vehicleName = handleNullValue( getSearchData.vehicle_name ) ; 
    let vehicleId = handleNullValue( getSearchData.vehicle_id ) ; 
    let travel_routes = getSearchData.travel_routes ; 
   

    let searchTravelRoot = ''; var searchTextRoot = '';
    if( ['airport','oneway'].includes( tripType ) ){
        searchTravelRoot = fromCityName+'|'+toCityName; 
        var searchTextRoot = getWordsBeforeComma( fromCityName )+'→'+getWordsBeforeComma( toCityName ); 
         
    }
    else if( ['local'].includes( tripType ) ){
        searchTravelRoot = fromCityName+'|'+packageName;
        var searchTextRoot = getWordsBeforeComma( fromCityName )+'→'+packageName; 
        
    }
    else if( ['outstation'].includes( tripType ) ){
        searchTravelRoot = fromCityName+travel_routes.map( (elm)=>{
            return '|'+elm.city;
        }); 
       var searchTextRoot = getWordsBeforeComma( fromCityName )+'→'+ travel_routes.map( (elm)=>{
            return getWordsBeforeComma( elm.city )+'→';
        })+getWordsBeforeComma( fromCityName );  

    }

    function changeCatItemData ( index, value ){
        const newData = {...cabItem};
        newData[index] = value; 
        setCabItem( newData );
        localStorage.removeItem('selectedCab'); 
        localStorage.setItem('selectedCab', JSON.stringify( newData ) );  
    }
 
    /*********get Review/Rating List *************/
    const getReviewList = (cab_id) => {
        axios
          .post(
            `${config.API_URL}customer/booking/cab_rating_details`,
            { cab_id: cab_id, page_no: '1', per_page_records: '50', is_test: 'yes' },
            apiHeaderToken(config.API_TOKEN)
          )
          .then((response) => {
                if (response.data.status) {
                // Assuming responseData.data is an array of reviews 
                let rtData = {}
                rtData.avgrating = response.data.data.avgrating;
                rtData.outofrating = response.data.data.outofrating;
                rtData.ratinglist = response.data.data.ratinglist;
                rtData.totalratings = response.data.data.totalratings;
                rtData.totalreviews = response.data.data.totalreviews;

                changeCatItemData('avg_rating', response.data.data.avgrating );

                setRatingData( rtData );
                setReviewData( response.data.data.list );
                } else {
                // Handle the case when the API returns an error status
                setReviewData([]);
                setRatingData([]);
                }
          })
          .catch((error) => { 
                setReviewData([]);
                setRatingData([]);
          });
      }

    /******** Handle Fuel Type ***********/  
    const handleFuelType = (list)=>{
        const newArray = list.find( (o)=>o.text === 'Fuel Type');
        if( newArray ){
            setCabFuelType( newArray.value );
        }    
    }
 


  const textAlignLeft = {
    textAlign: 'left',
    marginBottom: '6px'
  }
  const spanStyle = { 
    fontWeight: '600'
  }
 
  /********  Handle next age navigation*/
  function handleNextPageClick(){
    navigate('/price_summary');
  }

    return (
        <>
            <section className="sect_padding route_info">
                <div className="container">
                    <div className="row text-center route_info">
                        <h2 className="sitepage_hdng">{travelTextRoute}</h2>
                        <p className="rt_dtl">{ ucWords(tripType) }, {allDateFormat(pickupDate,'DD-MMM')}, {pickupTime}</p>
                    </div>
                </div>
            </section>

            {cabItem.cab_image_list && (
            <section className="detailspage"> 
                <div className="container">
                    <div className="row">
                        <OwlCarousel className="details-fleet-items owl-carousel owl-theme" {...options}>
                        {cabItem.cab_image_list.length > 0 && cabItem.cab_image_list.map((item) => (
                                    item ? (
                                        <div className="item" key={item}>
                                        <img src={item} alt={item} />
                                        </div>
                                    ) : null
                            ))} 
                        </OwlCarousel>
                    </div>
                </div>
            </section>
            )}

            <section className="container">
                <div className="row">
                    <div className="col-sm-6">
                            <h3 className="site_sub_hdng">Cab Details </h3>
                            <div className="cab_dt_box">
                                <div className="d-flex justify-content-between">
                                    <div className="cb_txt">
                                        <h4 className="sub_hdng">{cabItem.model_name}</h4>
                                        <ul className="cb_feat d-flex">
                                            <li>{cabItem.category_name}</li>
                                            <li>4 Seater</li>
                                            <li>AC</li>
                                        </ul>
                                        <p>Vehicle No. : {cabItem.vehicleno}</p>
                                    </div>
                                    <div className="cb_prc">
                                        <p className="rate_avg"><StarIcon />{cabItem.avg_rating}</p>
                                        <span>Estimated Fare</span>
                                        <h4>₹{cabItem.fare_details.total_trip_amount_with_gst}</h4>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between cb_feat_listed">
                                    <div className="bookby">
                                        <p>Booked : {cabItem.total_bookings !== '' ? cabItem.total_bookings : 0 }</p>
                                    </div>
                                    <div className="cb_icons">
                                        <img src={cbi1}  alt= {cbi1} />
                                        <img src={cbi2} alt= {cbi2} />
                                        <img src={cbi4} alt= {cbi4} />
                                        <img src={cbi5} alt= {cbi5} />
                                        <img src={cbi3} alt= {cbi3} />
                                    </div>
                                </div>
                                <div className="d-flex safty">
                                    <img src={cbd4} />
                                    <p>100% of drivers are police verified, licensed & vaccinated (Covid -19)</p>
                                </div>
                                <div className="about_cab">
                                    <h4 className="sub_hdng">Spacious Car with Full Safety Promise</h4>
                                    <ul className="blue_list">
                                        <li>{cabItem.fare_details.fixed_kms} kms included</li>
                                        <li>₹ {cabItem.fare_details.per_km_charge}/Km after {cabItem.fare_details.fixed_kms}km</li>
                                        {cabItem.trip_type ==='local' ? (
                                            <li>₹ {cabItem.fare_details.per_min_charge}/Min after {cabItem.fare_details.fixed_hours}Hours</li>
                                        ) : null }
                                        <li>{cabItem.luggage} Kg Luggage Bags</li>
                                    </ul>
                                    <ul className="grey_list">
                                        <li>
                                            <img src={cbd1} alt={cbd1} />
                                            <p>{cabFuelType} with refill breaks</p>
                                        </li>
                                        <li>
                                            <img src={cbd2} alt={cbd2} />
                                            <p>Free Cancellation, till 6 hours of departure</p>
                                        </li>
                                        <li>
                                            <img src={cbd3} alt={cbd3} />
                                            <p>Double Guarantee Confirmed cab arrival or 2X refund</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                    </div>
                    <div className="col-sm-6">
                        <CabSafetyPolicy safetyData={cabItem.safety_policy_list} />

                        <h3 className="site_sub_hdng">Terms & Conditions </h3>
                        <div className="cab_dt_box">
                            <ul className="grey_list list_disc">
                            { cabItem.features_list && cabItem.terms_list.map( (item)=>( 
                                    <li><p>{item.text}</p></li>
                                ))}
                            </ul>
                        </div> 

                    </div>
                </div>
            </section>


            <section className="container">
                <div className="row">
                    <div className="col-sm-6">
                        <h3 className="site_sub_hdng">Inclusions </h3>
                        <div className="cab_dt_box">
                                { cabItem.features_list && cabItem.features_list.map( (item)=>( 
                                <div class="row">
                                <div class="col-lg-6">
                                <p style={textAlignLeft}>{item.text}</p>
                                </div>
                                <div class="col-lg-6" style={spanStyle}>{item.value}</div>
                                </div>
                                ))} 
                        </div> 
                    </div>
                    <div className="col-sm-6">
                      { ratingData.ratinglist && (<RatingDetails ratingData={ratingData} />)}
                    </div>
                </div>
            </section>


            <section className="container">
                <div className="row">
                   {reviewData.length > 0 && (<ReviewTextSlider reviewList={reviewData} />)}
                </div>
            </section>
            <section className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="farebox d-flex justify-content-between">
                            <div className="cab_dt_box frtxt">
                                <span>Estimated Fare</span>
                                <h3>₹{cabItem.fare_details.total_trip_amount_with_gst}</h3>
                            </div>
                            <div className="frbtn"> 
                                <a href="javascript:void(0);" onClick={handleNextPageClick} className="bookbtn" >  Book Now</a> 
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="sect_padding">
                <Value_sec />
            </section>
            
            <section className="footer">
                <Footer />
            </section>




        </>
    );
};





export default CabDetails;

