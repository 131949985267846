import React, { useState, useEffect } from "react";
import axios                from "axios";
import config               from './config/config';
import apiHeaderToken       from './config/api_header';  
import { useNavigate } from "react-router-dom";
import { ucWords, allDateFormat, handleNullValue, getWordsBeforeComma  }  from './helper/my_function_helper';

 
import ValueSection from "./ValueSection";

import Footer from "./partials/Footer";
import TestimonialSection from "./TestimonialSection";
import TripSummary from "./TripSummary";



const PriceSummary = () => {

    const [ travelTextRoute, setTravelTextRoute ] = useState(''); 

    const getSelectedCabItem = JSON.parse( localStorage.getItem('selectedCab') ) || {};
    const [cabItem, setCabItem] = useState( getSelectedCabItem );


    useEffect(() => {
            setTimeout( ()=>{  
                setTravelTextRoute( searchTextRoot );  
            }, 500 ) ;
    }, []);


    //console.log( cabItem );

    /********set searched Items ************/
    let getSearchData = JSON.parse( localStorage.getItem('search_tab') ); 
 
    if( getSearchData === null ){
        window.location.href='/'; return;
    }



    let tripType = handleNullValue( getSearchData.trip_type );
    let fromCityId = handleNullValue( getSearchData.from_city_id ) ;
    let fromCityName = handleNullValue( getSearchData.from_city ) ;
    let packageName = handleNullValue( getSearchData.package ) ;
    let pickupDate = handleNullValue( getSearchData.pickup_date ) ;
    let pickupTime = handleNullValue( getSearchData.pickup_time ) ;
    let dropDate = handleNullValue( getSearchData.drop_date ) ;

    let toCityId = handleNullValue( getSearchData.to_city_id ) ;
    let toCityName = handleNullValue( getSearchData.to_city ) ;

    let vehicleName = handleNullValue( getSearchData.vehicle_name ) ; 
    let vehicleId = handleNullValue( getSearchData.vehicle_id ) ; 
    let travel_routes = getSearchData.travel_routes ; 
   

    let searchTravelRoot = ''; var searchTextRoot = '';
    if( ['airport','oneway'].includes( tripType ) ){
        searchTravelRoot = fromCityName+'|'+toCityName; 
        var searchTextRoot = getWordsBeforeComma( fromCityName )+'→'+getWordsBeforeComma( toCityName ); 
         
    }
    else if( ['local'].includes( tripType ) ){
        searchTravelRoot = fromCityName+'|'+packageName;
        var searchTextRoot = getWordsBeforeComma( fromCityName )+'→'+packageName; 
        
    }
    else if( ['outstation'].includes( tripType ) ){
        searchTravelRoot = fromCityName+travel_routes.map( (elm)=>{
            return '|'+elm.city;
        }); 
       var searchTextRoot = getWordsBeforeComma( fromCityName )+'→'+ travel_routes.map( (elm)=>{
            return getWordsBeforeComma( elm.city )+'→';
        })+getWordsBeforeComma( fromCityName );  

    }
    


    return (
        <>
            <section className="sect_padding route_info">
                <div className="container">
                    <div className="row text-center route_info">
                        <h2 className="sitepage_hdng">{travelTextRoute}</h2>
                        <p className="rt_dtl">{ ucWords(tripType) }, {allDateFormat(pickupDate,'DD-MMM')}, {pickupTime}</p>
                    </div>
                </div>
            </section>
            <section className="container sect_padding">
                <div className="row">
                    <TripSummary data={ cabItem } />
                </div>
            </section>
          
            <section className="sect_padding">
                <ValueSection />
            </section>
            <section className="sect_padding">
                <TestimonialSection />
            </section>
            <section className="footer">
                <Footer />
            </section> 
        </>
    )
}




export default PriceSummary;