import React from "react";
import ArrowRightAltOutlined from '@material-ui/icons/ArrowRightAltOutlined';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';


const TopPackageHdr = () => {

    return (
        <>
            <section className="sect_padding">
                <div className="container">
                    <div className="row top_filterrow pkg_topfiltr">
                        <form>
                            <div className="col-sm-1">
                                <label>Destination</label>
                            </div>
                            <div className="col-sm-4 form-element">
                                <input type="text" name="destination" className="form-control box_sh_inner"
                                    placeholder="Pickup From" />
                                <LocationOnOutlinedIcon />
                            </div>
                            <div className="col-sm-1"></div>
                            <div className="col-sm-2 text-center">
                                <label>Choose Vehicle Type</label>
                            </div>
                            <div className="col-sm-3 form-element">
                                <select name="package" id="" required="" className="form-select">
                                    <option value="">Prime</option>
                                    <option value="">Select Vehicle</option>
                                </select>
                            </div>
                            <div className="col-sm-1 form-element">
                                <input type="submit" className="search_cb" value="Search" />
                            </div>
                        </form>
                    </div>
                </div>
            </section>
            <section className="sect_padding route_info">
                <div className="container">
                    <div className="d-flex align-items-center justify-content-between">
                        <div class="norml_hdng">
                            <h2>Chandigarh Shimla Manali Tour</h2>
                            <div class="tourbrf d-flex align-items-center">
                                <span><i class="fa-regular fa-calendar"></i> Full day (500 km included)</span>
                                <span><i class="fa fa-location-dot"></i> Chandigarh</span>
                                <span><i class="fa-solid fa-car-side"></i> Etios, Dzire or equivalent</span>
                            </div>
                        </div>
                        <div class="pkright">
                            <h6 class="cutprice">₹ 13,961</h6>
                            <h3>₹ 12,461 <span>per person*</span></h3>
                            <p><i class="fa-solid fa-check"></i> Inclusive all taxes</p>
                            <span class="off">10% OFF</span>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
};

export default TopPackageHdr;