import React, {useEffect, useState } from "react";
import AppDownload from "../AppDownload";
import Footer from "../partials/Footer";
import axios from "axios";
import config from "../config/config"; 
import apiHeaderToken from "../config/api_header";
import { Helmet } from "react-helmet";


const Privacy = () => {

    const [ prvData , setPrvData ] = useState( [] );

    const getPrivacyData = ()=>{
        axios.post( `${config.API_URL}customer/common/cms/list`, {'page_type':'privacy'}, apiHeaderToken( config.API_TOKEN) )
        .then( ( resp )=>{
            return resp.data;
        })
        .then( (responseData)=>{ 
            if( responseData.status ){ 
                setPrvData( responseData.data )
            }
        })
    }

    useEffect( ()=>{
        getPrivacyData();
    }, [] );  

    return (
        <>
           <section className="container">
             <div className="row">
                
                {prvData.length > 0 && ( 
                    prvData.map( (elmVal) => (  
                        <>
                        <h2 className="site_hdng">{elmVal.h_one_tag}</h2>
                        <Helmet>
                        <title>{elmVal.meta_title}</title>
                        <meta name="description" content={elmVal.meta_description} ></meta>
                        <meta name="keywords" content={elmVal.meta_keyword} ></meta>
                        </Helmet>  
                        <div dangerouslySetInnerHTML={{ __html: elmVal.content_data}} />
                        </>
                    ))
                )}
             </div>
           </section>

            <section className="add_download sect_padding">
                <AppDownload />
            </section>
            <section className="footer">
                <Footer />
            </section>
        </>
    );
};

export default Privacy;