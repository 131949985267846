import axios from 'axios';
import React, { useEffect,useState } from 'react';


import config from '../config/config';
import apiHeaderToken from '../config/api_header';

import "react-modern-calendar-datepicker/lib/DatePicker.css";
import DatePicker from 'react-modern-calendar-datepicker';  
import DateRange from '@material-ui/icons/DateRange';

import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined'; 
import AccessTime from '@material-ui/icons/AccessTime';

import PropTypes from "prop-types";
import moment from "moment";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";

import AlertBox from "../modalbox/AlertBox";
import { getCurrentTime } from "../helper/my_function_helper"


const FilterLocalCabTab = () => {

  const getSearchData = JSON.parse(localStorage.getItem('search_tab')) || {};

    let currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(currentDate.getDate()).padStart(2, '0');
    const today = {'day':day,'month': month, 'year': year };  
    const formattedDate = `${year}-${month}-${day}`;

    const formattedTime = getCurrentTime();
 

  const tripType = 'local';
  const [formData, setFormData] = useState({
    from_city: "",
    from_city_id: "",
    from_state_id: "",
    from_state_name: "",
    trip_type: tripType,
    to_city: "",
    to_city_id: "",
    to_state_id: "",
    to_state_name: "",
    pickup_date: formattedDate,
    pickup_time: formattedTime,
    drop_date: "",
    package: '',
    package_id: '',
    vehicle_name: "",
    vehicle_id:  ""
});

  const handleAllInputChange = ( nameValueObj ) => { 
    for (let property in nameValueObj ) { 
      formData[property] = nameValueObj[property];
    } 
    setFormData({
      ...formData
    });
  };

  /************************* Start Of Search City input Fields  *****************************/
  const [cityInputValue, setCityInputValue] = useState(''); 
  const [fromCityId, setFromCityId] = useState('');   
  const [dbList, setDbList] = useState([]); 
  let timer;

  // Fetch live Records From Db
  const gatherMatchRecords = (trip_type, value) => {
    axios.post(`${config.API_URL}customer/citylist`, { keyword: value, page_no: 1, records_per_page: 10, trip_type: trip_type }, apiHeaderToken(config.API_TOKEN) )
      .then((respData) => respData.data)
      .then((responseData) => {
        if (responseData.status) {
          setDbList(responseData.data);  
        } else {
          setDbList([]);  
        }
      })
      .catch((error) => { 
        setDbList([]);  
    });
  };

  const handleCityInputChange = (e) => {
    const value = e.target.value;
    setCityInputValue(value);

    clearTimeout(timer);
    timer = setTimeout(() => {
      gatherMatchRecords( tripType, value );
      setFromCityId( '' );
      handleAllInputChange({'from_city':'', 'from_city_id': '','from_state_id':'', 'from_state_name' : '' }); 
    }, 300);
  };

  const handleSuggestionClick = (suggestion) => {
    let city_name = suggestion.city_name+', '+suggestion.state_name; 
    setCityInputValue( city_name );  
    setFromCityId( suggestion._id );  
    setDbList([]);
    handleAllInputChange({'from_city':city_name, 'from_city_id': suggestion._id, 'from_state_id': suggestion.state_id, 'from_state_name': suggestion.state_name }); 
  };

  
  const ulListStyle = {
    position: 'absolute',
    zIndex: 900,
    background: '#fbfdfd',
    top: '57px',
    left: '29px',
  };

  const listStyle = {
    padding: '5px 0px 5px 5px',
    borderBottom: '1px dotted #111111',
    width: '463px',
    cursor: 'pointer',
  };
  /************************* End Of Search City input Fields  *****************************/


  /************************* Start Of Local Page Listing **********************************/
  const [packageList, setPackageList] = useState([]);
  const [selectedPackageOption, setSelectedPackageOption] = useState("");
  const gatherPackageRecords = (value) => {
    axios
      .get(`${config.API_URL}customer/packagelist`, apiHeaderToken(config.API_TOKEN))
      .then((respData) => respData.data)
      .then((responseData) => { 
        if (responseData.status) {  
          setPackageList(responseData.data); 
          if(typeof getSearchData === 'undefined' || Object.keys(getSearchData).length === 0  ){
            handleAllInputChange({'package': responseData.data[0].package_name, 'package_id': responseData.data[0]._id }); 
           }
        } else {
          setPackageList([]);
        }
      })
      .catch((error) => { 
        setPackageList([]);
      });
  };
 
  function handlePackageSelect(e) {
    const selectedOptionText = e.target.options[e.target.selectedIndex].text;
    const selectedOptionValue = e.target.value;

    setSelectedPackageOption( selectedOptionValue ); 
    handleAllInputChange({'package': selectedOptionText, 'package_id': selectedOptionValue });  
  } 

   const selectStyle = {
    cursor:'pointer'
   }
  /************************** End  Of Local Page Listing **********************************/

  /************************** Stat Of Vehicle Listing **********************************/
  const [vehicleList, setVehicleList] = useState([]);
  const [selectedVehicleOption, setSelectedVehicleOption] = useState("");
  
  const gatVehicleRecords = () => {
    axios
      .get(`${config.API_URL}customer/vehiclelist`, apiHeaderToken(config.API_TOKEN))
      .then((respData) => respData.data)
      .then((responseData) => { 
        if (responseData.status) {
            setVehicleList(responseData.data);
            if(typeof getSearchData === 'undefined' || Object.keys(getSearchData).length === 0  ){
              handleAllInputChange({'vehicle_name': responseData.data[0].category, 'vehicle_id': responseData.data[0]._id  });
            } 
        } else {
            setVehicleList([]);
        }
      })
      .catch((error) => { 
        setVehicleList([]);
      });
  };


  function handleVehicleSelect(e) {
    const selectedOptionText = e.target.options[e.target.selectedIndex].text;
    const selectedOptionValue = e.target.value;

    setSelectedVehicleOption( selectedOptionValue ); 
    handleAllInputChange({'vehicle_name': selectedOptionText, 'vehicle_id': selectedOptionValue });      
  }

   const selectVehicleStyle = {
    cursor:'pointer'
   }
  /************************** End Of Vehicle Listing **********************************/
   
  /************************** Start Of Date Function **********************************/ 
    const [selectedDay, setSelectedDay] = useState( today );   

    function handlePickupDateValue (e){ 
      setSelectedDay( e );
      handleAllInputChange({'pickup_date': `${e.year}-${e.month}-${e.day}` }); 
    }  
    // render regular HTML input element
    const renderCustomInput = ({ ref }) => (
      <input
        readOnly
        ref={ref} // necessary
        placeHolder={'Pickup Date'}
        name={'pickup_date'}
        value={selectedDay ? `${selectedDay.day}/${selectedDay.month}/${selectedDay.year}` : ''}
        style={{
          textAlign: 'center',
          padding: '1rem 1.5rem',
          fontSize: '1rem',
          borderRadius: '100px',
          color: '#9c88ff',
          outline: 'none',
          cursor:'pointer'
        }}
        className="form-control"
        required
      />
    )
  /************************** End Of Date Function **********************************/

  /************************** Start Of Time Function **********************************/
  const [selectedTime, setSelectedTime] = useState( moment() );  
  function handleTimeChange(value){  setSelectedTime( value ); handleAllInputChange({'pickup_time': moment(value).format("hh:mm A") }); }
  const DeliTimePicker = ({ className, onChange, value, ...rest }) => (
    <TimePicker
      {...rest}
      className={className}
      popupClassName={className}
      showSecond={false}
      onChange={onChange}
      hideDisabledOptions
      minuteStep={5}
      value={value}
      use12Hours
    />
  );

  DeliTimePicker.propTypes = {
    className: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.instanceOf(moment)
  };
 
  
  /************************** End Of Time Function **********************************/

  //console.log("Form Data:", formData ); 
  const [showAlertMessage, setShowAlertMessage] = useState(''); 
  const [showAlertStatus, setShowAlertStatus] = useState( false ); 

  const handleSubmit  = (e)=>{
    e.preventDefault(); 
    
    const delayTime = 3000;
    if( formData.from_city === '' ){
      setShowAlertMessage('Please Search Pickup City Name');
      setShowAlertStatus(true);
      setTimeout( ()=>{ setShowAlertStatus(false);}, delayTime );
      return false;
    }
    else if( formData.package === '' ){
      setShowAlertMessage('Please Select Package');
      setShowAlertStatus(true);
      setTimeout( ()=>{ setShowAlertStatus(false);}, delayTime  );
      return false;
    } 
    else if( formData.pickup_date === '' ){
      setShowAlertMessage('Please Select Pickup Date');
      setShowAlertStatus(true);
      setTimeout( ()=>{ setShowAlertStatus(false);}, delayTime  );
      return false;
    }
    else if( formData.pickup_time === '' ){
      setShowAlertMessage('Please Select Pickup Time');
      setShowAlertStatus(true);
      setTimeout( ()=>{ setShowAlertStatus(false);}, delayTime );
      return false;
    }
    else if( formData.vehicle_name === '' ){
      setShowAlertMessage('Please Select Vehicle');
      setShowAlertStatus(true);
      setTimeout( ()=>{ setShowAlertStatus(false);}, delayTime );
      return false;
    } 
    
    //remove ole session 
    localStorage.removeItem('search_tab');

    //assign data in search tab storage
    localStorage.setItem('search_tab', JSON.stringify( formData ) );  

    //Reload to cab list
    window.location.reload(); 
  } 
 
   
  /*set search data in input fields*/
  useEffect(() => {

    gatherPackageRecords("");  
    gatVehicleRecords("");     

    if( typeof getSearchData !== 'undefined' && getSearchData !== null && getSearchData.trip_type === tripType ){   
 
        setCityInputValue( getSearchData.from_city ); 
        setFromCityId( getSearchData.from_city_id ); 
        handleAllInputChange({'from_city': getSearchData.from_city, 'from_city_id': getSearchData.from_city_id ,'from_state_id': getSearchData.from_state_id, 'from_state_name': getSearchData.from_state_name}); 

        setSelectedPackageOption( getSearchData.package_id );
        handleAllInputChange({'package': getSearchData.package, 'package_id': getSearchData.package_id });  

        setSelectedVehicleOption( getSearchData.vehicle_id );
        handleAllInputChange({'vehicle_name': getSearchData.vehicle_name, 'vehicle_id': getSearchData.vehicle_id }); 

        const pickupDate = getSearchData.pickup_date.split('-');
        const dateObj = {}
        dateObj['day'] = parseInt( pickupDate[2] );
        dateObj['month'] = parseInt( pickupDate[1] );
        dateObj['year'] = parseInt( pickupDate[0] ); 
        setSelectedDay( dateObj );
        handleAllInputChange({'pickup_date': `${dateObj.year}-${dateObj.month}-${dateObj.day}` }); 

        const pickupDateTimeMoment = moment( new Date( `${dateObj.year}-${dateObj.month}-${dateObj.day}`+' '+ getSearchData.pickup_time ) );
        const pickupDateTime = pickupDateTimeMoment.format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ');
        
        handleAllInputChange({'pickup_time': moment( pickupDateTime ).format("hh:mm A") }); 
        setSelectedTime( moment( pickupDateTime ) );
    }else{
        handleAllInputChange({'vehicle_name': '','vehicle_id': '' });
    }

  }, []);
    
    return (
        <>
         {showAlertStatus  && (
         <AlertBox type="error" message={showAlertMessage} />
         )}
         
            <section className="sect_padding">
                <div className="container">
                    <div className="row top_filterrow">
                        <form onSubmit={handleSubmit} > 

                            <div className="col-sm-2  form-element">
                                <input type="hidden" id="id_from_city_local" name="from_city_id" value={fromCityId} />
                                <input
                                        type="text"
                                        value={cityInputValue}
                                        onChange={handleCityInputChange}
                                        placeholder="Type Pickup City"
                                        id={'pickupLocalCity'}
                                        className="form-control box_sh_inner"
                                        name={"from_city"}
                                />
                                <ul style={ulListStyle}>
                                {dbList.map((item, index) => (
                                <li key={index} onClick={() => handleSuggestionClick(item)} style={listStyle}> {item.city_name+', '+ item.state_name}</li>
                                ))}
                                </ul>
                                <LocationOnOutlinedIcon />
                            </div>
                            
                            <div className="col-sm-2 form-element">
                                <select name={'package'} value={selectedPackageOption} onChange={handlePackageSelect}  className="form-select whiteshdow" style={selectStyle}> 
                                {packageList.map((item, index) => (
                                <option key={index} value={item._id}>
                                {item.package_name}
                                </option>
                                ))}
                                </select> 
                            </div>
                           
                            <div className="col-sm-2 form-element">
                                <DatePicker
                                    value={selectedDay}
                                    onChange={handlePickupDateValue}
                                    renderInput={renderCustomInput} 
                                    shouldHighlightWeekends
                                    />
                                <DateRange />
                            </div>
                            <div className="col-sm-2 form-element">
                                <div className="timepkr timepkrTop">
                                    <DeliTimePicker value={selectedTime} onChange={handleTimeChange}  name="pickup_time" /> 
                                    <AccessTime /> 
                                </div> 
                            </div>

                            <div className="col-sm-2 form-element"> 
                                <select value={selectedVehicleOption} name={'vehicle_name'} id="vehicle_name" onChange={handleVehicleSelect}  className={'form-select whiteshdow'} style={selectVehicleStyle} required={"required"}> 
                                    {vehicleList.map((item, index) => (
                                    <option key={index} value={item._id}>
                                    {item.category}
                                    </option>
                                    ))}
                                </select>
                            </div>

                            <div className="col-sm-2 form-element">
                                <input type="submit" className="search_cb" value="Search" />
                            </div>
                         </form>
                    </div> 
                </div>
            </section>
        </>
    );
};

export default FilterLocalCabTab;