import React from "react";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';



//import LoadMyScript from './Myscript';

// class Appx extends React.Component {
//     componentDidMount() {
//       <LoadMyScript />
//     }
// }

const options = {
    margin: 10,
    responsiveClass: true,
    nav: false,
    dots: true,
    autoplay: false,
    smartSpeed: 1000,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        600: {
            items: 1,
        },
        700: {
            items: 1,
        },
        1000: {
            items: 3,

        }
    },
};
const TestimonialSection = () => {
    return ( 
        <>   
            <div className="container">
                <div className="hdngrow">
                    <div className="lefthdng">
                        <h3 className="site_sub_hdng"> Why People Love Us </h3>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard </p>
                    </div>
                    <div className="rght_hdng">
                        <a href="#" className="sidelink">View All</a>
                    </div>
                </div>
                <div className="row">
                    <div className=" ">
                        <OwlCarousel className="vdoslider-items owl-carousel owl-theme" {...options}>
                            <div className="item">
                                <iframe src="https://www.youtube.com/embed/Ql8pai_D-Zs" title="YouTube video player"
                                    frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen></iframe>
                            </div>
                            <div className="item">
                                <iframe src="https://www.youtube.com/embed/Ql8pai_D-Zs" title="YouTube video player"
                                    frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen></iframe>
                            </div>
                            <div className="item">
                                <iframe src="https://www.youtube.com/embed/Ql8pai_D-Zs" title="YouTube video player"
                                    frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen></iframe>
                            </div>
                            <div className="item">
                                <iframe src="https://www.youtube.com/embed/Ql8pai_D-Zs" title="YouTube video player"
                                    frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen></iframe>
                            </div>
                            {/* <div class="youtube-container">
                              <div class="youtube-player" data-id="Ql8pai_D-Zs"></div>
                            </div> 
                            <div class="youtube-container">
                              <div class="youtube-player" data-id="Ql8pai_D-Zs"></div>
                            </div>
                            <div class="youtube-container">
                              <div class="youtube-player" data-id="Ql8pai_D-Zs"></div>
                            </div> 
                            <div class="youtube-container">
                              <div class="youtube-player" data-id="Ql8pai_D-Zs"></div>
                            </div> */}
                        </OwlCarousel>
                    </div>
                </div>
            </div>
        </>
    );
};
export default TestimonialSection;