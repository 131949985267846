import React from "react";
import downloadApp from "../src/images/downloadapp.png";
import playStore from "../src/images/playstore.webp";
import appStore from "../src/images/appstore.webp";


const AppDownload = () => {

    return (
        <>

            <div className="container">
                <div className="row j-align-center">
                    <div className="col-sm-6 left_txt">
                        <h2 className="site_hdng">Book Your Cab <br /> with India's No.1 <br />  Cab Booking App</h2>
                        <p>It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages,
                            and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem
                            Ipsum.</p>
                        <div className="appimgs">
                            <img src={playStore} alt="playStore" />
                            <img src={appStore} alt="appStore" />
                        </div>
                    </div>
                    <div className="col-sm-6 right_img">
                        <img src={downloadApp} alt={downloadApp} />
                    </div>
                </div>
            </div>


        </>
    )
}

export default AppDownload