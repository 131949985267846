import React from "react";
import TopPackageBar from "./TopPackageBar";
import PackageListBox from "./PackageListBox";
import Faq from "./Faq";
import BlogList from "./BlogList";
import AppDownload from "./AppDownload";
import ValueSection from "./ValueSection";
import TestimonialSection from "./TestimonialSection";
import Footer from "./partials/Footer";


const TaxiPackage = () => {
    return (
        <>
            <TopPackageBar />

            <section>
                <div className="container">
                    <div className="hdngrow">
                        <div className="lefthdng">
                            <h3 className="site_sub_hdng"> List of Local Taxi Packages </h3>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard </p>
                        </div>
                        <div className="rght_hdng">
                            <a href="/taxi_package" className="sidelink">43 Packages Live</a>
                        </div>
                    </div>
                    <div className="row">
                        <PackageListBox />
                        <PackageListBox />
                        <PackageListBox />
                        <PackageListBox />
                        <PackageListBox />
                        <PackageListBox /> 
                        <PackageListBox />
                    </div>
                </div>

            </section>

            <section>
                <div className="container">
                    <div className="hdngrow">
                        <div className="lefthdng">
                            <h3 className="site_sub_hdng"> FAQ </h3>
                        </div>
                        <div className="rght_hdng">
                            <a href="/taxi_package" className="sidelink">View All</a>
                        </div>
                    </div>
                </div>
                <Faq />
            </section>
            <section>
                <div className="container">
                    <div className="hdngrow">
                        <div className="lefthdng">
                            <h3 className="site_sub_hdng"> Our Blog </h3>
                        </div>
                        <div className="rght_hdng">
                            <a href="/taxi_package" className="sidelink">View All</a>
                        </div>
                    </div>
                </div>
                <BlogList />
            </section>

            <section className="add_download sect_padding">
                <AppDownload />
            </section>
            <section className="sect_padding">
                <ValueSection />
            </section>
            <section className="sect_padding">
                <TestimonialSection />
            </section>
            <section className="footer">
                <Footer />
            </section>
        </>
    );
};

export default TaxiPackage;