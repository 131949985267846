import React, { useState, useEffect } from "react";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function AlertBox(props) {
  // Initialize state based on props
  const [type, setType] = useState(props.type || 'success');
  const [message, setMessage] = useState(props.message || 'test');
  const [open, setOpen] = useState(true);

  useEffect(() => {  
    setType(props.type || 'success');
    setMessage(props.message || 'test'); 
  }, [props.type, props.message] );

  const popUpBox = {
    position: 'fixed',
    left: '50px',
    bottom: '20px',
    zIndex: '1000',
    minWidth: '250px',
    maxWidth: '550px',
  }

  const alertBoXStyle = {
    backgroundColor: '#04304c'
  }

  const handleClose = () => {
    setOpen(false);
  };

  setTimeout(handleClose, 3000);

  return (
    <div style={popUpBox}>
        {open && (
         <Alert severity={type} onClose={handleClose} style={alertBoXStyle} >{message}</Alert>
        )}
    </div>
  );
}

export default AlertBox;
