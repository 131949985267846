import React from "react";
import BlogBox from './BlogBox';


const BlogList = () => {

    return (
        <>
            <div className="container">
                <div className="row">
                    <BlogBox />
                    <BlogBox />
                    <BlogBox />
                </div>
            </div>

        </>
    )
}

export default BlogList