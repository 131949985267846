import axios from 'axios';
import React, { useEffect,useState } from 'react'; 

import config from '../config/config';
import apiHeaderToken from '../config/api_header';

import "react-modern-calendar-datepicker/lib/DatePicker.css";
import DatePicker from 'react-modern-calendar-datepicker';  
import DateRange from '@material-ui/icons/DateRange';

import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined'; 
import AccessTime from '@material-ui/icons/AccessTime';

import PropTypes from "prop-types";
import moment from "moment";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";

import AlertBox from "../modalbox/AlertBox";
import { getCurrentTime } from "../helper/my_function_helper"


const FilterOutstationCabTab = () => {

  const getSearchData = JSON.parse(localStorage.getItem('search_tab')) || {};  
    
    let currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(currentDate.getDate()).padStart(2, '0');
    const today = {'day':day,'month': month, 'year': year };  
    const formattedDate = `${year}-${month}-${day}`;

    const formattedTime = getCurrentTime();
 

  const tripType = 'outstation';
  const [formData, setFormData] = useState({
      from_city: "",
      from_city_id: "",
      from_state_id: "",
      from_state_name: "",
      trip_type: tripType,
      to_city: "",
      to_city_id: "",
      to_state_id: "",
      to_state_name: "",
      pickup_date: formattedDate,
      pickup_time: formattedTime,
      drop_date: "",
      package: '',
      package_id: '',
      vehicle_name: "",
      vehicle_id:  ""
  });

  const handleAllInputChange = ( nameValueObj ) => { 
    for (let property in nameValueObj ) { 
      formData[property] = nameValueObj[property];
    } 
    setFormData({
      ...formData
    });
  };

  /************************* Start Of Search City input Fields  *****************************/
  const [fromCityInputValue, setFromCityInputValue] = useState(''); 
  const [fromCityId, setFromCityId] = useState('');   
  const [dbFromCityList, setDbFromCityList] = useState([]); 

  let timer;

  // Fetch live Records From Db
  const gatherMatchRecords = (trip_type, value, cityType ) => {
    axios.post(`${config.API_URL}customer/citylist`, { keyword: value, page_no: 1, records_per_page: 10, trip_type: trip_type }, apiHeaderToken(config.API_TOKEN) )
      .then((respData) => respData.data)
      .then((responseData) => {
        if (responseData.status) { 
              setDbFromCityList(responseData.data);  
        } else { 
              setDbFromCityList([]);  
        }
      })
      .catch((error) => {  
              setDbFromCityList([]);  
      });
  };

  //From city handle data request
    const handleFromCityInputChange = (e) => {
        const value = e.target.value;
        setFromCityInputValue(value);

        clearTimeout(timer);
        timer = setTimeout(() => {
        gatherMatchRecords( tripType, value , 'pickup' );
        setFromCityId( '' );
        handleAllInputChange({'from_city':'', 'from_city_id': '', 'from_state_id':'', 'from_state_name': '' }); 
        }, 300);
    };

    const handleFromCitySuggestionClick = (suggestion) => {
        let city_name = suggestion.city_name+', '+suggestion.state_name; 
        setFromCityInputValue( city_name );  
        setFromCityId( suggestion._id );  
        setDbFromCityList([]);
        handleAllInputChange({'from_city':city_name, 'from_city_id': suggestion._id, 'from_state_id': suggestion.state_id, 'from_state_name': suggestion.state_name }); 
    };

  //to city handle data request  
  
  const ulListStyle = {
      position: 'absolute',
      zIndex: 900,
      background: '#fbfdfd',
      top: '57px',
      left: '29px',
  };

  const listStyle = {
      padding: '5px 0px 5px 5px',
      borderBottom: '1px dotted #111111',
      width: '463px',
      cursor: 'pointer',
  };
  /************************* End Of Search City input Fields  *****************************/

  /****************************   Autocomplete Address Start Script   ***************************/

  const [inputFields, setInputFields] = useState([{ city: '' }]);
  const [dbAddressLists, setDbAddressLists] = useState([[]]);

  const gatherAddressMatchRecordsData = (index, value) => {
    axios.post(`${config.API_URL}customer/address_book/searchAddress`, { search_keyword: value }, apiHeaderToken(config.API_TOKEN))
      .then((respData) => respData.data)
      .then((responseData) => {
        if (responseData.status) {
          const newDbListData = [...dbAddressLists];
          newDbListData[index] = responseData.data;
          setDbAddressLists(newDbListData);
        } else {
          const newDbListData = [...dbAddressLists];
          newDbListData[index] = [];
          setDbAddressLists(newDbListData);
        }
      })
      .catch((error) => {
        const newDbListData = [...dbAddressLists];
        newDbListData[index] = [];
        setDbAddressLists(newDbListData);
      });
  };

  const handleAddressInputChange = (index, value) => {
    const newInputFields = [...inputFields];
    newInputFields[index].city = value;
    setInputFields(newInputFields);
    handleAllInputChange({'travel_routes': newInputFields });

    clearTimeout(timer);
    timer = setTimeout(() => {
      gatherAddressMatchRecordsData(index, value);
    }, 1000);
  };

  const addInputField = () => {
    setInputFields([...inputFields, { city: '' }]);
    setDbAddressLists([...dbAddressLists, []]);
  };

  const removeInputField = (index) => {
    const newInputFields = [...inputFields];
    newInputFields.splice(index, 1);
    setInputFields(newInputFields);
    handleAllInputChange({'travel_routes': newInputFields });

    const newDbAddressLists = [...dbAddressLists];
    newDbAddressLists.splice(index, 1);
    setDbAddressLists(newDbAddressLists);
  };

  const handleSuggestionAddressClick = (index, suggestion) => {
      let city_name = suggestion.address; 
      const newInputFields = [...inputFields];
      newInputFields[index].city = city_name;
      setInputFields(newInputFields);

      const newDbAddressLists = [...dbAddressLists];
      newDbAddressLists.splice(index, 1);
      setDbAddressLists(newDbAddressLists);
  };
       
    
  /****************************   Autocomplete Address End Script   ***************************/

  /************************** Stat Of Vehicle Listing **********************************/
  const [vehicleList, setVehicleList] = useState([]);
  const [selectedVehicleOption, setSelectedVehicleOption] = useState("");
  const gatVehicleRecords = () => {
    axios
      .get(`${config.API_URL}customer/vehiclelist`, apiHeaderToken(config.API_TOKEN))
      .then((respData) => respData.data)
      .then((responseData) => { 
        if (responseData.status) {
            setVehicleList(responseData.data);
            if(typeof getSearchData === 'undefined' || Object.keys(getSearchData).length === 0  ){
              handleAllInputChange({'vehicle_name': responseData.data[0].category, 'vehicle_id': responseData.data[0]._id  });
            }
        } else {
            setVehicleList([]);
        }
      })
      .catch((error) => { 
        setVehicleList([]);
      });
  };
 

  function handleVehicleSelect(e) {
    const selectedOptionText = e.target.options[e.target.selectedIndex].text;
    const selectedOptionValue = e.target.value; 
    setSelectedVehicleOption( selectedOptionValue ); 
    handleAllInputChange({'vehicle_name': selectedOptionText, 'vehicle_id': selectedOptionValue });   
  }

   const selectVehicleStyle = {
    cursor:'pointer'
   }
  /************************** End Of Vehicle Listing **********************************/
   
  /************************** Start Of Date Function **********************************/ 
    const [selectedPickupDay, setSelectedPickupDay] = useState( today ); 
    const [selectedDropDay, setSelectedDropDay] = useState( today );   

    function handlePickupDateValue (e){ 
      setSelectedPickupDay( e );
      handleAllInputChange({'pickup_date': `${e.year}-${e.month}-${e.day}` }); 
      //reset drop date
      setSelectedDropDay( e );
      handleAllInputChange({'drop_date': `${e.year}-${e.month}-${e.day}` });
      
    }  
    // render regular HTML input element
    const renderCustomPickupDateInput = ({ ref }) => (
      <input
        readOnly
        ref={ref} // necessary
        placeHolder={'Pickup Date'}
        name={'pickup_date'}
        value={selectedPickupDay ? `${selectedPickupDay.day}/${selectedPickupDay.month}/${selectedPickupDay.year}` : ''}
        style={{
          textAlign: 'center',
          padding: '1rem 1.5rem',
          fontSize: '1rem',
          borderRadius: '100px',
          color: '#9c88ff',
          outline: 'none',
          cursor:'pointer'
        }}
        className="form-control"
        required
      />
    )

    function handleDropDateValue (e){ 
      setSelectedDropDay( e );
      handleAllInputChange({'drop_date': `${e.year}-${e.month}-${e.day}` }); 
    }

    const renderCustomDropDateInput = ({ ref }) => (
      <input
        readOnly
        ref={ref} // necessary
        placeHolder={'Drop Date'}
        name={'drop_date'}
        value={selectedDropDay ? `${selectedDropDay.day}/${selectedDropDay.month}/${selectedDropDay.year}` : ''}
        style={{
          textAlign: 'center',
          padding: '1rem 1.5rem',
          fontSize: '1rem',
          borderRadius: '100px',
          color: '#9c88ff',
          outline: 'none',
          cursor:'pointer'
        }}
        className="form-control"
        required
      />
    )
  /************************** End Of Date Function **********************************/

  /************************** Start Of Time Function **********************************/
  const [selectedTime, setSelectedTime] = useState( moment() );
  function handleTimeChange(value){ setSelectedTime( value ); handleAllInputChange({'pickup_time': moment(value).format("hh:mm A") }); }
  const DeliTimePicker = ({ className, onChange, value, ...rest }) => (
    <TimePicker
      {...rest}
      className={className}
      popupClassName={className}
      showSecond={false}
      onChange={onChange}
      hideDisabledOptions
      minuteStep={5}
      value={value}
      use12Hours
    />
  );

  DeliTimePicker.propTypes = {
    className: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.instanceOf(moment)
  };
 
  
  /************************** End Of Time Function **********************************/

  //console.log("Form Data:", formData ); 
  const [showAlertMessage, setShowAlertMessage] = useState(''); 
  const [showAlertStatus, setShowAlertStatus] = useState( false ); 

  const checkBlankCity = ( list ) => {
    let output = true;
    for (let index = 0; index < list.length; index++) {
      let cityName = list[index].city; 
      if( cityName === '' ){
        output = false;
      }
    }
    return output ;
  }

  const handleSubmit  = (e)=>{
    e.preventDefault(); 
    
    const delayTime = 3000;
    if( formData.from_city === '' ){
      setShowAlertMessage('Please Search Pickup City Name');
      setShowAlertStatus(true);
      setTimeout( ()=>{ setShowAlertStatus(false);}, delayTime );
      return false;
    }
    else if( !checkBlankCity( formData.travel_routes ) ){
      setShowAlertMessage('Please Search Drop City Name');
      setShowAlertStatus(true);
      setTimeout( ()=>{ setShowAlertStatus(false);}, delayTime  );
      return false;
    } 
    else if( formData.pickup_date === '' ){
      setShowAlertMessage('Please Select Pickup Date');
      setShowAlertStatus(true);
      setTimeout( ()=>{ setShowAlertStatus(false);}, delayTime  );
      return false;
    }
    else if( formData.pickup_time === '' ){
      setShowAlertMessage('Please Select Pickup Time');
      setShowAlertStatus(true);
      setTimeout( ()=>{ setShowAlertStatus(false);}, delayTime );
      return false;
    }
    else if( formData.vehicle_name === '' ){
      setShowAlertMessage('Please Select Vehicle');
      setShowAlertStatus(true);
      setTimeout( ()=>{ setShowAlertStatus(false);}, delayTime );
      return false;
    } 
    
    //remove ole session 
    localStorage.removeItem('search_tab');

    //assign data in search tab storage
    localStorage.setItem('search_tab', JSON.stringify( formData ) ); 

    //REload to cab list
    window.location.reload(); 
  } 
 
     
  /*set search data in input fields*/
  useEffect(() => {
  
    gatVehicleRecords();     

    if( typeof getSearchData !== 'undefined' && getSearchData !== null && getSearchData.trip_type === tripType ){    

        setFromCityInputValue( getSearchData.from_city );  
        setFromCityId( getSearchData.from_city_id );  
        handleAllInputChange({'from_city': getSearchData.from_city, 'from_city_id': getSearchData.from_city_id, 'from_state_id': getSearchData.from_state_id, 'from_state_name': getSearchData.from_state_name }); 

        setInputFields( getSearchData.travel_routes );  
        handleAllInputChange({'travel_routes': getSearchData.travel_routes });  

        setSelectedVehicleOption( getSearchData.vehicle_id );
        handleAllInputChange({'vehicle_name': getSearchData.vehicle_name, 'vehicle_id': getSearchData.vehicle_id }); 

        const pickupDate = getSearchData.pickup_date.split('-');
        const dateObj = {}
        dateObj['day'] = parseInt( pickupDate[2] );
        dateObj['month'] = parseInt( pickupDate[1] );
        dateObj['year'] = parseInt( pickupDate[0] ); 
        setSelectedPickupDay( dateObj );
        handleAllInputChange({'pickup_date': `${dateObj.year}-${dateObj.month}-${dateObj.day}` }); 

        const pickupDateTimeMoment = moment( new Date( `${dateObj.year}-${dateObj.month}-${dateObj.day}`+' '+ getSearchData.pickup_time ) );
        const pickupDateTime = pickupDateTimeMoment.format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ');
        
        handleAllInputChange({'pickup_time': moment( pickupDateTime ).format("hh:mm A") }); 
        setSelectedTime( moment( pickupDateTime ) );

        if( getSearchData.drop_date !== 'undefined' && getSearchData.drop_date !== '' ){ 
          const dropDate = getSearchData.drop_date.split('-');
          const dateDropObj = {}
          dateDropObj['day'] = parseInt( dropDate[2] );
          dateDropObj['month'] = parseInt( dropDate[1] );
          dateDropObj['year'] = parseInt( dropDate[0] ); 
          setSelectedDropDay( dateDropObj );
          handleAllInputChange({'drop_date': `${dateDropObj.year}-${dateDropObj.month}-${dateDropObj.day}` });
        } 

    }else{
        handleAllInputChange({'vehicle_name': '','vehicle_id': '' });
    }

  }, []);

    
    return (
        <>
         {showAlertStatus  && (
         <AlertBox type="error" message={showAlertMessage} />
         )}
         
            <section className="sect_padding">
                <div className="container">
                    <div className="row top_filterrow">
                        <form onSubmit={handleSubmit} > 

                            <div className="col-sm-2  form-element">
                                <input type="hidden" id="id_from_city_local" name="from_city_id" value={fromCityId} />
                                <input
                                        type="text"
                                        value={fromCityInputValue}
                                        onChange={handleFromCityInputChange}
                                        placeholder="Type Pickup City"
                                        id={'pickupLocalCity'}
                                        className="form-control box_sh_inner"
                                        name={"from_city"}
                                />
                                <ul style={ulListStyle}>
                                    {dbFromCityList.map((item, index) => (
                                    <li key={index} onClick={() => handleFromCitySuggestionClick(item)} style={listStyle}> {item.city_name+', '+ item.state_name}</li>
                                    ))}
                                </ul>
                                <LocationOnOutlinedIcon />
                            </div>
                            
                            <div className="col-sm-3 form-element filterDiv">
                                    <div className="row">
                                    <div className="col-sm-10">
                                        <div>
                                        {inputFields.map((data, index) => { 
                                            
                                            const dbAddressList = dbAddressLists[index] || [];
                                            const cityName = inputFields[index].city || '';
                                            const divCss = index === 0 ? 'col-md-12 col-12 col-lg-12 form-element' : ' col-md-10 col-10 col-lg-10 form-element';

                                            return (
                                            <div className="row" key={index}>

                                                {index === 0 ? ( <>
                                                <div className={divCss}>
                                                <input
                                                    type="text"
                                                    value={cityName}
                                                    onChange={(e) => handleAddressInputChange(index, e.target.value)}
                                                    placeholder={'Type drop city address'}
                                                    id={`to_city_${index}`}
                                                    className="form-control box_sh_inner"
                                                    name={`to_city_${index}`}
                                                    required={'required'}
                                                />
                                                <LocationOnOutlinedIcon /> 
                                                    <ul style={ulListStyle}>
                                                    {dbAddressList.map((item, subIndex) => (
                                                        <li key={subIndex} onClick={() => handleSuggestionAddressClick(index, item)} style={listStyle}> {item.address}</li>
                                                    ))}
                                                    </ul>
                                                    </div>
                                                    </>
                                                ) : (
                                                    <>
                                                    <div className={divCss}>
                                                    <input
                                                        type="text"
                                                        value={cityName}
                                                        onChange={(e) => handleAddressInputChange(index, e.target.value)}
                                                        placeholder={'Type drop city address'}
                                                        id={`to_city_${index}`}
                                                        className="form-control box_sh_inner"
                                                        name={`to_city_${index}`}
                                                        required={'required'}
                                                    />
                                                    <ul style={ulListStyle}>
                                                        {dbAddressList.map((item, subIndex) => (
                                                        <li key={subIndex} onClick={() => handleSuggestionAddressClick(index, item)} style={listStyle}> {item.address}</li>
                                                        ))}
                                                    </ul> 
                                                    <LocationOnOutlinedIcon />
                                                    </div> 
                                                    <div className="col-md-1 col-1 col-lg-1">
                                                    <button className=" pull-right removeBtnCss" onClick={() => removeInputField(index)} >x</button>
                                                    </div>
                                                    </>
                                                )}
                                            </div>
                                            );
                                        })} 
                                        </div> 
                                           
                                    </div> 

                                    <div className="col-sm-1">
                                    <div className="add_city pointer pull-right filterAddMoreBtn" onClick={addInputField}>+</div>
                                    </div>

                                    </div> 
                            </div> 
                            
                           
                            <div className="col-sm-2 form-element ">
                                <DatePicker
                                    value={selectedPickupDay}
                                    onChange={handlePickupDateValue}
                                    renderInput={renderCustomPickupDateInput} 
                                    name="pickupdate"
                                    shouldHighlightWeekends
                                />
                                <DateRange />
                            </div>
                            <div className="col-sm-1 form-element">
                                <div className="timepkr timepkrTop">
                                    <DeliTimePicker value={selectedTime} onChange={handleTimeChange}  name="pickup_time" /> 
                                    <AccessTime /> 
                                </div>
                            </div>

                            <div className="col-sm-2 form-element">
                                <DatePicker
                                    value={selectedDropDay}
                                    onChange={handleDropDateValue}
                                    renderInput={renderCustomDropDateInput}
                                    name="dropdate" 
                                    shouldHighlightWeekends
                                />
                                <DateRange />
                            </div>


                            <div className="col-sm-2 form-element">
                                    <select value={selectedVehicleOption} name={'vehicle_name'} id="vehicle_name" onChange={handleVehicleSelect}  className={'form-select whiteshdow'} style={selectVehicleStyle} required={"required"}> 
                                        {vehicleList.map((item, index) => (
                                        <option key={index} value={item._id}>
                                        {item.category}
                                        </option>
                                        ))}
                                    </select>
                            </div>

                            <div className="col-sm-2 form-element">
                                <input type="submit" className="search_cb" value="Search" />
                            </div>
                         </form>
                    </div> 
                </div>
            </section>
        </>
    );
};

export default FilterOutstationCabTab;