import React from "react";
import { NavLink } from "react-router-dom";

const Footer = () => {
    return (
        <>
            <div className="popular_section">
                <div className="container">
                    <div className="row">
                        <div className="hdngrow">
                            <div className="lefthdng">
                                <h3 className="site_sub_hdng"> Popular Routes </h3>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard </p>
                            </div>
                            <div className="rght_hdng">
                                <a href="#" className="sidelink">View All</a>
                            </div>
                        </div>
                    </div>
                    <div className="row pop_links">
                        <div className="col-sm-2">
                            <ul>
                                <li><a href="#">Lucknow to Agra </a></li>
                                <li><a href="#">Lucknow to Agra </a></li>
                                <li><a href="#">Lucknow to Agra </a></li>
                                <li><a href="#">Lucknow to Agra </a></li>
                                <li><a href="#">Lucknow to Agra </a></li>
                                <li><a href="#">Lucknow to Agra </a></li>
                            </ul>

                        </div>
                        <div className="col-sm-2">
                            <ul>
                                <li><a href="#">Lucknow to Agra </a></li>
                                <li><a href="#">Lucknow to Agra </a></li>
                                <li><a href="#">Lucknow to Agra </a></li>
                                <li><a href="#">Lucknow to Agra </a></li>
                                <li><a href="#">Lucknow to Agra </a></li>
                                <li><a href="#">Lucknow to Agra </a></li>
                            </ul>

                        </div>
                        <div className="col-sm-2">
                            <ul>
                                <li><a href="#">Lucknow to Agra </a></li>
                                <li><a href="#">Lucknow to Agra </a></li>
                                <li><a href="#">Lucknow to Agra </a></li>
                                <li><a href="#">Lucknow to Agra </a></li>
                                <li><a href="#">Lucknow to Agra </a></li>
                                <li><a href="#">Lucknow to Agra </a></li>
                            </ul>

                        </div>
                        <div className="col-sm-2">
                            <ul>
                                <li><a href="#">Lucknow to Agra </a></li>
                                <li><a href="#">Lucknow to Agra </a></li>
                                <li><a href="#">Lucknow to Agra </a></li>
                                <li><a href="#">Lucknow to Agra </a></li>
                                <li><a href="#">Lucknow to Agra </a></li>
                                <li><a href="#">Lucknow to Agra </a></li>
                            </ul>

                        </div>
                        <div className="col-sm-2">
                            <ul>
                                <li><a href="#">Lucknow to Agra </a></li>
                                <li><a href="#">Lucknow to Agra </a></li>
                                <li><a href="#">Lucknow to Agra </a></li>
                                <li><a href="#">Lucknow to Agra </a></li>
                                <li><a href="#">Lucknow to Agra </a></li>
                                <li><a href="#">Lucknow to Agra </a></li>
                            </ul>

                        </div>
                    </div>
                </div>
            </div>
            <section>
                <div className="footer-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-3 col-lg-3 col-md-3 col-6">
                                <div className="foot_box">
                                    <h5>Product</h5>
                                    <ul>
                                        <li><a href="" className="nav-link"> Drivers </a></li>
                                        <li><a href="#" className="nav-link"> Business</a></li>
                                        <li><a href="#" className="nav-link">Safety</a></li>
                                        <li><a href="" className="nav-link">Cities and Rates</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-sm-3 col-lg-3 col-md-3 col-6">
                                <div className="foot_box">
                                    <h5>About Us</h5>
                                    <ul>
                                        <li><NavLink className="nav-link" to="/about">Who We Are</NavLink> </li>
                                        <li><NavLink className="nav-link" to="/about">Join The Team</NavLink></li>
                                        <li><NavLink className="nav-link" to="/about">Blog</NavLink></li>
                                        <li><NavLink className="nav-link" to="/about">Press</NavLink></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-sm-3 col-lg-3 col-md-3 col-6">
                                <div className="foot_box">
                                    <h5>Resources</h5>
                                    <ul>
                                        <li><NavLink className="nav-link" to="/contact">Help Center</NavLink> </li>
                                        <li><NavLink className="nav-link" to="/privacy_policy">Privacy Policy</NavLink></li>
                                        <li> <NavLink className="nav-link" to="/terms_condition">Terms & Conditions</NavLink> </li>
                                        <li><NavLink className="nav-link" to="/about">Quality Policy</NavLink></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-sm-3 col-lg-3 col-md-3 col-6">
                                <div className="foot_box cstmr_support">
                                    <h5>Extra Links</h5>
                                    <div className="site-link csp">
                                        <ul>
                                            <li><NavLink className="nav-link" to="/">FAQs</NavLink></li>
                                            <li><NavLink className="nav-link" to="/">Partners</NavLink></li>
                                            <li><NavLink className="nav-link" to="/about">Social Media</NavLink></li>
                                        </ul>
                                    </div>
                                    <div className="footer-social-icons">
                                        <ul className="social-icons">
                                            <li>
                                                <a href="#" className="social-icon"> <i className="fa fa-facebook"></i></a>
                                            </li>
                                            <li>
                                                <a href="#" className="social-icon"> <i className="fa fa-twitter"></i></a>
                                            </li>
                                            <li>
                                                <a href="#" className="social-icon"> <i className="fa fa-linkedin"></i></a>
                                            </li>
                                            <li>
                                                <a href="#" className="social-icon"> <i className="fa fa-youtube"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom mobile-view-none">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-lg-12 col-12">
                                <p className="text-center">Copyright © Duplex. All Rights Reserved. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Footer;