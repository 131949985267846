import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
//import OtpScreen from './Otpscreen';

import axios  from "axios";
import config                   from './config/config';
import apiHeaderToken           from './config/api_header'; 
import { ucWords, allDateFormat, handleNullValue, getWordsBeforeComma  }  from './helper/my_function_helper';


export default function MyLoginModal(props) {
    
    const [showLoginModal, setShowLoginModal] = useState( false );
    const [showOTPModal, setShowOTPModal] = useState(false);
    const [mobileNo, setMobileNo] = useState('');
    const [btnText, setBtnText] = useState('Submit');
    const [btnVerifyText, setBtnVerifyText] = useState('Verify');
    const [counter, setCounter] = useState(0);

    const handleMobileNo = ( e )=>{ 
        const value = e.target.value;
        setMobileNo( value ); 
    }

    const manageBtnText = ( msg ) => {
        setBtnText( msg );
        setTimeout( ()=>{ setBtnText('Submit') }, 1500 );
    } 

    const submitMobileNo = ()=>{ 

        if( mobileNo === '' || mobileNo.length !== 10 ){
            manageBtnText('Please Enter 10 Digit Mobile No'); 
        }else{
            axios
            .post(`${config.API_URL}customer/login`, {'mobile_no': mobileNo },  apiHeaderToken(config.API_TOKEN))
            .then((respData) => respData.data)
            .then((responseData) => { 
                    if (responseData.status) {
                        setShowOTPModal( true ); 
                        setShowLoginModal( false ); 
                        setCounter(59);
                    }else{
                        manageBtnText( responseData.message ); 
                    }
            })
        }

    }



    useEffect(() => {  
         
        if (counter > 0) {
            const timer = setTimeout(() => setCounter(counter - 1), 1000);
            return () => clearTimeout(timer);
        }  

        if( mobileNo === ''){
            setTimeout( ()=>{
                setShowLoginModal( props.show ); 
            }, 200 ) ;   
        } 

    }, [counter, props.show, mobileNo]); 
  


/************* OTP Section Start Here ************/
const [otp, setOtp] = useState(new Array(4).fill("")); 

    const handleChange = (element, index) => {
        if (isNaN(element.value)) return false;

        setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);

        //Focus next input
        if (element.nextSibling) {
            element.nextSibling.focus();
        }
    };


    const refreshLoginPage = () => {
        window.location.reload();
    };

/****************** Resend OTP System Start Here *************************/
    const handleResendClick = () => {

        axios
            .post(`${config.API_URL}customer/resendotp`, {'mobile_no': mobileNo },  apiHeaderToken(config.API_TOKEN))
            .then((respData) => respData.data)
            .then((responseData) => { 
                    if (responseData.status) {  
                        setCounter(59); 
                    }else{
                        manageBtnText( responseData.message ); 
                    }
            })          
      };

/****************** Resend OTP System End Here *************************/

    //set login data in session
    const setLoginData = ( loginData )=>{
        const storeLoginData = {}
        storeLoginData.user_id = handleNullValue( loginData._id );
        storeLoginData.user_name = handleNullValue( loginData.full_name );
        storeLoginData.user_email = handleNullValue( loginData.email_id );
        storeLoginData.gender = handleNullValue( loginData.gender );
        storeLoginData.kyc_status = handleNullValue( loginData.kyc_status );
        storeLoginData.user_mobile = handleNullValue( loginData.mobile_no );
        storeLoginData.unique_id = handleNullValue( loginData.unique_id );
        storeLoginData.wallet_balance = handleNullValue( loginData.wallet_balance );
        storeLoginData.referral_code = handleNullValue( loginData.referral_code );
        storeLoginData.referral_amount = handleNullValue( loginData.referral_amount );
        storeLoginData.referral_used = handleNullValue( loginData.referral_used );
        storeLoginData.city_name = handleNullValue( loginData.city_name );
        storeLoginData.state_name = handleNullValue( loginData.state_name );
        storeLoginData.city_id = handleNullValue( loginData.city_id ); 
        storeLoginData.state_id = handleNullValue( loginData.state_id ); 
        storeLoginData.address = handleNullValue( loginData.address ); 
        storeLoginData.pin_code = handleNullValue( loginData.pin_code ); 
        storeLoginData.profile_image = handleNullValue( loginData.profile_image ); 

        
        storeLoginData.comp_address = handleNullValue( loginData.comp_address ); 
        storeLoginData.comp_city_name = handleNullValue( loginData.comp_city_name ); 
        storeLoginData.comp_gstin = handleNullValue( loginData.comp_gstin ); 
        storeLoginData.comp_name = handleNullValue( loginData.comp_name ); 
        storeLoginData.comp_pan = handleNullValue( loginData.comp_pan ); 
        storeLoginData.comp_pin_code = handleNullValue( loginData.comp_pin_code ); 
        storeLoginData.comp_state_name = handleNullValue( loginData.comp_state_name ); 
        
        //assign Login data in storage
        localStorage.setItem('login_data', JSON.stringify( storeLoginData ) ); 
    }


    const manageOTPBtnText = ( msg ) => {
        setBtnVerifyText( msg );
        setTimeout( ()=>{ setBtnVerifyText('Verify') }, 1500 );
    }

    const submitVerifyOtp = ()=>{ 

        if( mobileNo === '' || mobileNo.length !== 10 ){
            manageOTPBtnText('Please Enter 10 Digit Mobile No'); 
        }else if( otp === '' || otp.length !== 4 ){
            manageOTPBtnText('Please Enter 10 Digit Mobile No'); 
        }else{
            const otpWhere = {}
            otpWhere.mobile_no = mobileNo;
            otpWhere.otp = otp.join('');
            otpWhere.device_id = 'web1234';
            otpWhere.fcm_id = '1234'; 
            axios
            .post(`${config.API_URL}customer/matchotp`, otpWhere,  apiHeaderToken(config.API_TOKEN))
            .then((respData) => respData.data)
            .then((responseData) => {  
                    if (responseData.status) {
                        setShowOTPModal( false ); 
                        setLoginData( responseData.data ); 
                        refreshLoginPage();
                    }else{
                        manageOTPBtnText( responseData.message ); 
                    }
            })
        }

    }
/************* OTP Section End Here ************/   
    return (
        <>
        <Modal className="qry_modal"
            {...props} 
            show={showLoginModal}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered 
            backdrop="static"
            keyboard={false}
        >

            <Modal.Body>
                <div className="loginmodl">
                    <h2 className="text-center">Sign In</h2>
                    <Form className='feedbckrow row justify-content-around'>
                        <div class="col-md-9 col-12 col-lg-9 form-element m-auto">
                            <label>Mobile Number</label>
                                <input
                                    type="text"
                                    name="mobile_no"
                                    placeholder="Enter Mobile Number"
                                    className="form-control box_sh_inner"
                                    onInput={(event) => {
                                    event.target.value = event.target.value.replace(/[^0-9]/g, '').slice(0, 10);
                                    }} 
                                    value={mobileNo}
                                    onChange={handleMobileNo}
                                    maxLength="10"
                                />
                        </div>
                        <Col className="col-sm-9 text-center m-auto">
                            <Button className="bookbtn" variant="primary" type="button" onClick={submitMobileNo}>
                                {btnText} 
                            </Button>
                        </Col>
                    </Form>
                </div>
            </Modal.Body>
        </Modal>

        {/***********  OTP Modal Section **********/}
        <Modal className="qry_modal" 
            show={showOTPModal} 
            onHide={() => setShowOTPModal(false)}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            keyboard={false}
        >
            <Modal.Body>
                <div className="loginmodl">
                    <h2 className="text-center">Verify OTP</h2>
                    <Form className='ottp row justify-content-around'>
                        
                            <div className="row">
                                <div className="col text-center"> 
                                    <p>Enter the OTP sent to you to verify your identity</p>

                                    {otp.map((data, index) => {
                                        return (
                                            <input
                                                className="otp-field"
                                                type="text"
                                                name="otp"
                                                maxLength="1"
                                                key={index}
                                                value={data}
                                                onChange={e => handleChange(e.target, index)}
                                                onFocus={e => e.target.select()}
                                            />
                                        );
                                    })}
                                    <a href="#" className="resend">
                                        {counter > 0 ? (
                                            <p>Resend OTP in <span>{counter} seconds </span></p>
                                        ) : (
                                            <span onClick={handleResendClick}>Resend OTP</span>
                                        )}
                                    </a>
                                </div>
                            </div>
                        <Col className="col-sm-9 text-center m-auto">
                            <Button className="bookbtn" variant="primary" type="button" onClick={submitVerifyOtp}>
                               {btnVerifyText}
                            </Button>
                        </Col>
                    </Form>
                </div>
            </Modal.Body>
        </Modal>
        </>
    );
}