import React ,{ useState, useEffect }     from "react"; 
import axios                    from "axios";
import ValueSection             from "./ValueSection";
import TestimonialSection       from "./TestimonialSection";
import Footer                   from "./partials/Footer";

import CabListBox               from "./CabListBox";

import { ucWords, allDateFormat, handleNullValue, getWordsBeforeComma  }  from './helper/my_function_helper';
import config                   from './config/config';
import apiHeaderToken           from './config/api_header'; 


/*filter tab script */
import FilterLocalCabTab from './cabsearchengine/FilterLocalCabTab'; 
import FilterAirportCabTab from './cabsearchengine/FilterAirportCabTab';
import FilterOneWayCabTab from './cabsearchengine/FilterOneWayCabTab';
import FilterOutstationCabTab from './cabsearchengine/FilterOutstationCabTab';





const CabList = () => { 

    const [ vehicleCategoryList, setVehicleCategoryList ] = useState([]); 
    const [ vehicleModelList, setVehicleModelList ] = useState([]); 
    const [ selectedCatName, setSelectedCatName ] = useState(''); 
    const [ selectedCatId, setSelectedCatId ] = useState('');
    const [ selectedModelName, setSelectedModelName ] = useState(''); 
    const [ selectedFuelType, setSelectedFuelType ] = useState(''); 
    const [ cabList, setCabList ] = useState([]); 
    const [ travelTextRoute, setTravelTextRoute ] = useState('');  


    let getSearchData = JSON.parse( localStorage.getItem('search_tab') );


    let tripType = handleNullValue( getSearchData.trip_type );
    let fromCityId = handleNullValue( getSearchData.from_city_id ) ;
    let fromCityName = handleNullValue( getSearchData.from_city ) ;
    let packageName = handleNullValue( getSearchData.package ) ;
    let pickupDate = handleNullValue( getSearchData.pickup_date ) ;
    let pickupTime = handleNullValue( getSearchData.pickup_time ) ;
    let dropDate = handleNullValue( getSearchData.drop_date ) ;

    let toCityId = handleNullValue( getSearchData.to_city_id ) ;
    let toCityName = handleNullValue( getSearchData.to_city ) ;

    let vehicleName = handleNullValue( getSearchData.vehicle_name ) ; 
    let vehicleId = handleNullValue( getSearchData.vehicle_id ) ; 
    let travel_routes = getSearchData.travel_routes ; 
   

    let searchTravelRoot = ''; var searchTextRoot = '';
    if( ['airport','oneway'].includes( tripType ) ){
        searchTravelRoot = fromCityName+'|'+toCityName; 
        var searchTextRoot = getWordsBeforeComma( fromCityName )+'→'+getWordsBeforeComma( toCityName ); 
         
    }
    else if( ['local'].includes( tripType ) ){
        searchTravelRoot = fromCityName+'|'+packageName;
        var searchTextRoot = getWordsBeforeComma( fromCityName )+'→'+packageName; 
        
    }
    else if( ['outstation'].includes( tripType ) ){
        searchTravelRoot = fromCityName+travel_routes.map( (elm)=>{
            return '|'+elm.city;
        }); 
       var searchTextRoot = getWordsBeforeComma( fromCityName )+'→'+ travel_routes.map( (elm)=>{
            return getWordsBeforeComma( elm.city )+'→';
        })+getWordsBeforeComma( fromCityName );  

    }

    let payLoad = {} 
    payLoad['user_id'] = ''; 
    payLoad['trip_type'] = tripType;  
    payLoad['from_city_id'] = fromCityId;  
    payLoad['to_city_id'] = toCityId;  
    payLoad['package_name'] = packageName;  
    payLoad['vehicle_name'] = vehicleName;  
    payLoad['vehicle_id'] = vehicleId; 
    payLoad['pickup_date'] = pickupDate;  
    payLoad['pickup_time'] = pickupTime;  
    payLoad['drop_date'] = dropDate;  
    payLoad['drop_time'] = pickupTime;  
    payLoad['page_no'] = '1';  
    payLoad['records_per_page'] = '200';
    payLoad['travel_route'] = searchTravelRoot; 

    payLoad['fuel_type'] = selectedFuelType; 
    payLoad['category_name'] = selectedCatName; 
    payLoad['model_name'] = selectedModelName;  

    //console.log( JSON.stringify( payLoad ) );

    /**************  Get Vehicle Category / Model List  ***********************/ 
    const gatVehicleRecords = ( cat_id ) => {
        axios.post(`${config.API_URL}customer/vehicle_model_list`,{cat_id: cat_id }, apiHeaderToken(config.API_TOKEN) )
          .then((respData) => respData.data)
          .then((responseData) => {
            if (responseData.status) {  
                setVehicleCategoryList( responseData.data );
                prePareModelList( responseData.data );  
            } else {
                setVehicleCategoryList([]);  
            }
          })
          .catch((error) => { 
            setVehicleCategoryList([]);  
        });
      };  
       
      useEffect(() => {
            setTimeout( ()=>{ 
                gatVehicleRecords(''); 
                setTravelTextRoute( searchTextRoot );
            }, 500 ) ;
      }, []);
  

    /***************** Cab Search Results Start Script ****************/  

    const gatCabList = () => {
        axios.post( `${config.API_URL}customer/booking/searchcab` , payLoad, apiHeaderToken(config.API_TOKEN) )
          .then((respData) => respData.data)
          .then((responseData) => { 
            if (responseData.status) { 
                setCabList(responseData.data);  
            } else {
                setCabList([]);  
            }
          })
          .catch((error) => { 
            setCabList([]);  
        });
      }; 
 
       
      useEffect(() => {
            setTimeout( ()=>{ 
                gatCabList(); 
            }, 100 ) ;
      }, []);

/*******************filter model from list **********************/
 function prePareModelList ( listData, cat_name = '' ){
    setVehicleModelList( [] );  
    let outPut = [];  
        for( var i=0; i < listData.length;i++){ 
            let modelList = listData[i].model_list;
            for( var j=0; j < modelList.length;j++){ 
                if( cat_name !== '' && cat_name ==  listData[i].category ){ 
                    let makeCollection =  {'modelname': modelList[j].modelname,'_id': modelList[j]._id }
                    outPut.push( makeCollection );
                }else if( cat_name === '' ){
                    let makeCollection =  {'modelname': modelList[j].modelname,'_id': modelList[j]._id }
                    outPut.push( makeCollection );
                } 
            }                      
        }
    setVehicleModelList( outPut ); 
 }

/*************** Handle Selected Vehicle Category ID / Data From List *************/
const handleCategoryChange = (e)=>{
    let categoryData = vehicleCategoryList; 
    setSelectedCatName( e.target.value ); 
    prePareModelList( categoryData, e.target.value ); 
    setSelectedModelName( '' );
    setTimeout( ()=>{ gatCabList();},500 );
}

/******************* Handle Selected Vehicle Model ID **********************/
const handleModelChange = (e)=>{ 
    setSelectedModelName( e.target.value ); 
    setTimeout( ()=>{ gatCabList();},500 );
}

/******************* Handle Selected Fuel Type ID **********************/
const handleFuelTypeChange = (e)=>{ 
    setSelectedFuelType( e.target.value ); 
    setTimeout( ()=>{ gatCabList();},500 );
}



const cursor = {cursor:'pointer'}

    return (
        <>  
        { ['local'].includes(tripType) ? ( <FilterLocalCabTab /> ) : null }
        { ['oneway'].includes(tripType) ? ( <FilterOneWayCabTab /> ) : null }
        { ['outstation'].includes(tripType) ? ( <FilterOutstationCabTab /> ) : null } 
        { ['airport'].includes(tripType) ? ( <FilterAirportCabTab /> ) : null }  
            
            <section className="sect_padding route_info">
                <div className="container">
                    <div className="row text-center route_info">
                        <h2 className="sitepage_hdng">{travelTextRoute}</h2>
                        <p className="rt_dtl">{ ucWords(tripType) }, {allDateFormat(pickupDate,'DD-MMM')}, {pickupTime}</p>
                    </div>
                </div>
            </section>
            <section className="filter_cablist">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-3 ">
                            <div className="side_filter">
                                <h3>Filter By:</h3>
                                <div className="filtrtype">
                                    <h5>Cab Type:</h5>
                                    <ul className="fi-list">
                                        {vehicleCategoryList.length > 0 && vehicleCategoryList.map((item) => ( 

                                        <li className="filter-df">
                                            <div className="cstm_radio">
                                                <input className="form-check-input" type="radio"  name="categoryIds" id={`categoryIds${item._id}`} onClick={handleCategoryChange} value={item.category} />
                                                <span className="checkmark"></span>
                                                <label htmlFor={`categoryIds${item._id}`} style={cursor} >{item.category}</label>
                                            </div> <p>&nbsp;</p>
                                        </li>
                                  
                                         ))}
                                    </ul>
                                </div>
                                <div className="filtrtype">
                                    <h5>Fuel Type:</h5>
                                    <ul className="fi-list">
                                        <li className="filter-df">
                                            <div className="cstm_radio">
                                                <input className="form-check-input" onClick={handleFuelTypeChange} type="radio" name="cng" id="fuel_type_1" value="CNG" />
                                                <span className="checkmark"></span>
                                                <label htmlFor="fuel_type_1">CNG</label>
                                            </div>
                                            <p>&nbsp;</p>
                                        </li>
                                        <li className="filter-df">
                                            <div className="cstm_radio">
                                                <input className="form-check-input" type="radio" name="cng" onClick={handleFuelTypeChange} id="fuel_type_2" value="Diesel" />
                                                <span className="checkmark"></span>
                                                <label htmlFor="fuel_type_2">Diesel </label>
                                            </div>
                                            <p>&nbsp;</p>
                                        </li>
                                        
                                    </ul>
                                </div>
                                <div className="filtrtype">
                                    <h5>Cab Model:</h5>
                                    <ul className="fi-list">
                                    {vehicleModelList.length > 0 && vehicleModelList.map(( item ) =>(
                                          
                                        <li className="filter-df" key={item.id}>
                                            <div className="cstm_radio">
                                                <input className="form-check-input" type="radio" name="modelIds" onClick={handleModelChange} id={`modelIds${item._id}`} value={item.modelname} />
                                                <span className="checkmark"></span>
                                                <label htmlFor={`modelIds${item._id}`} style={cursor}>{item.modelname}</label>
                                            </div> <p>&nbsp;</p>
                                        </li>
                                        
                                    ) ) }
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-9 cab_listing">
                            <div className="row">
                            {cabList.length > 0 && cabList.map(( item ) =>(
                                <div className="col-sm-6">
                                    <CabListBox listData={item} />
                                </div>
                            ) ) } 
                            </div>
                        </div>
                    </div>
                </div>
            </section>

           

            <section className="sect_padding">
                <ValueSection />
            </section>
            <section className="sect_padding">
                <TestimonialSection />
            </section>
            <section className="footer">
                <Footer />
            </section>

        </>
    );
};

export default CabList;