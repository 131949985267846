import React, { useState, useEffect } from "react";
import { Tabs, Tab } from 'react-bootstrap';

import DealSlider from "../DealSlider";
import RoundTripSlider from "../RoundTripSlider";

import PackageListSlider from "../PackageListSlider";

import ValueSection from "../ValueSection";
import TestimonialSection  from "../TestimonialSection";
import FleetSlider from "../FleetSlider";

import Footer from "../partials/Footer";
import playStore from "../../src/images/playstore.webp";
import appStore from "../../src/images/appstore.webp";

import AppDownload from "../AppDownload";


import LocalTab from "./LocalCabTab";
import OnewayCabTab from "./OnewayCabTab";
import OutstationCabTab from "./OutstationCabTab";
import AirportCabTab from './AirportCabTab';


const CabSearchEngine = () => {  

    const getSearchData = JSON.parse(localStorage.getItem('search_tab')) || {};
     
    const [activeTab, setActiveTab] = useState('first');

    useEffect(() => {
        if (typeof getSearchData !== 'undefined') {
            if (getSearchData.trip_type === 'local') {
                setActiveTab('first');
            } else if (getSearchData.trip_type === 'oneway') {
                setActiveTab('second');
            } else if (getSearchData.trip_type === 'outstation') {
                setActiveTab('third');
            } else if (getSearchData.trip_type === 'airport') {
                setActiveTab('fourth');
            }
        }
    }, []);

    const handleTabSelect = (key) => {
        setActiveTab(key);
    };
  
    

    return (
        <>
            <section className="homebanner">
                <div className="container">
                    <div className="row j-align-center">
                        <div className="col-sm-6">
                            <div className="topbnr_text">
                                <h1>The All New <br /> Rental Cab in Your City</h1>
                                <h2>Look <span>Book</span></h2>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s. </p>
                                <div className="appimgs">
                                    <img src={playStore} alt="playStore" />
                                    <img src={appStore} alt="appStore" />
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="home_tabs_wrapper">
                                <Tabs activeKey={activeTab} onSelect={handleTabSelect} className="searchtabs">
                                    <Tab eventKey="first" title="Local">
                                        <div className="cab_search">
                                            <LocalTab/> 
                                        </div>
                                    </Tab>
                                    <Tab eventKey="second" title="One Way" >
                                        <div className="cab_search cab_100">
                                            <OnewayCabTab/> 
                                        </div>
                                    </Tab>
                                    <Tab eventKey="third" title="Round Trip">
                                        <div className="cab_search cab_100">
                                            <OutstationCabTab/> 
                                        </div>
                                    </Tab>
                                    <Tab eventKey="fourth" title="Airport">
                                        <div className="cab_search cab_100"> 
                                            <AirportCabTab/>
                                        </div>
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="sect_padding">
                <div className="container">
                    <div className="hdngrow">
                        <div className="lefthdng">
                            <h3 className="site_sub_hdng"> Popular Oneway Route </h3>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard </p>
                        </div>
                        <div className="rght_hdng">
                            <a href="cab_list" className="sidelink">View All</a>
                        </div>
                    </div>
                    <FleetSlider />
                </div>
            </section>
            <section className="sect_padding">
                <div className="container">
                    <div className="hdngrow">
                        <div className="lefthdng">
                            <h3 className="site_sub_hdng"> Round Trip Taxi Packages </h3>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard </p>
                        </div>
                        <div className="rght_hdng">
                            <a href="taxi_package" className="sidelink">View All</a>
                        </div>
                    </div>
                    <RoundTripSlider />
                </div>
            </section>
            <section className="slider sect_padding">
                <DealSlider />
            </section>
            <section className="sect_padding">
                <div className="container">
                    <div className="hdngrow">
                        <div className="lefthdng">
                            <h3 className="site_sub_hdng"> Local Taxi Packages </h3>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard </p>
                        </div>
                        <div className="rght_hdng">
                            <a href="taxi_package" className="sidelink">View All</a>
                        </div>
                    </div>
                    <PackageListSlider />
                </div>
            </section>
            {/* <section className="aboutsec sect_padding">
                <About_sec />
            </section> */}
            <section className="add_download sect_padding">
                <AppDownload />
            </section>
            <section className="sect_padding">
                <ValueSection />
            </section>
            <section className="sect_padding">
                <TestimonialSection  />
            </section>
            <section className="footer">
                <Footer />
            </section>
        </>
    );
};

export default CabSearchEngine;