import React from "react";

const RoundCabPrice = () => {
    return (
        <>
            <div class="whiteshdow pkginsbox round_tbl ">
                <div class="row">
                    <table>
                        <thead>
                            <tr className="whiteshdow">
                                <th>Vehicle Type</th>
                                <th>Model</th>
                                <th>PEr KM Rate</th>
                                <th>Fare</th>
                                <th>Included</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td> HATCHBACK </td>
                                <td> Indica, Swift or similar</td>
                                <td> ₹17.5 </td>
                                <td> ₹700 </td>
                                <td> 50 KMs</td>
                                <td><a href="" class="btn_know"> View Details</a></td>
                            </tr>
                            <tr>
                                <td> HATCHBACK </td>
                                <td> Indica, Swift or similar</td>
                                <td> ₹17.5 </td>
                                <td> ₹700 </td>
                                <td> 50 KMs</td>
                                <td><a href="" class="btn_know"> View Details</a></td>
                            </tr>
                            <tr>
                                <td> HATCHBACK </td>
                                <td> Indica, Swift or similar</td>
                                <td> ₹17.5 </td>
                                <td> ₹700 </td>
                                <td> 50 KMs</td>
                                <td><a href="" class="btn_know"> View Details</a></td>
                            </tr>
                            <tr>
                                <td> HATCHBACK </td>
                                <td> Indica, Swift or similar</td>
                                <td> ₹17.5 </td>
                                <td> ₹700 </td>
                                <td> 50 KMs</td>
                                <td><a href="" class="btn_know"> View Details</a></td>
                            </tr>
                            <tr>
                                <td> HATCHBACK </td>
                                <td> Indica, Swift or similar</td>
                                <td> ₹17.5 </td>
                                <td> ₹700 </td>
                                <td> 50 KMs</td>
                                <td><a href="" class="btn_know"> View Details</a></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}


export default RoundCabPrice;