import React, { useState } from "react"; 

const CabSafetyPolicy = (props) => {

    const [safetyData, SetSafetyData] = useState( props.safetyData ); 
     
    return (
        <>
            <h3 className="site_sub_hdng">Safety Policy </h3> 
            <div className="cab_dt_box">
                <ul className="grey_list list_disc">
                    {safetyData && safetyData.map((item)=>(
                        <li><p>{item.s_name}</p></li>
                    ))}
                </ul>
            </div>    
        </>
    );
};

export default CabSafetyPolicy;