import React from "react";

const Attach = () => {
    return(
        <>
        <h1>Welcome Home</h1>
        </>
    );
};

export default Attach;